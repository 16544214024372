import type { User } from 'src/types/user';
import axios from 'axios';
// const STORAGE_KEY: string = 'users';

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

// const getPersistedUsers = (): User[] => {
//   try {
//     const data = sessionStorage.getItem(STORAGE_KEY);
//
//     if (!data) {
//       return [];
//     }
//
//     return JSON.parse(data) as User[];
//   } catch (err) {
//     console.error(err);
//     return [];
//   }
// };

// const persistUser = (user: User): void => {
//   try {
//     const users = getPersistedUsers();
//     const data = JSON.stringify([...users, user]);
//     sessionStorage.setItem(STORAGE_KEY, data);
//   } catch (err) {
//     console.error(err);
//   }
// };
type SignInRequest = {
  email: string;
  password: string;
}

type ForgotPasswordRequest = {
  email: string;
}

type ForgotPasswordResponse = Promise<{
  message: string;
}>;

type ResentRegisterCodeRequest = {
  email: string;
}

type ResentRegisterCodeResponse = Promise<{
  message: string;
}>;

type ResetPasswordRequest = {
  email: string;
  code: string;
  password: string;
  confirmPassword: string
}

type ResetPasswordResponse = Promise<{
  message: string;
}>;

type SubmitPinRequest = {
  email: string;
  code: string;
  urlSelect: string;
}

type SubmitPinResponse = Promise<{
  message?: string;
  accessToken?: string;
}>;

type SignInResponse = Promise<{
  accessToken: string;
}>;

type SignUpRequest = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  code: string | null;
}

type SignUpResponse = Promise<{
  status: string;
}>;

type MeRequest = {
  accessToken: string;
  isFirst?:boolean
};

type MeResponse = Promise<User>;

class AuthApi {

  async postData(url: string, requestData: object) {
    try {
      const response = await axios.post(url, requestData);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Some error... Please try again');
      }
    } catch (err) {
      console.log('[Auth Api]: ', err.response.data.message);

      // console.warn('[Auth Api]: ', err);
      throw new Error(err.response.data.message);
    }
  }

  async getData(url: string, headers:object) {
    try {
      const response = await axios.get(url, headers);
      if (response.status === 200) {
        return response.data.user;
      } else {
        throw new Error('Some error... Please try again');
      }
    } catch (err) {
      console.log('[Auth Api]: ', err.response.data.message);

      // console.warn('[Auth Api]: ', err);
      throw new Error(err.response.data.message);
    }
  }

  async signIn(request: SignInRequest): SignInResponse {
    const { email, password } = request;
    const response = await this.postData(process.env.REACT_APP_API_URL + 'api/authorization', { email, password });
    return { accessToken: response.access_token };
  }

  async signUp(request: SignUpRequest): SignUpResponse  {
    const { email, firstName, lastName, password, confirmPassword, code } = request;
    let body: {
      email: string,
      firstName: string,
      lastName: string,
      password: string,
      passwordConfirmation: string,
      inviteCode?: string
    } = {
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation: confirmPassword
    }
    if (code) {
      body.inviteCode = code
    }
    const response = await this.postData(process.env.REACT_APP_API_URL + 'api/registration', body);
    return { status: response };
  }

  async forgotPassword(request: ForgotPasswordRequest): ForgotPasswordResponse  {
    const { email } = request;
    const response = await this.postData(process.env.REACT_APP_API_URL + 'api/password-recovery/send', { email });
    return { message: response.message };
  }

  async resentRegisterCode(request: ResentRegisterCodeRequest): ResentRegisterCodeResponse {
    const { email } = request;
    const response = await this.postData(process.env.REACT_APP_API_URL + 'api/registration/resend', { email });
    return { message: response.message };
  }

  async resetPassword(request: ResetPasswordRequest) : ResetPasswordResponse {
    const { email, code, password, confirmPassword } = request;
    const response = await this.postData(process.env.REACT_APP_API_URL + 'api/password-recovery/set', {
      email,
      code,
      password,
      passwordConfirmation: confirmPassword
    });
    return { message: response.message };
  }

  async submitPin(request: SubmitPinRequest): SubmitPinResponse {
    const { email, code, urlSelect } = request;
    const url =
        urlSelect === 'forgotURL'
            ? `${process.env.REACT_APP_API_URL}api/password-recovery/verify`
            : `${process.env.REACT_APP_API_URL}api/registration/verify`;
    const response = await this.postData(url, { email, code });
    if (urlSelect === 'forgotURL') {
      return { message: response.message };
    } else if (urlSelect === 'submitEmailURL') {
      return { accessToken: response.access_token };
    } else {
      throw new Error('Some error... Please try again');
    }
  }

  async me(request: MeRequest): MeResponse {
    const { accessToken, isFirst } = request;
    const query = isFirst ? '/?has_logged_in=1' : ''
    const response = await this.getData(process.env.REACT_APP_API_URL + `api/user${query}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    return {user:response}
  }
}

export const authApi = new AuthApi();
