import React, {useEffect, useState} from 'react';
import {Box, TextField, Typography} from "@mui/material";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DayjsUtils from '@date-io/dayjs';

interface DatePickerProps {
    element: any;
    format: string;
    onChange: (e: string) => void;
    isRequiredError: boolean;
    disabled?: boolean;
}

const FormattedDatePicker: React.FC<DatePickerProps> = ({ element, format, onChange, isRequiredError, disabled = false }) => {
    let dateFormat: string = 'DD MMMM, YYYY';

    if (format) {
        if (format === 'DD February, YYYY') {
            dateFormat = "DD MMMM, YYYY";

        } else {
            dateFormat = format.toUpperCase();
        }
    }
    const [selectedDate, handleDateChange] = useState<any>(new Date(element.value).toString() === 'Invalid Date' ? dateFormat : element.value);
    useEffect(() => {
        onChange(selectedDate);
        // eslint-disable-next-line
    }, [selectedDate])

    const formatDate = (date: any, formatString: string) => {
        if (!date) return '';
        return dayjs(date).format(formatString);
    };

    if(disabled) {
        return (
            <Box
                sx={{
                    width: '100%',
                    padding: '20px 8px 8px',
                    borderRadius: '8px',
                    border: '1px solid rgba(108, 115, 127, 0.50)',
                    position: 'relative'
                }}
            >
                <Typography variant='caption'
                    sx={{
                        position: 'absolute',
                        color: '#6C737F',
                        fontSize: '11px',
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.15px',
                        left: '0',
                        top: '0',
                        transform: 'translate(4px, 9px) scale(0.85)',
                    }}
                >
                    {element.label}
                </Typography>
                <Typography variant='body1'>{element.value}</Typography>
            </Box>
        )
    }

    return (
        <LocalizationProvider
            dateAdapter={DayjsUtils}
        >
            <Box
                sx={{
                    position: 'relative'
                }}
            >
                <DatePicker
                    value={new Date(selectedDate).toString() === 'Invalid Date' ? Date.now() : selectedDate}
                    onChange={(date) => handleDateChange(formatDate(date, dateFormat))}
                    renderInput={(props: any) => (
                        <TextField
                            {...props}
                            required={element.required}
                            sx={{
                                width: '100%',
                                "& .MuiFilledInput-input": {
                                    visibility: 'hidden'
                                }
                            }}
                            inputProps={{
                                ...props.inputProps,
                                readOnly: true,
                                placeholder: dateFormat
                            }}
                            disabled={disabled}
                        />
                    )}
                />
                <TextField
                    value={selectedDate}
                    variant="filled"
                    label={element.label}
                    required={element.required}
                    error={isRequiredError}
                    disabled
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        pointerEvents: 'none',
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: '#6C737F'
                        },
                        '& input.Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        "& .MuiInputBase-root": {
                            borderColor: 'rgba(108, 115, 127, 0.50)'
                        },
                        '& .MuiFormLabel-root': {
                            color: '#6C737F',
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                        },
                        '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
                            transform: 'translate(12px, 9px) scale(0.85)'
                        }
                    }}
                />
                {
                    isRequiredError ? (<Typography
                        sx={{
                            color: '#F04438',
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            lineHeight: '1.66',
                            fontFamily: "Inter, sans-serif",
                            marginTop: '3px'
                        }}
                        variant="body2"
                    >
                        This field is required
                    </Typography>) : null
                }
            </Box>
        </LocalizationProvider>
    );
};

export { FormattedDatePicker };
