export const paths = {
    index: '/',
    auth: {
        login: '/auth/login',
        register: '/auth/register',
        confirmRegister: '/auth/confirmation',
        forgotPassword: '/auth/forgot-password',
        resetPassword: '/auth/reset-password'
    },
    dashboard: {
        index: '/dashboard',
        // submittedForms: '/dashboard/submitted-forms',
        submittedForms: {
            index: '/dashboard/submitted-forms',
            details: '/dashboard/submitted-forms/:customerId',
            edit: '/dashboard/submitted-forms/:customerId/edit'
        },
        notifications: '/dashboard/notifications',
        calendar: '/dashboard/calendar',
        kycOverview: '/dashboard/kyc-overview',
        kycBuilder: '/dashboard/kyc-builder',
        kycForms: '/dashboard/kyc-forms',
        settings: '/dashboard/settings',
        admin: '/dashboard/admin-panel',
        prospects: '/dashboard/prospects',
// other not used
        account: '/dashboard/account',
        analytics: '/dashboard/analytics',
        blank: '/dashboard/blank',

        // team: {
        //     editWorker:  '/dashboard/settings/edit-worker/:workerId'
        // },

        blog: {
            index: '/dashboard/blog',
            postDetails: '/dashboard/blog/:postId',
            postCreate: '/dashboard/blog/create'
        },
        chat: '/dashboard/chat',
        crypto: '/dashboard/crypto',
        ecommerce: '/dashboard/ecommerce',
        fileManager: '/dashboard/file-manager',
        invoices: {
            index: '/dashboard/invoices',
            details: '/dashboard/invoices/:orderId'
        },
        jobs: {
            index: '/dashboard/jobs',
            create: '/dashboard/jobs/create',
            companies: {
                details: '/dashboard/jobs/companies/:companyId'
            }
        },
        kanban: '/dashboard/kanban',
        logistics: {
            index: '/dashboard/logistics',
            fleet: '/dashboard/logistics/fleet'
        },
        mail: '/dashboard/mail',
        orders: {
            index: '/dashboard/orders',
            details: '/dashboard/orders/:orderId'
        },
        products: {
            index: '/dashboard/products',
            create: '/dashboard/products/create'
        },
        social: {
            index: '/dashboard/social',
            profile: '/dashboard/social/profile',
            feed: '/dashboard/social/feed'
        }
    },
    components: {
        index: '/components',
        dataDisplay: {
            detailLists: '/components/data-display/detail-lists',
            tables: '/components/data-display/tables',
            quickStats: '/components/data-display/quick-stats'
        },
        lists: {
            groupedLists: '/components/lists/grouped-lists',
            gridLists: '/components/lists/grid-lists'
        },
        forms: '/components/forms',
        modals: '/components/modals',
        charts: '/components/charts',
        buttons: '/components/buttons',
        typography: '/components/typography',
        colors: '/components/colors',
        inputs: '/components/inputs'
    },
    customerForm: {
        index: '/form/:formId'
    },
    docs: 'https://material-kit-pro-react-docs.devias.io',
    401: '/401',
    404: '/404',
    500: '/500'
};
