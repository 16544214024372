import { v4 } from "uuid";

function FormTemplate() {
	const currentDate = new Date();
	const futureDate = new Date(currentDate);
	futureDate.setFullYear(currentDate.getFullYear() + 1000);

	return {
		content: {
			id: v4(),
			order: 0,
			page: "Settings",
			children: [],
			formName: "New Form (1)",
			category: "Insurance",
			briefDescription: "-",
			autoApprove: "No",
			renewalReminder: "None",
			formExpiry: futureDate.toISOString().split('T')[0],
			pages: [
				{
					id: v4(),
					order: 1,
					page: "Page 1",
					children: [
						{
							id: v4(),
							order: 0,
							type: "text",
							label: "First Name",
							value: "",
							isDefault: "fName",
							required: true,
						},
						{
							id: v4(),
							order: 1,
							type: "text",
							label: "Last Name",
							value: "",
							isDefault: "lName",
							required: true,
						},
						{
							id: v4(),
							order: 2,
							type: "email",
							label: "Email",
							value: "",
							isDefault: "userEmail",
							required: true,
							validate: true,
						},
					],
				},
			],
		},
	};
}

export default FormTemplate;
