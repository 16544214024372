import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import User03Icon from "@untitled-ui/icons-react/build/esm/User03";
import {
	Box,
	Button,
	Divider,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Popover,
	SvgIcon,
	Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useMockedUser } from "src/hooks/use-mocked-user";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AuthContextType } from "src/contexts/auth/jwt-context";

interface AccountPopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
	const { anchorEl, onClose, open, ...other } = props;
	const [redirect, setRedirect] = useState(false);
	const navigate = useNavigate();
	const router = useRouter();
	const auth = useAuth();
    const location = useLocation();
    const {initialize, signOut} = useAuth<AuthContextType>();
    
	// const user = useMockedUser();
	const user = useSelector((state: any) => state.user.user);

	useEffect(() => {
		// onClose?.();
		if (redirect && !open) {
			setRedirect(false);
			navigate(paths.dashboard.settings);
		}
		
        const accessToken = getCookieValue('accessToken')
	}, [open]);

	function close() {
		onClose?.();
		setRedirect(true);
	}

	function checkCookie(name: string) {
		const cookies = document.cookie.split(";"); 
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim(); 
			if (cookie.startsWith(name + "=")) {
				return true; 
			}
		}
		return false;
	}

    function getCookieValue(name:string) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(name + '=')) {
            const cookieValue = cookie.substring(name.length + 1);
            return decodeURIComponent(cookieValue); 
          }
        }
        return null; 
    }
    
    async function onSwitchBussinessClick() {
        const accessToken = getCookieValue('accessToken')
        if (accessToken) {
            // await signOut()
            localStorage.setItem('accessToken', accessToken)
            await initialize()
          setTimeout(() => {
            document.cookie = 'accessToken' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			navigate(paths.dashboard.admin);
          }, 500);

        }
        
        onClose?.();
    }



	const handleLogout = useCallback(async (): Promise<void> => {
		try {
			onClose?.();

			switch (auth.issuer) {
				case Issuer.JWT: {
					await auth.signOut();
					break;
				}

				default: {
					console.warn(
						"Using an unknown Auth Issuer, did not log out"
					);
				}
			}

			router.push(paths.index);
		} catch (err) {
			console.error(err);
			toast.error("Something went wrong!");
		}
	}, [auth, router, onClose]);

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: "center",
				vertical: "bottom",
			}}
			disableScrollLock
			onClose={onClose}
			open={!!open}
			PaperProps={{ sx: { width: 'auto' } }}
			{...other}
		>
			<Box sx={{ p: 2 }}>
				<Typography variant="body1">
					{user.firstName + " " + user.lastName}
				</Typography>
				<Typography color="text.secondary" variant="body2">
					{user.email}
				</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1 }}>
				{checkCookie("accessToken") && (
					<ListItemButton
						component={RouterLink}
						href={"#"}
						onClick={() => {
							onSwitchBussinessClick();
						}}
						sx={{
							borderRadius: 1,
							px: 1,
							py: 0.5,
						}}
					>
						<ListItemIcon>
							<SvgIcon fontSize="small">
								<User03Icon />
							</SvgIcon>
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography variant="body1">
									Switch Business
								</Typography>
							}
						/>
					</ListItemButton>
				)}
				{/* { user.isAdmin && (
					<ListItemButton
						component={RouterLink}
						href={paths.dashboard.prospects}
                        onClick={() => {
                            if (onClose) {
                                onClose()
                            }
						}}
						sx={{
							borderRadius: 1,
							px: 1,
							py: 0.5,
						}}
					>
						<ListItemIcon>
							<SvgIcon fontSize="small">
								<User03Icon />
							</SvgIcon>
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography variant="body1">
									Prospects
								</Typography>
							}
						/>
					</ListItemButton>
				)} */}
				<ListItemButton
					component={RouterLink}
					href={paths.dashboard.settings}
					onClick={(event) => {
						// event.preventDefault();
						close();
					}}
					sx={{
						borderRadius: 1,
						px: 1,
						py: 0.5,
					}}
				>
					<ListItemIcon>
						<SvgIcon fontSize="small">
							<Settings04Icon />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography variant="body1">Settings</Typography>
						}
					/>
				</ListItemButton>
			</Box>
			<Divider sx={{ my: "0 !important" }} />
			<Box
				sx={{
					display: "flex",
					p: 1,
					justifyContent: "center",
				}}
			>
				<Button color="inherit" onClick={handleLogout} size="small">
					Logout
				</Button>
			</Box>
		</Popover>
	);
};

AccountPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,
};
