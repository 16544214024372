import { lazy } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { IssuerGuard } from "src/guards/issuer-guard";
import { AuthGuard } from "src/guards/auth-guard";
import { Layout as MainLayout } from "src/layouts/dashboard/index";
import { Issuer } from "src/utils/auth";
import { paths } from "src/paths";

// JWT
const KycBuilder = lazy(() => import("src/pages/kyc-builder/index"));
const Dashboard = lazy(() => import("src/pages/dashboard/index"));
const Overview = lazy(() => import("src/pages/kyc-overview/index"));
const Notifications = lazy(() => import("src/pages/notifications/index"));
const Settings = lazy(() => import("src/pages/settings/index"));
const SubmittedForms = lazy(() => import("src/pages/submitted-forms/index"));
const KYCForms = lazy(() => import("src/pages/kyc-forms/index"));
const AdminPanel = lazy(() => import("src/pages/admin-panel/index"));
const Prospects = lazy(() => import("src/pages/admin-panel/pending-clients/index"));

const CustomerEditPage = lazy(() => import("src/pages/submitted-forms/edit"));
const EditWorker = lazy(
	() => import("src/pages/settings/teamEditWorkerComponent")
);

export const dashboard: RouteObject[] = [
	{
		path: paths.dashboard.index,
		element: (
			<IssuerGuard issuer={Issuer.JWT}>
				<AuthGuard>
					<MainLayout>
						<Outlet />
					</MainLayout>
				</AuthGuard>
			</IssuerGuard>
		),
		children: [
			{
				path: "",
				element: <Dashboard />,
			},
			{
				path: paths.dashboard.kycBuilder,
                element: <KycBuilder />,
                children: [
                    {
                      path: ":formId",
                    },
                ]
			},
			{
				path: paths.dashboard.settings,
				element: <Settings />,
			},
			{
				path: paths.dashboard.kycOverview,
				element: <Overview />,
			},
			{
				path: paths.dashboard.notifications,
				element: <Notifications />,
			},
			{
                path: paths.dashboard.submittedForms.index,
                element: <SubmittedForms />,
                children: [
                  {
                    path: ":customerId",
                    // element: <CustomerDetailPage />,
                  },
                  {
                    path: ":customerId/edit",
                    element: <CustomerEditPage />,
                  },
                ],
              },
			{
				path: paths.dashboard.kycForms,
				element: <KYCForms />,
			},
			{
				path: paths.dashboard.admin,
				element: <AdminPanel />,
			},
			{
				path: paths.dashboard.prospects,
				element: <Prospects />,
			},
			// {
			//     path: paths.dashboard.team.editWorker,
			//     element: (
			//             <EditWorker />
			//     )
			// },
		],
	},
];
