import {slice} from 'src/slices/company';
import type {AppThunk} from 'src/store';
import {companyApi} from "src/api/company";

const getCompany = (company: {}): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.getCompany(company));
};

const createCompany = (company: {  name: string, color: string, industry: string, address: string, phone: string, logo: any }): AppThunk => async (dispatch): Promise<void> => {
    const response = await companyApi.setCompanyData(company);
    dispatch(slice.actions.getCompany(response.data));
};

const updateCompany = (company: { id:string, name?: string, color?: string, industry?: string, address?: string, phone?: string, logo?: any }): AppThunk => async (dispatch): Promise<void> => {
    const response = await companyApi.updateCompanyData(company);
    dispatch(slice.actions.updateCompany(response.data));
    // dispatch(userSlice.actions.updateUserCompany(response.data));
};

export const thunks = {
    getCompany,
    createCompany,
    updateCompany,
};
