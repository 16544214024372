import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { Notification } from 'src/types/notification';

interface NotificationsState {
    unreadNotificationsCount: number
}

interface GetUnreadNotificationsCountPayload {
    count: number
}

type GetUnreadNotificationsCountAction = PayloadAction<GetUnreadNotificationsCountPayload>

const initialState: NotificationsState = {
    unreadNotificationsCount: 0
}

const reducers = {
    setUnreadNotificationsCount(state: NotificationsState, action: GetUnreadNotificationsCountAction): void {
        state.unreadNotificationsCount = action.payload.count
    },
}
export const slice = createSlice({
    name: 'notification',
    initialState,
    reducers
});

export const { reducer } = slice;

