import 'src/assets/kyc-builder.css';
import React, {useState, useEffect, FC} from "react";
import {
    Box,
    Button,
    Modal,
    Typography,
    Divider,
} from "@mui/material";
import {useSelector} from "react-redux";
import {selectInputByType} from "src/utils/kyc-form-bilder";
import {hexToRGB} from "src/utils/kyc-form-bilder";

interface PreviewModalProps {
    isOpenPreview: any;
    setIsOpenPreview: any;
    isDataCollected: boolean;
}

const PreviewModal: FC<PreviewModalProps> = ({isOpenPreview, setIsOpenPreview, isDataCollected}) => {
    const handleClosePreviewModal = () => {
        setIsOpenPreview(false);
    }

    const reduxFormSettings: any = useSelector((state: any) => state.form.forms);
    const companySettings: any = useSelector((state: any) => state.company.company);
    const [selectedPage, setSelectedPage] = useState({
        order: 1
    });
    const [pages, setPages] = useState([{}]);
    const [companyName, setCompanyName] = useState('');
    const [companyColor, setCompanyColor] = useState('');

    useEffect(() => {
        setCompanyName(companySettings.name);
        setCompanyColor(companySettings.color);
        let pagesList: any[] = [];
        for (const key in reduxFormSettings) {
            if (reduxFormSettings.hasOwnProperty(key)) {
                if (!reduxFormSettings[key].hasOwnProperty('formName')) {
                    pagesList.push(reduxFormSettings[key]);
                }
            }
        }
        setPages([...pagesList])
        setSelectedPage({order: 1});
        // eslint-disable-next-line
    }, [isOpenPreview, reduxFormSettings])

    const nextOrSubmitAction = () => {
        if(selectedPage.order !== pages.length) {
            setSelectedPage({
                order: selectedPage.order + 1
            })
        }
    }

    return (
        <Modal
            open={isOpenPreview}
            onClose={handleClosePreviewModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    maxWidth: '359px ',
                    width: '100%',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '20px',
                    background: '#FFFFFF',
                    boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.08)',
                    maxHeight: '100vh',
                    overflow: 'auto',
                }}
            >
                <Box
                    sx={{
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                    }}
                >
                    <Box
                        sx={{
                            height: '8px',
                            borderRadius: '8px',
                            background: companyColor ?
                                hexToRGB(companyColor, selectedPage.order === pages.length ? 1 : 0.50) :
                                hexToRGB('#0AB730', selectedPage.order === pages.length ? 1 : 0.50),
                            width: '100%',
                            marginBottom: '23px'
                        }}
                    >
                        <Box
                            sx={{
                                height: '8px',
                                borderRadius: '8px',
                                background: companyColor ? companyColor : '#0AB730',
                                opacity: (selectedPage.order - 1) === pages.length ? 0.8: 1,
                                marginLeft: `${(100 / pages.length) * (selectedPage.order - 1)}%`,
                                width: `${100 / pages.length}%`,
                                transition: 'margin-left 0.3s ease-in-out',
                            }}
                        />
                    </Box>
                    {
                        pages.filter((el: any) => (selectedPage.order === el.order)).map((el: any) => {
                            let childrenList: any = [];
                            if(el.children) {
                                el.children.forEach((el: any) => {
                                    childrenList.push(el)
                                })
                            }
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gridGap: '9px',
                                    }}
                                    key={el.order + 'progress'}
                                >
                                    {
                                        el.children ?
                                            childrenList
                                                .sort((a: any, b: any) => (a.order - b.order))
                                                .map((child: any) => {
                                                    return selectInputByType(child, true);
                                        }) : null
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>

                <Divider
                    sx={{
                        marginTop: '23px',
                        strokeWidth: '1px',
                        stroke: '#D7D7D7',
                        filter: 'drop-shadow(0px 2px 4px rgba(184, 184, 184, 0.50))',
                    }}
                />
                <Box
                    sx={{
                        marginTop: '26px',
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gridGap: '14px',
                        }}
                    >
                        {
                            selectedPage.order !== 1 ? (
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    background: companyColor ? companyColor : '#00C650',
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '157%',
                                    marginBottom: '17px',
                                    padding: '0',
                                    ".hoverEffect": {
                                        width: '100%',
                                        padding: '8px 20px',
                                        borderRadius: '12px',
                                        transition: 'background 0.3s ease-in-out',
                                    },
                                    "&:hover": {
                                        background: companyColor ? companyColor : '#00C650',
                                        ".hoverEffect": {
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            transition: 'background 0.3s ease-in-out',
                                        },
                                    }
                                }}
                                onClick={()=>{
                                    setSelectedPage({
                                        order: selectedPage.order - 1
                                    })
                                }}
                            >
                                <div className='hoverEffect' >
                                    Back
                                </div>
                            </Button>) : null
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                width: '100%',
                                background: companyColor ? companyColor : '#00C650',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '157%',
                                marginBottom: '17px',
                                padding: '0',
                                ".hoverEffect": {
                                    width: '100%',
                                    padding: '8px 20px',
                                    borderRadius: '12px',
                                    transition: 'background 0.3s ease-in-out',
                                },
                                "&:hover": {
                                    background: companyColor ? companyColor : '#00C650',
                                    ".hoverEffect": {
                                        background: 'rgba(0, 0, 0, 0.5)',
                                        transition: 'background 0.3s ease-in-out',
                                    },
                                }
                            }}
                            onClick={nextOrSubmitAction}
                        >
                            <div className="hoverEffect">
                                {selectedPage.order === pages.length ? 'Submit' : 'Next'}
                            </div>
                        </Button>
                    </Box>
                    <Typography
                        variant="body2"
                        sx={{textAlign: 'center', paddingLeft: '24px', paddingRight: '24px'}}
                    >
                        Brought to you by {companyName ? companyName : '*Company name*'} and YesYou Limited
                    </Typography>
                </Box>
            </Box>
        </Modal>
    )
}

export default PreviewModal;