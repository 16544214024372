import {FC, useEffect, useState} from "react";
import {Box, Button, SvgIcon, Typography} from "@mui/material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import {FormBuilderProps} from "src/types/kyc-form-builder";
import {generateInput} from "src/utils/kyc-form-bilder";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import {useDispatch} from "src/store";
import { thunks } from 'src/thunks/forms';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {useSelector} from "react-redux";

const FormBuilder: FC<FormBuilderProps> = ({
    myData,
    state,
    setOpenSettingMenu,
    setOpenInputTypesMenu,
    isSettingsOpened,
    formDbId,
    formStatus,
}) => {
    const dispatchForms: any = useDispatch();
    const companySettings: any = useSelector((state: any) => state.company.company);

    const [data, setData] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [companyColor, setCompanyColor] = useState('');

    useEffect(() => {
        setCompanyName(companySettings.name);
        setCompanyColor(companySettings.color);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        let childrenList: any = [];
        myData?.children?.forEach((item: any) => {
            childrenList.push(item)
        })
        setData(childrenList);
        // eslint-disable-next-line
    }, [myData])

    return (
        <form
            style={{minWidth: '509px', maxWidth: '509px'}}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '16px',
                    paddingLeft: '16px',
                    paddingBottom: '8px',
                    paddingTop: '8px',
                    gridGap: '16px'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '157%',
                    }}
                >
                    Page {myData.order}
                </Typography>
                <Box
                    sx={{
                        marginLeft: 'auto',
                        marginRight: '0'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '18px',
                            letterSpacing: '0.16px',
                            paddingBottom: '7px',
                            paddingTop: '7px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            borderRadius: '12px',
                            background: '#E5E7EB',
                        }}
                    >
                        {myData?.children?.length}
                    </Typography>
                </Box>
                {
                    formStatus === 'Saved' && myData.order !== 1 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <SvgIcon
                                onClick={() => (
                                    dispatchForms(thunks.deleteForm({
                                        formId: myData.id,
                                        prevState: state,
                                        formDbId: formDbId
                                    }))
                                )}
                                fontSize={'small'}
                            >
                                <DeleteSharpIcon />
                            </SvgIcon>
                        </Box>
                    ) : null
                }
            </Box>
            <Box
                sx={{
                    padding: '24px',
                    borderRadius: '20px',
                    background: '#F8F9FA'
                }}
            >
                <Box
                    sx={{
                        padding: '24px',
                        borderRadius: '20px',
                        background: '#FFFFFF'
                    }}
                >
                    <Droppable droppableId={myData.id}>
                        {(provided: any) => {
                            return(
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gridGap: '15px',
                                    minHeight: '42px',
                                    border: data.length > 0 ? 'none' : '1px solid rgba(108, 115, 127, 0.50)',
                                    borderRadius: '8px'
                                }}
                            >
                                {
                                    data.sort((a: any, b: any) => (a.order - b.order)).map((element: any, index: any) => (
                                        <div
                                            key={`${index}-${element.order}-${element.label}`}
                                        >
                                            <Draggable
                                                key={element.id}
                                                draggableId={element.id}
                                                index={index}
                                                isDragDisabled={formStatus !== 'Saved'}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        key={`${index}-${element.label}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`draggable-item ${
                                                            snapshot.isDragging ? 'dragging': ''
                                                        }`}
                                                        onClick={(e)=>{
                                                            setOpenSettingMenu({
                                                                isOpen: true,
                                                                formId: myData.id,
                                                                elementId: element.id
                                                            })
                                                        }}
                                                    >
                                                        {generateInput(element)}
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}}
                    </Droppable>
                    <Box
                        sx={{
                            marginTop: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            gridGap: '15px',
                            borderRadius: '20px',
                            background: '#FFFFFF'
                        }}
                    >
                        { formStatus === 'Saved' && <Button
                            onClick={()=> {
                                setOpenInputTypesMenu({isOpen: true, formId: myData.id});
                                // setPageId(myData.id);
                            }}
                            sx={{
                                cursor: 'pointer',
                                padding: '16px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gridGap: '8px',
                                borderRadius: '20px',
                                background: '#FFF',
                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
                                color: '#6C737F',
                                justifyContent: 'flex-start',
                                height: 'auto',
                                "&:hover": {
                                    background: 'rgba(99, 102, 241, 0.04)'
                                }
                            }}
                        >
                            <SvgIcon>
                                <AddSharpIcon/>
                            </SvgIcon>
                            Add Component
                        </Button>}
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: '20px',
                                background: companyColor ? companyColor : '#00C650',
                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
                                paddingTop: '6px',
                                paddingBottom: '6px',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                gridGap: '8px',
                                color: '#FFFFFF',
                                border: 'none',
                                "&:hover": {
                                    background: companyColor ? companyColor : '#42B68A',
                                    border: 'none',
                                },
                            }}
                        >
                            {
                                (Object.keys(state).length - 1) === myData.order ? 'Submit' : 'Next'
                            }
                        </Button>
                        <Typography
                            sx={{textAlign: 'center'}}
                            variant={"caption"}
                        >
                            Brought to you by {companyName ? companyName : '*Company name*'} and YesYou Limited
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </form>
    )
}

export default FormBuilder;