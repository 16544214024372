import type { FC } from 'react';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Avatar, Box, ButtonBase, SvgIcon } from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { usePopover } from 'src/hooks/use-popover';
import { AccountPopover } from './account-popover';
import {useSelector} from "react-redux";
import React from "react";

export const AccountButton: FC = () => {
  const src = '/assets/avatars/avatar-anika-visser.png';
  const popover = usePopover<HTMLButtonElement>();
    const user = useSelector((state: any) => state.user.user);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
          src={user.photo ? user.photo : src}
        >
        </Avatar>
      </Box>
        {
            popover.open && (<AccountPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />)
        }
    </>
  );
};
