import type { FC, ReactNode } from 'react';
import { Box, } from '@mui/material';


interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row'
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'neutral.800',
          // backgroundImage: 'url("./assets/img/auth_background.png")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          color: 'common.white',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
            md: '1 1 auto'
          },
          justifyContent: 'center',
          // p: {
          //   xs: 4,
          //   md: 8
          // }
        }}
      >
          <video
              style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
              }}
              src='/assets/videos/Yes_You.mp4'
              autoPlay
              muted
              loop
          />
        <Box maxWidth="md">
            {/*<p style={{*/}
            {/*    fontFamily: 'Inter-Bold',*/}
            {/*    fontSize: '32px',*/}
            {/*    fontWeight: 700,*/}
            {/*    color: '#ffffff',*/}
            {/*    margin: '0 0 8px'*/}
            {/*}}>Welcome to YesYou.</p>*/}
            {/*<p style={{*/}
            {/*    fontFamily: 'Inter',*/}
            {/*    fontSize: '16px',*/}
            {/*    fontWeight: 400,*/}
            {/*    color: '#6C737F',*/}
            {/*    margin: 0*/}
            {/*}}>Making your compliance compliant. </p>*/}
            <p style={{
                fontFamily: 'Inter-SemiBold',
                fontSize: '12px',
                fontWeight: 500,
                color: '#ffffff',
                margin: 0,
                position: 'absolute',
                bottom: '45px',
                left: '64px'
            }}>© {new Date().getFullYear()} YesYou. All Rights Reserved </p>

        </Box>
      </Box>
      <Box
        className='auth'
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          // padding: '63px',
          flex: {
            xs: '1 1 auto',
            md: '0 0 auto'
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center'
          },
          maxWidth: '100%',
          p: {
            xs: 4,
            md: '63px'
          },
          width: {
            md: 600
          },
            position: 'relative'
        }}
      >
        <div>
          <Box sx={{ mb: 4 }}>
          </Box>
          {children}
        </div>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
//   children: PropTypes.node
};
