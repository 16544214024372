import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { authApi } from 'src/api/auth';
import type  {User}  from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import { useDispatch } from 'src/store';
import { thunks as userThunks } from 'src/thunks/user';
import { thunks as companyThunks } from 'src/thunks/company';
import { thunks as announcementThunks } from 'src/thunks/announcement';
import {companyApi} from "src/api/company";
import {slice as userSlice} from "src/slices/user";
import {slice as companySlice} from "src/slices/company";
import {slice as announcementSlice} from "src/slices/announcement";

const STORAGE_KEY = 'accessToken';

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

enum ActionType {
    INITIALIZE = 'INITIALIZE',
    SIGN_IN = 'SIGN_IN',
    SIGN_UP = 'SIGN_UP',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    SUBMIT_PIN = 'SUBMIT_PIN',
    RESET_PASSWORD = 'RESET_PASSWORD',
    SIGN_OUT = 'SIGN_OUT',
    RESENT_REGISTER_CODE = 'RESENT_REGISTER_CODE'
}

type InitializeAction = {
    type: ActionType.INITIALIZE;
    payload: {
        isAuthenticated: boolean;
        user: User | null;
    };
};
type SignInAction = {
    type: ActionType.SIGN_IN;
    payload: {
        user: User;
    };
};
type SignUpAction = {
    type: ActionType.SIGN_UP;
    payload: {
        user: User;
    };
};
type ForgotPassword = {
    type: ActionType.FORGOT_PASSWORD;
    payload: {
        user: User;
    };
};
type ResentRegisterCode = {
    type: ActionType.RESENT_REGISTER_CODE;
    payload: {
        user: User;
    };
};
type SubmitPin = {
    type: ActionType.SUBMIT_PIN;
    payload: {
        user: User;
    };
};
type ResetPassword = {
    type: ActionType.RESET_PASSWORD;
    payload: {
        user: User;
    };
};
type SignOutAction = {
    type: ActionType.SIGN_OUT;
};

type Action =
    | InitializeAction
    | SignInAction
    | SignUpAction
    | ForgotPassword
    | SubmitPin
    | ResetPassword
    | ResentRegisterCode
    | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers: Record<ActionType, Handler> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    SIGN_IN: (state: State, action: SignInAction): State => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    SIGN_UP: (state: State, action: SignUpAction): State => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    FORGOT_PASSWORD: (state: State, action: ForgotPassword): State => {
        return {
            ...state,
            isAuthenticated: false,
            user: null

        };
    },
    RESENT_REGISTER_CODE: (state: State, action: ResentRegisterCode): State => {
        return {
            ...state,
            isAuthenticated: false,
            user: null

        };
    },
    SUBMIT_PIN: (state: State, action: SubmitPin): State => {
        return {
            ...state,
            isAuthenticated: false,
            user: null

        };
    },
    RESET_PASSWORD: (state: State, action: ResetPassword): State => {
        return {
            ...state,
            isAuthenticated: false,
            user: null

        };
    },

    SIGN_OUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null
    })
};

const reducer = (state: State, action: Action): State => (
    handlers[action.type] ? handlers[action.type](state, action) : state
);

export interface AuthContextType extends State {
    issuer: Issuer.JWT;
    signIn: (email: string, password: string) => Promise<void>;
    forgotPassword: (email: string) => Promise<void>;
    resentRegisterCode: (email: string) => Promise<void>;
    submitPin: (email: string, code: string,urlSelect: string) => Promise<void>;
    resetPassword: (email: string, code: string, password: string, confirmPassword: string) => Promise<void>;
    signUp: (email: string, firstName: string, lastName: string, password: string, confirmPassword: string, code: string | null) => Promise<void>;
    initialize: () => Promise<void>;
    signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
    ...initialState,
    issuer: Issuer.JWT,
    signIn: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    resentRegisterCode: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    submitPin: () => Promise.resolve(),
    signUp: () => Promise.resolve(),
    initialize: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const userDispatch = useDispatch();

    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(
        async (): Promise<void> => {
            try {
                const accessToken = window.localStorage.getItem(STORAGE_KEY);
                if (accessToken) {
                    const resp = await authApi.me({ accessToken });
                    const user = resp.user
                    userDispatch(userThunks.getUser(resp.user));
                    if(resp.user.companies && resp.user.companies.length>0){
                        userDispatch(companyThunks.getCompany(resp.user.companies[0]));
                        const announcement = await companyApi.getAnnouncementSettings({ id: resp.user.companies[0].id, accessToken });
                        userDispatch(announcementThunks.getAnnouncement(announcement.settings));
                    } else if (resp.user.workingProfiles && resp.user.workingProfiles[0]) {
                        // const companyData = await companyApi.getCompanyById({
                        //     accessToken: accessToken,
                        //     id: resp.user.workingProfiles[0].company
                        // })
                        userDispatch(companyThunks.getCompany({ ...resp.user.workingProfiles[0], id: resp.user.workingProfiles[0].company }));
                        if (resp.user.workingProfiles[0].role === "Admin") {
                            
                            const announcement = await companyApi.getAnnouncementSettings({ id: resp.user.workingProfiles[0].company, accessToken });
                            userDispatch(announcementThunks.getAnnouncement(announcement.settings));
                        }
                    }
                    dispatch({
                        type: ActionType.INITIALIZE,
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: ActionType.INITIALIZE,
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        },
        [dispatch]
    );

    useEffect(
        () => {
            initialize();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const signIn = useCallback(
        async (email: string, password: string): Promise<void> => {
            await authApi.signIn({ email, password });
            const { accessToken } = await authApi.signIn({ email, password });
            const data = await authApi.me({ accessToken});
            localStorage.setItem(STORAGE_KEY, accessToken);
            userDispatch(userThunks.getUser(data.user));
            if(data.user.companies && data.user.companies.length>0){
                const companyData = await companyApi.getCompanyById({
                    accessToken: accessToken,
                    id: data.user.companies[0].id
                })
                userDispatch(companyThunks.getCompany(companyData.company));
                const announcement = await companyApi.getAnnouncementSettings({ id: data.user.companies[0].id, accessToken });
                userDispatch(announcementThunks.getAnnouncement(announcement.settings));
            }else if (data.user.workingProfiles && data.user.workingProfiles[0]) {
                // const companyData = await companyApi.getCompanyById({
                //     accessToken: accessToken,
                //     id: data.user.workingProfiles[0].company
                // })
                userDispatch(companyThunks.getCompany({ ...data.user.workingProfiles[0], id: data.user.workingProfiles[0].company }));

                if (data.user.workingProfiles[0].role === "Admin") {
                    const announcement = await companyApi.getAnnouncementSettings({ id: data.user.workingProfiles[0].company, accessToken });
                    userDispatch(announcementThunks.getAnnouncement(announcement.settings));
                }
            }
            dispatch({
                type: ActionType.SIGN_IN,
                payload: {
                    user: data.user
                }
            });
        },
        [dispatch]
    );

    const forgotPassword = useCallback(
        async (email: string): Promise<void> => {
            await authApi.forgotPassword({ email });
            // const user = await authApi.me({ accessToken });
        },
        []
    );

    const resetPassword = useCallback(
        async (email: string, code: string, password: string, confirmPassword: string): Promise<void> => {
            await authApi.resetPassword({ email, code, password, confirmPassword});
        },
        []
    );

    const resentRegisterCode = useCallback(
        async (email: string): Promise<void> => {
            await authApi.resentRegisterCode({ email });
        },
        []
    );

    const submitPin = useCallback(
        async (email: string, code: string, urlSelect: string): Promise<void> => {
            if (urlSelect !== 'forgotURL'){
                const  response = await authApi.submitPin({ email, code, urlSelect});
                if (response.accessToken){
                    localStorage.setItem(STORAGE_KEY, response.accessToken);
                    const user = await authApi.me({ accessToken: response.accessToken, isFirst: true });
                    await initialize()
                    userDispatch(userThunks.getUser(user.user));
                    dispatch({
                        type: ActionType.SIGN_IN,
                        payload: {
                            user
                        }
                    });
                    sessionStorage.setItem("registerByInvite", 'true')
                } else if (!response.accessToken) {
                    sessionStorage.setItem("registerByInvite", 'false')
                }
            } else {
               await authApi.submitPin({ email, code, urlSelect});
            }
        },
        [dispatch]
    );

    const signUp = useCallback(
        async (email: string,
               firstName: string,
               lastName: string,
               password: string,
               confirmPassword: string, 
               code: string | null): Promise<void> => {
            await authApi.signUp({
                email,
                firstName,
                lastName,
                password,
                confirmPassword,
                code,
            });
            // const user = await authApi.me({accessToken});

            // localStorage.setItem(STORAGE_KEY, accessToken);
        },
        []
    );

    const signOut = useCallback(
        async (): Promise<void> => {
            localStorage.removeItem(STORAGE_KEY);
            userDispatch(userSlice.actions.clearState({}))
            userDispatch(companySlice.actions.clearState({}))
            userDispatch(announcementSlice.actions.clearState({}))
            dispatch({ type: ActionType.SIGN_OUT });
        },
        [dispatch]
    );

    return (
        <AuthContext.Provider
            value={{
                ...state,
                issuer: Issuer.JWT,
                signIn,
                forgotPassword,
                resetPassword,
                resentRegisterCode,
                submitPin,
                signUp,
                initialize,
                signOut
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    // children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
