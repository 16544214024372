import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {Company} from 'src/types/company';

interface CompanyState {
    company: {};
}

type GetCompanyAction = PayloadAction<Company>;
type UpdateCompanyAction = PayloadAction<Company>;

const initialState: CompanyState = {
    company: {},
};

const reducers = {
    getCompany(state: Company, action: GetCompanyAction): void {
        state.company = action.payload;
    },

    updateCompany(state: Company, action: UpdateCompanyAction): void {
        state.company = action.payload;
        // state.user.user.companies[0]= action.payload;

    },
    clearState(state: any, action: any): void {
        state.company = {}
    },
};

export const slice = createSlice({
    name: 'company',
    initialState,
    reducers
});

export const {reducer} = slice;
