import { usePageView } from 'src/hooks/use-page-view';
import 'src/assets/kyc-builder.css';
import {useState, useEffect, FC} from "react";
import {
    Box, Button,
} from "@mui/material";
import {KYCInputSettingsElement} from "src/types/kyc-form-builder";
import {getInputsForSettings, getSettingsHeader} from "src/utils/kyc-form-bilder";
import {useDispatch} from "src/store";
import {useSelector} from "react-redux";
import {thunks} from "src/thunks/forms";

const generateInputs = (
    inputElement: any,
    setInputElement: any,
    inputValue: any,
    setInputValue: any,
    dispatch: any,
    formId: string,
    setOpenSettingMenu: any,
    formDbId: string,
    formData: any,
    formStatus?: string
) => {
    let subheaderText = null;
    switch (inputElement.type) {
        case 'head': subheaderText = '14pt, Bolded'; break;
        case 'subhead': subheaderText = '12pt, Bolded'; break;
        case 'paragraph': subheaderText = '12pt, Regular'; break;
    }

    return (
        <>
            <h3 className="title">
                <span>{getSettingsHeader(inputElement.type)}</span>
                <span className="subheader" >{subheaderText}</span>
            </h3>
            {
                getInputsForSettings(inputElement, setInputElement, inputValue, setInputValue, dispatch, formStatus)
            }
            {formStatus === 'Saved' && <Button
                variant='text'
                className="btn delete"
                disabled={inputElement.hasOwnProperty('isDefault')}
                onClick={() => {
                    dispatch(thunks.deleteFormChild({
                        formId: formId,
                        childrenId: inputElement.id,
                        formDbId,
                        formData
                    }))
                    setOpenSettingMenu({isOpen: false, formId: '', elementId: ''});
                }}
            >
                Delete Component
            </Button>}
        </>
    )
}

const KYCInputSettings: FC<KYCInputSettingsElement> = ({openSettingMenu, setOpenSettingMenu, isOpen, formDbId, formStatus}) => {
    usePageView();
    const dispatchForms: any = useDispatch();
    const formData = useSelector((state: any) => state.form.forms);
    const [initialChange, setInitialChange] = useState(false);
    const [inputElement, setInputElement] = useState({
        id: '',
        order: 0,
        type: '',
        label: '',
        value: ''
    });

    const [inputValue, setInputValue] = useState({
        label: 'Example',
        required: false,
        validate: false,
        numberOfDigits: 0,
        link: 'https://',
        countryCode: false,
        pattern: '',
    });

    useEffect(() => {
        let timeoutId: any;
        if (inputElement.hasOwnProperty('id') && inputElement.id) {
            clearTimeout(timeoutId);
            if (initialChange) { 
                timeoutId = setTimeout(() => {
                    dispatchForms(thunks.updateFormChild({
                        formId: openSettingMenu.formId,
                        children: {
                            ...inputElement,
                            ...inputValue,
                        },
                        formDbId,
                        formData
                    }));
                }, 300);
            } else {
                setInitialChange(true);
            }
        }
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line
    }, [inputValue]);

    const initPageSettings = () => {
        if(openSettingMenu.formId && openSettingMenu.elementId) {
            const selectedEl = formData[openSettingMenu.formId].children.filter((item: any) => (
                item.id === openSettingMenu.elementId
            ))[0];
            let elementsFromRedux = {};
            for (const inputValueKey in inputValue) {
                if(selectedEl.hasOwnProperty(inputValueKey)) {
                    elementsFromRedux= {
                        ...elementsFromRedux,
                        [inputValueKey]: selectedEl[inputValueKey]
                    }
                }
            }
            setInputValue({...inputValue, ...selectedEl})
            setInputElement(selectedEl);
        }
    }

    useEffect(()=> {
        initPageSettings();
        // eslint-disable-next-line
    },[isOpen]);

    useEffect(() => {
        // eslint-disable-next-line
    }, [openSettingMenu.formId])

    return (
        <>
            {
                openSettingMenu.isOpen && (
                    <Box className="settings-bar">
                        {
                            generateInputs(inputElement, setInputElement, inputValue, setInputValue, dispatchForms, openSettingMenu.formId, setOpenSettingMenu, formDbId, formData, formStatus)
                        }
                    </Box>
                )
            }
        </>
    );
};

export default KYCInputSettings;
