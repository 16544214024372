import React, { useEffect, useState } from 'react';
import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import {countries} from "src/utils/countries";

interface DatePickerProps {
    element: any;
    handleChangeValue: (element: any, value: any, pattern: string) => void;
    isRequiredError: boolean;
    isValidateError: boolean;
    onBlurInput: any;
    disabled?: boolean;
    color?: string;
}

const CountrySelect: React.FC<DatePickerProps> = ({element, handleChangeValue, isRequiredError, isValidateError, onBlurInput, disabled = false, color = '#42B68A' }) => {
    const [selectedValue, setSelectedValue] = useState(countries[0]);

    const handleChange = (event: any, newValue: any) => {
        setSelectedValue(newValue);
    };

    useEffect(() => {
        const event: any = {}
        handleChange(event, countries.filter((country) => (country.name === element.value))[0])
        // eslint-disable-next-line
    }, [])

    if (disabled) {
        return (
            <Box
                sx={{
                    width: '100%',
                    padding: '20px 8px 8px',
                    borderRadius: '8px',
                    border: '1px solid rgba(108, 115, 127, 0.50)',
                    position: 'relative'
                }}
            >
                <Typography variant='caption'
                    sx={{
                        position: 'absolute',
                        color: '#6C737F',
                        fontSize: '11px',
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.15px',
                        left: '0',
                        top: '0',
                        transform: 'translate(4px, 9px) scale(0.85)',
                    }}
                >
                    {element.label}
                </Typography>
                <Typography variant='body1'>{element.value}</Typography>
            </Box>
        )
    }
    return (
        <Box
            key={element.id}
        >
            <Box
                sx={{
                    marginTop: '8px',
                    width: '100%',
                    padding: '0',
                    borderRadius: '8px',
                    border: '1px solid rgba(108, 115, 127, 0.50)',
                    position: 'relative'
                }}
            >
                <Autocomplete
                    sx={{
                        "& .MuiAutocomplete-inputRoot": {
                            border: 'none'
                        },
                        '& .Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        '& .MuiFilledInput-root.Mui-focused': {
                            borderColor: color,
                            boxShadow: `${color} 0 0 0 2px`
                        },
                        '& .Mui-focused': {
                            color: color,
                        },
                    }}
                    fullWidth
                    options={countries}
                    autoHighlight
                    disabled={disabled}
                    getOptionLabel={(option) => option.name ? option.name : countries[0].name}
                    isOptionEqualToValue={(option, value) => {
                        if(value.hasOwnProperty('name')) {
                            return (option.name === value.name)
                        } else {
                            return true;
                        }
                    }}
                    onBlur={onBlurInput}
                    value={selectedValue}
                    onChange={(e, value: any) => {
                        handleChange(e, value);
                        handleChangeValue(element.id, value && value.hasOwnProperty('name') ? value.name : '', '')
                    }}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                        >
                            {option.emoji} {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`${element.label}${element.required ? '*' : ''}`}
                            disabled={disabled}
                            value={selectedValue && selectedValue.hasOwnProperty('name') ? selectedValue.name : ''}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'none',
                                'data-input-id': element.id
                            }}
                        />
                    )}
                />
            </Box>

            {
                isRequiredError && (<Typography
                    sx={{
                        color: '#F04438',
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        lineHeight: '1.66',
                        fontFamily: "Inter, sans-serif",
                        marginTop: '3px'
                    }}
                    variant="body2"
                >
                    This field is required
                </Typography>)
            }
        </Box>
    )
};

export { CountrySelect };
