import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as companyReducer } from 'src/slices/company';
import { reducer as announcementReducer } from 'src/slices/announcement';
import { reducer as formsReducer } from 'src/slices/forms';
import { reducer as notificationsReducer } from 'src/slices/notifications';
export const rootReducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  announcement: announcementReducer,
  form: formsReducer,
  notification: notificationsReducer,
});
