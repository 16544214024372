import type {User} from 'src/types/user';
import axios from 'axios';
import toast from 'react-hot-toast';

type SetPhoneRequest = {
    phone: string;
}
type SetPhoneResponse = Promise<{
    message: string;
}>;
type SetCompanyDataRequest = {
    name: string
    color: string
    industry: string
    phone: string
    address: string
    logo: File | null
}
type SetCompanyDataResponse = Promise<{
    message: string;
    data: {}
}>;
type UpdateCompanyDataRequest = {
    id: string,
    name?: string
    color?: string
    industry?: string
    phone?: string
    address?: string
    logo?: File | null
}
type UpdateCompanyDataResponse = Promise<{
    message: string;
    data: object;
}>;
type UpdateAnnouncementRequest = {
    id: string,
    subject?: string,
    header?: string,
    description?: string,
    buttonText?: string,
    buttonLink?: any
}
type UpdateAnnouncementResponse = Promise<{
    message: string;
    data: object;
}>;

type PhotoUploadRequest = File | null
type PhotoUploadResponse = Promise<{
    url: string;
}>;


type GetRolesResponse = Promise<{
    roles: []
}>;


type GetAnnouncementRequest = {
    id: string,
    accessToken?: any
}
type GetAnnouncementResponse = Promise<{
    settings: {};
}>;
type GetCompanyByIdRequest = {
    id: string,
    accessToken:any

}
type GetCompanyByIdResponse = Promise<{
    company: {  name?: string;
        industry?: string;
        phone?: string;
        address?: string;
        color?: string;
        formExpiry?: string;
    logo?: string};
}>;

type InviteWorkerByEmailRequest = {
    id: string,
    email: string,
    role: string
}
type InviteWorkerByEmailResponse = Promise<{
    response: {
        hash: string
    };
}>;

type ComfirmInviteWorkerByEmailRequest = {

    hash: string
}
type ComfirmInviteWorkerByEmailResponse = Promise<{
    message: string,
    isRegistered: boolean
}>;


// TODO need api to refresh access token
const initialAccessToken = localStorage.getItem('accessToken');
const initialRefreshToken = localStorage.getItem('refreshToken');
// const tokenLifeTime = localStorage.getItem('tokenExpire')
const tokenLifeTime = 1693919549.671
async function refreshAccessToken() {
  try {
    const response = await fetch("/refresh-token-endpoint", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${initialRefreshToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to refresh access token");
    }

    const data = await response.json();
    // setAccessToken(data.access_token); 
  } catch (error) {
    console.error("Error refreshing access token:", error);
  }
}

function checkAccessTokenValidity() {
  if (!initialAccessToken) {
    console.log("User needs to log in again");
    return;
  }


  if (tokenLifeTime && Number(tokenLifeTime) < (Date.now() / 1000)) {
    console.log("Access token is invalid");
    // refreshAccessToken();
  } else {
    console.log("Access token is valid");
  }
}

// TODO need api to refresh access token (above)
class CompanyApi {

    async postData(url: string, requestData: object, headers: object) {
        const accessToken = localStorage.getItem('accessToken');
        const requestHesders = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
                ...headers
            },

        }
        try {
            const response = await axios.post(url, requestData, requestHesders);
            if (response.status === 200) {
                toast.success(response.data.message)
                return response.data;
            } else {
                throw new Error('Some error... Please try again');
            }
        } catch (err) {
            toast.error(err.response.data.message)

            console.log('[Auth Api]: ', err.response.data.message);
            console.warn('[Auth Api]: ', err);
            throw new Error(err.response.data.message);
        }
    }

    async getData(url: string, headers: object) {
        // checkAccessTokenValidity() // add to check&refresh token
        try {
            const response = await axios.get(url, headers);
            if (response.status === 200) {
                return response;
            } else {
                throw new Error('Some error... Please try again');
            }
        } catch (err) {
            console.log('[Auth Api]: ', err.response.data.message);

            // console.warn('[Auth Api]: ', err);
            throw new Error(err.response.data.message);
        }
    }

    async setPhone(request: SetPhoneRequest): SetPhoneResponse {
        const {phone} = request;

        const response = await this.postData(process.env.REACT_APP_API_URL + 'api/user/phone', {phone}, {});
        return {message: response.message};
    }

    async setCompanyData(request: SetCompanyDataRequest): SetCompanyDataResponse {
        const {name, color, industry, phone, address, logo} = request;
        const response = await this.postData(process.env.REACT_APP_API_URL + 'api/company', {
            name,
            color,
            industry,
            phone,
            address,
            logo
        }, {'Content-Type': 'multipart/form-data'});
        return {
            message: response.message,
            data: response.data
        };
    }

    async updateCompanyData(request: UpdateCompanyDataRequest): UpdateCompanyDataResponse {
        const {id, name, color, industry, phone, address, logo} = request;
        const response = await this.postData(process.env.REACT_APP_API_URL + `api/company/${id}/profile`, {
            name,
            color,
            industry,
            phone,
            address,
            logo
        }, {'Content-Type': 'multipart/form-data'});
        return {
            message: response.message,
            data: response.data
        };
    }

    async updateAnnouncement(request: UpdateAnnouncementRequest): UpdateAnnouncementResponse {
        const {id, subject, header, description, buttonText, buttonLink} = request;
        const response = await this.postData(process.env.REACT_APP_API_URL + `api/company/${id}/announcement-settings`, {
            id,
            subject,
            header,
            description,
            buttonText,
            buttonLink
        }, {});
        return {
            message: response.message,
            data: response.data
        };
    }

    async uploadPhoto(photo: PhotoUploadRequest): PhotoUploadResponse {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const formData = new FormData();
            formData.append('photo', photo || '');
            const response = await axios.post(process.env.REACT_APP_API_URL + 'api/user/photo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Some error... Please try again');
            }
        } catch (error) {
            console.error('Error uploading photo:', error);
            throw new Error('Some error... Please try again');

        }
    };

    async getRoles(): GetRolesResponse {
        const response = await this.getData(process.env.REACT_APP_API_URL + `api/company/roles`, {
            headers: {
                // 'Authorization': `Bearer ${accessToken}`
            }
        })
        return {roles: response.data.roles}
    }

    async getCompanyById(request: GetCompanyByIdRequest): GetCompanyByIdResponse {
        const {id, accessToken} = request
        const response = await this.getData(process.env.REACT_APP_API_URL + `api/company/${id}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return {company: response.data.company}
    }

    async getAnnouncementSettings(request: GetAnnouncementRequest): GetAnnouncementResponse {
        const {id, accessToken} = request;
        const response = await this.getData(process.env.REACT_APP_API_URL + `api/company/${id}/announcement-settings`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return {settings: response.data.settings}
    }

    async inviteWorkerByEmail(request: InviteWorkerByEmailRequest): InviteWorkerByEmailResponse {
        const {id, email, role} = request;
        const response = await this.postData(`${process.env.REACT_APP_API_URL}api/company/${id}/invite`, {
            id,
            email,
            role
        }, {});
        return {
            response: response.hash
        };
    }

    async confirmInviteWorkerByEmail(request: ComfirmInviteWorkerByEmailRequest): ComfirmInviteWorkerByEmailResponse {
        const {hash} = request;
        const response = await this.postData(`${process.env.REACT_APP_API_URL}api/company/confirm-invite`, {
           hash
        }, {});
        return {
            message: response.message,
            isRegistered: response.isRegistered
        };
    }


}

export const companyApi = new CompanyApi();
