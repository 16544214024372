import type { Customer } from 'src/types/customer';

import api from "src/api";
import axios from "axios";

type GetCustomersRequest = {
  companyId?: string;
  filters?: {
    query?: string;
    status?: string;
    date?: string;
    tabs?: string;
  };
  page?: number;
  rowsPerPage?: number;
  sortBy?: string;
    sortDir?: 'asc' | 'desc';
    isExport?: boolean;
};

type GetCustomersResponse = Promise<{
  data: [];
  count: number;
}>;

type GetCustomerRequest = {
    id: string,
};

type GetCustomerResponse = Promise<Customer>;

type ChangeCustomerStatusRequest = {
  id: string,
  status: string,
  declineReason?: string,
  notify?: boolean
}

type ChangeCustomerStatusResponse = Promise<{
  message : string
}>

type GetCustomerLogsRequest = {
    id:any
};

type GetCustomerLogsResponse = Promise<{
    logs: []
}>;

class CustomersApi {
  async getCustomers(request: GetCustomersRequest = {}): GetCustomersResponse {
    const { companyId, filters, page, rowsPerPage, sortBy, sortDir, isExport } = request;
    const pages = isExport === true ? '?isExport=true' : `?per_page=${rowsPerPage}&page=${page && page + 1}`
    let url = `submitted-form/${companyId}/list${pages}`;
    if(filters){
      if(filters.query){
        url += `&search=${filters.query}`
      }
      if (filters.status){
        url += `&status=${filters.status}`
      }
      if (filters.date){
        url += `&start=${filters.date}`
      }
      if (filters.tabs === "1") {
        url += `&is_renewal=${filters.tabs}`
      }
    }
    
    if(sortBy){
      url += `&sort_by=${sortBy}&order=${sortDir}`
    } else {
      url += `&sort_by=sid&order=${sortDir}`
    }

    const response = await api.call(url, "get");
    return Promise.resolve({
      data: response.customers,
      count: response.total
    });
  }

  async getCustomer(request: GetCustomerRequest): GetCustomerResponse {
    const { id } = request
    const response = await api.call(
        `submitted-form/${id}`, "get"
    );
    return Promise.resolve(response.customer);
  }

  async changeCustomerStatus(request: ChangeCustomerStatusRequest): ChangeCustomerStatusResponse {
    const { id, status, declineReason, notify } = request;
    let body: {status: string, declineReason?: string, notify?: boolean} = { status }
    body.notify = notify;
    if (declineReason) { body.declineReason = declineReason}
    const response = await api.call(`submitted-form/${id}/status`, 'put', body, {"Content-Type": "application/json"})
    return  {message: response.message}
  }

    async getLogs(request: GetCustomerLogsRequest): GetCustomerLogsResponse {
        const { id } = request;
        const response = await api.call(`submitted-form/${id}/logs`, 'get', {} , {"Content-Type": "application/json"})
        return  Promise.resolve(response.logs);
  }

  async setCustomerFormStatus(request: any) {
      const { id } = request;
      const accessToken = localStorage.getItem('accessToken');
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(process.env.REACT_APP_API_URL  + `api/` + `submitted-form/${id}/finish`, {}, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${accessToken}`
            }
          });

          resolve({response})
        } catch (e: any) {
          reject({response: e.response})
        }
      })
  }
  async addCustomer(request: any) {
    const { id, content } = request;
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(process.env.REACT_APP_API_URL  + `api/` + `submitted-form/${id}`, content, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    });
    return { response };
  }
  // async updateCustomer(request: any) {
  //   const { id, content } = request;
  //   const accessToken = localStorage.getItem('accessToken');
  //     const response = await axios.post(process.env.REACT_APP_API_URL  + `api/` + `submitted-form/${id}/fields`, content, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         'Authorization': `Bearer ${accessToken}`
  //       }
  //     });
  //     return new Promise((resolve: any, reject: any) => {try {resolve(response)} catch (err: any) {reject(response)}})
  // }

  async updateCustomer(request: any) {
    const { id, content } = request;
    const accessToken = localStorage.getItem('accessToken');
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL  + `api/` + `submitted-form/${id}/fields`, content, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
          }
        });

        resolve({response})
      } catch (e: any) {
        reject({response: e.response})
      }
    })
  }
    
  async exportAllData(request: GetCustomersRequest = {}): GetCustomersResponse {
    const { companyId } = request;
    
    let url = `submitted-form/${companyId}/list`;

    const response = await api.call(url, "get");
    return Promise.resolve({
      data: response.customers,
      count: response.total
    });
  }
}

export const customersApi = new CustomersApi();
