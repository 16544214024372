import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

interface SeoProps {
  title?: string;
  isAdmin?: boolean;
}

export const Seo: FC<SeoProps> = (props) => {
  const { title, isAdmin } = props;

  const fullTitle = title
    ? title + ' | YesYou'
    : 'YesYou';

  return (
    <Helmet>
      <title>
        {isAdmin ? `YesYou ${title}` : fullTitle}
      </title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
