import axios from "axios";
import toast from "react-hot-toast";

const handleResponse = (response: any) => {
    if (response.status === 200) {
        if (response.data.message) {
			if(response.data.message !== "Notifications were marked as read"){
				toast.success(response.data.message);
			}
        }

		return response.data;
	} else {
		throw new Error("Some error... Please try again");
	}
};

const handleErrorResponse = (err: any) => {
	const errorMessage = err.response?.data?.message || "An error occurred";
	toast.error(errorMessage);
	console.log("[Auth Api]: ", errorMessage);
	console.warn("[Auth Api]: ", err);
	throw new Error(errorMessage);
};

const api = {
	async call(url: string, method: string, requestData = {}, headers = {}) {
		const accessToken = localStorage.getItem("accessToken");
		const createRequestConfig = (headers: any) => ({
			headers: {
				Authorization: `Bearer ${accessToken}`,
				...headers,
			},
		});
		const fetchUrl = process.env.REACT_APP_API_URL + `api/` + url;
		try {
			let response;			
			switch (method) {
				case "get":
					response = await axios.get(
						fetchUrl,
						createRequestConfig(headers)
					);
					break;

				case "post":
					response = await axios.post(
						fetchUrl,
						requestData,
						createRequestConfig(headers)
					);
					break;

				case "put":
					response = await axios.put(
						fetchUrl,
						requestData,
						createRequestConfig(headers)
					);
					break;

				case "delete":
					response = await axios.delete(
						fetchUrl,
						createRequestConfig(headers)
					);
					break;

				default:
					return;
					break;
			}
			return handleResponse(response);
		} catch (err) {
			return handleErrorResponse(err);
		}
	},
};

export default api;
