import {
    slice,
    AddChildren,
    DeleteForm,
    UpdateForm,
    UpdateChildren,
    UpdateOrderAndForm,
    DeleteChildren,
    ClearForm,
    Page,
    changeOrderInputsInPages, changeOrderInputsAfterAdding
} from 'src/slices/forms';
import type { AppThunk } from 'src/store';
import { FormSettings } from "src/types/kyc-form-builder";
import { kycFormsApi } from "src/api/kyc-forms";

const addForm = (forms: FormSettings): AppThunk => async (dispatch): Promise<void> => {
    if (forms.new) {
        const prevState = forms.prevState
        const pagesArray = Object.values(prevState).filter((page: any) => page.page !== "Settings");
        const settings = Object.values(prevState).filter((page: any) => page.page === "Settings");
        let newForm = Object.assign({ pages: [] }, settings[0])
        let newData = Object.assign({}, forms)
        delete newData.prevState
        delete newData.new
        newForm = Object.assign(newForm, { pages: [...pagesArray, newData] })
        if (newData.formDbId)
            kycFormsApi.updateForm({
                content: newForm,
                id: newData.formDbId
            })
        dispatch(slice.actions.addForm(newData));
    } else {
        dispatch(slice.actions.addForm(forms));
    }
};

const updateForm = (forms: UpdateForm): AppThunk => async (dispatch): Promise<void> => {
    try {
        const { formDbId, form, prevState } = forms;
        const pagesArray = Object.values(prevState).filter(
            (page: any) => page.page !== "Settings"
        );
        const content = {
            ...form,
            pages: pagesArray,
        };
        await kycFormsApi.updateForm({
            content,
            id: formDbId,
        });

        dispatch(slice.actions.updateForm(forms));
    } catch (error) {
        console.error("Error:", error);
    }
};


// const deleteForm = (forms: DeleteForm): AppThunk => async (dispatch): Promise<void> => {
//     if (forms.formId) {

//         let state = Object.assign({}, forms.prevState)
//         let newState = {}
//         const deletedOrder = forms.prevState[forms.formId].order;
//         Object.keys(state).map((el: any) => {
//             const currentOrder = forms.prevState[el].order;
//             if (forms.prevState[el].id !== forms.formId) {
//                 newState = {
//                     ...newState,
//                     [el]: {
//                         ...forms.prevState[el],
//                         order: currentOrder < deletedOrder ? currentOrder : currentOrder - 1
//                     }
//                 };
//                 return forms.prevState[el];
//             }
//         })
//         const pagesArray = Object.values(newState).filter((page: any) => page.page !== "Settings");
//         const settings = Object.values(newState).filter((page: any) => page.page === "Settings");
//         let settingsObj = Object.assign({ pages: [] }, settings[0])
//         let newData = Object.assign({}, forms)
//         settingsObj = Object.assign(settingsObj, { pages: [...pagesArray] })
//         let newForm = settingsObj;
//         if (newData.formDbId)
//             kycFormsApi.updateForm({
//                 content: newForm,
//                 id: newData.formDbId
//             })
//         dispatch(slice.actions.deleteForm(newState));
//     }
// };

const deleteForm = (forms: DeleteForm): AppThunk => async (dispatch): Promise<void> => {
    if (!forms.formId) return;

    const { formId, prevState, formDbId } = forms;
    const deletedOrder = prevState[formId].order;
    
    const newState = Object.entries(prevState)
      .filter(([el]) => prevState[el].id !== formId)
      .reduce((acc, [el, page]: [string, any]) => {
        const currentOrder = page.order;
        const newPage: Page = {
          ...page,
          order: currentOrder < deletedOrder ? currentOrder : currentOrder - 1
        };
        return { ...acc, [el]: newPage };
      }, {} as Record<string, Page>);

    const pagesArray = Object.values(newState).filter((page: any) => page.page !== "Settings");
    const settings = Object.values(newState).find((page: any) => page.page === "Settings");

    if (settings) {
      settings.pages = [...pagesArray];
    }

    const newContent = settings || { pages: [] };

    if (formDbId) {
      try {
        await kycFormsApi.updateForm({
          content: newContent,
          id: formDbId
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }

    dispatch(slice.actions.deleteForm(newState));
};

const addFormChild = (forms: AddChildren): AppThunk => async (dispatch): Promise<void> => {
    const { formDbId } = forms;
    const newState = changeOrderInputsAfterAdding({payload: forms, type: "forms/changeFormAndOrderFormChild"})
    const pagesArray = Object.values(newState).filter((page: any) => page.page !== "Settings");
    const settings = Object.values(newState).filter((page: any) => page.page === "Settings");
    let settingsObj = Object.assign({ pages: [] }, settings[0])
    settingsObj = Object.assign(settingsObj, { pages: [...pagesArray] })
    let newForm = settingsObj;

    if (formDbId) {
        kycFormsApi.updateForm({
            content: newForm,
            id: formDbId
        })
    }
    dispatch(slice.actions.addFormChild(forms));
};

const updateFormChild = (forms: UpdateChildren): AppThunk => async (dispatch): Promise<void> => {
    if (!forms.isCustomerForm && forms.formDbId) {
        const { formData, formId, children, formDbId } = forms;

        const newState = {
            ...formData,
            [formId]: {
                ...formData[formId],
                children: [
                    ...formData[formId].children.filter((el: any) => el.id !== children.id),
                    {
                        ...formData[formId].children[children.id],
                        ...children
                    }
                ]
            }
        };

        const pagesArray = Object.values(newState).filter((page: any) => page.page !== "Settings");
        const settings: { pages?: any[] } = Object.values(newState).find((page: any) => page.page === "Settings") || {};
        const updatedSettings = { ...settings, pages: [...pagesArray] };

        try {
            await kycFormsApi.updateForm({
                content: updatedSettings,
                id: formDbId
            });
        } catch (error) {
            console.error("Error:", error);
        }
    }

    dispatch(slice.actions.updateFormChild(forms));
};

const changeFormAndOrderFormChild = (forms: UpdateOrderAndForm): AppThunk => async (dispatch): Promise<void> => {
    if (!forms.isCustomerForm && forms.formDbId) {
        const { formDbId } = forms;
        const res = changeOrderInputsInPages({payload: forms, type: "forms/changeFormAndOrderFormChild"})
        const pagesArray = Object.values(res).filter((page: any) => page.page !== "Settings");
        const settings: { pages?: any[] } = Object.values(res).find((page: any) => page.page === "Settings") || {};
        const updatedSettings = { ...settings, pages: [...pagesArray] };
        try {
            kycFormsApi.updateForm({
                content: updatedSettings,
                id: formDbId
            });
        } catch (error) {
            console.error("Error:", error);
        }
    }
    dispatch(slice.actions.changeFormAndOrderFormChild(forms));
};

const deleteFormChild = (forms: DeleteChildren): AppThunk => async (dispatch): Promise<void> => {
    const { formData, formId, childrenId , formDbId } = forms;
    let state = formData
    let newState =  {
        ...state,
        [formId]: {
            ...state[formId],
            children: [
                ...state[formId].children.filter((el: any) => (
                    el.id !== childrenId
                    )),
                ]
            }
        }
    const pagesArray: any[] = Object.values(newState).filter((page: any) => page.page !== "Settings");

    const settings = Object.values(newState).filter(
        (page: any) => page.page === 'Settings'
    );

    let newForm = Object.assign({ pages: [] }, settings[0])
    newForm = Object.assign(newForm, { pages: [...pagesArray] })


    if (formDbId)
        kycFormsApi.updateForm({
            content: newForm,
            id: formDbId
        })
    dispatch(slice.actions.deleteFormChild(forms));
};

const clearFormContent = (forms: ClearForm): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.clearForm(forms));
};

export const thunks = {
    addForm,
    updateForm,
    deleteForm,
    updateFormChild,
    changeFormAndOrderFormChild,
    addFormChild,
    deleteFormChild,
    clearFormContent
};