import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type { FormSettings } from 'src/types/kyc-form-builder';
import {ElementSettings} from "src/types/kyc-form-builder";

export interface AddChildren {
    formId: string;
    children: ElementSettings;
    formDbId: any,
    state: any
}
export interface UpdateChildren {
    formId: string;
    children: ElementSettings;
    formDbId?: any,
    formData?: any,
    isCustomerForm?: boolean,
}
export interface UpdateOrderAndForm {
    formIdStart: string;
    formIdEnd: string;
    childrenId: string;
    oldOrder: number;
    newOrder: number;
    formDbId?: any;
    formData?: any;
    isCustomerForm?: boolean;
}
export interface DeleteChildren {
    formId: string;
    childrenId: string;
    formDbId?: any,
    formData?: any
}

export interface UpdateForm {
    formId: string;
    formDbId: string;
    prevState: any;
    form: FormSettings;
}
export interface DeleteForm {
    formId?: string;
    prevState?: any;
    index?: string;
    [key: string]: any;
}
export interface ClearForm {

}

export interface Page {
    id: string;
    order: number;
    pages: any;
    page?: any
  }

type AddFormAction = PayloadAction<FormSettings>;
type UpdateFormAction = PayloadAction<UpdateForm>;
type DeleteFormAction = PayloadAction<DeleteForm>;
type ClearFormAction = PayloadAction<ClearForm>;

type AddChildrenAction = PayloadAction<AddChildren>;
type UpdateChildAction = PayloadAction<UpdateChildren>;
type UpdateOrderAndFormAction = PayloadAction<UpdateOrderAndForm>;
type DeleteChildrenAction = PayloadAction<DeleteChildren>;

interface ReduxFormsObject {

        [key: string]: any;
    
}

const initialState: ReduxFormsObject = {
    forms: {}
};

export const changeOrderInputsAfterAdding = (action: AddChildrenAction): any => {
    const {  formId, children, state } = action.payload;
    const uniqueOrder = state[formId].children.filter((el: any) => {
        return el.order === children.order;
    });
    let newChildArray: any = [];
    if (uniqueOrder.length > 0) {
        newChildArray.push(children);
        state[formId].children.forEach((el: any) => {
            if (el.order === children.order || el.order > children.order) {
                newChildArray.push(
                    {
                        ...el,
                        order: el.order + 1
                    }
                )
            } else {
                newChildArray.push(el);
            }
        })
    } else {
        state[formId].children.forEach((el: any) => {
            newChildArray.push(el);
        })
        newChildArray.push(children);
    }
    return {
        ...state,
        [formId]: {
            ...state[formId],
            children: [
                ...newChildArray
            ]
        }
    }
}

export const changeOrderInputsInPages = (action: UpdateOrderAndFormAction): any => {
    const {
        childrenId,
        formData,
        newOrder,
        oldOrder,
        formIdEnd,
        formIdStart
    } = action.payload;
    const settings: any = Object.values(formData).find((page: any) => page.page === "Settings") || {};
    const pagesArray = Object.values(formData).filter((page: any) => page.page !== "Settings");
    const draggableInput: any = formData[formIdStart].children.filter((child: any) => (child.id === childrenId))[0];

    const newPagesArray = pagesArray.map((page: any) => {
        if(page.id === formIdStart && page.id === formIdEnd) {
            let childrenList: any = [];
            page?.children?.forEach((item: any) => {
                childrenList.push(item)
            })

            // TODO: Sometimes in this code we have a bug when items change order is not correctly
            const newChildrenList = childrenList.sort((a: any, b: any) => (a.order - b.order)).map((child: any) => {

                if(child.id === childrenId) {
                    return {
                        ...child,
                        order: newOrder
                    }
                }else{
                    if (newOrder < oldOrder) {
                        if(child.order === newOrder || child.order > newOrder) {
                            return {
                                ...child,
                                order: child.order + 1
                            }
                        } else {
                            return {
                                ...child,
                                order: child.order
                            }
                        }
                    } else {
                        if(child.order === newOrder) {
                            return {
                                ...child,
                                order: child.order - 1
                            }
                        } else if(child.order < newOrder) {
                            return {
                                ...child,
                                order: child.order - 1
                            }
                        }
                        return child;
                    }
                }
            });

            return {
                ...page,
                children: [...newChildrenList]
            };
        } else {
            let childrenList: any = [];
            page?.children?.forEach((item: any) => {
                childrenList.push(item)
            })
            if(page.id === formIdStart) {
                let isDecrement = false;
                const newChildrenList = childrenList
                    .sort((a: any, b: any) => (a.order - b.order))
                    .filter((child: any) => (child.id !== childrenId))
                    .map((child: any) => {
                    if(child.order === oldOrder + 1) {
                        isDecrement = true;
                        return {
                            ...child,
                            order: child.order - 1
                        }
                    } else if (isDecrement) {
                        return {
                            ...child,
                            order: child.order - 1
                        }
                    }
                    return child;
                });
                return {
                    ...page,
                    children: [...newChildrenList]
                };
            } else if(page.id === formIdEnd) {
                let isIncrement = false;
                const newChildrenList = childrenList
                    .sort((a: any, b: any) => (a.order - b.order))
                    .filter((child: any) => (child.id !== childrenId))
                    .map((child: any) => {
                        if(child.order === newOrder) {
                            isIncrement = true;
                            return {
                                ...child,
                                order: child.order + 1
                            }
                        } else if (isIncrement) {
                            return {
                                ...child,
                                order: child.order + 1
                            }
                        }
                        return child;
                    });
                return {
                    ...page,
                    children: [
                        ...newChildrenList,
                        {
                            ...draggableInput,
                            order: newOrder
                        }
                    ]
                };
            }
        }
        return page;
    })
    let resultObject = {};
    newPagesArray.forEach((page: any) => {
        resultObject = {
            ...resultObject,
            [page.id]: page
        }
    })
    return {
        [settings.id]: settings,
        ...resultObject
    }
}

const reducers = {
    addForm(state: ReduxFormsObject, action: AddFormAction): void {
        state.forms = {...state.forms, [action.payload.id]: action.payload };
    },

    updateForm(state: ReduxFormsObject, action: UpdateFormAction): void {
        const { prevState, formId, form } = action.payload;
        const prevStateWithoutCurrent = Object.keys(prevState)
            .filter((prevStateKey) => prevState[prevStateKey].id !== formId)
            .reduce((acc, prevStateKey) => {
                acc[prevStateKey] = prevState[prevStateKey];
                return acc;
            }, {} as ReduxFormsObject);

        state.forms = {
            ...prevStateWithoutCurrent,
            [formId]: {
                ...prevState[formId],
                ...form,
            },
        };
    },

    deleteForm(state: DeleteForm, action: DeleteFormAction): void {
        state.forms = {
            ...action.payload
        }
    },

    addFormChild(state: ReduxFormsObject, action: AddChildrenAction): void {
        const newState = changeOrderInputsAfterAdding(action)
        state.forms = {...newState};
    },

    updateFormChild(state: ReduxFormsObject, action: UpdateChildAction): void {
        try {
            state.forms = {
                ...state.forms,
                [action.payload.formId]: {
                    ...state.forms[action.payload.formId],
                    children: [
                        ...state.forms[action.payload.formId].children.filter((el: ElementSettings) => (
                            el.id !== action.payload.children.id
                        )),
                        {
                            ...state.forms[action.payload.formId].children[action.payload.children.id],
                            ...action.payload.children
                        }
                    ]
                }
            }
        } catch (e) {
            console.log('Error: ', e)
        }
    },

    changeFormAndOrderFormChild(state: ReduxFormsObject, action: UpdateOrderAndFormAction): void {
        try {
            const newOrderedState = changeOrderInputsInPages(action);
            state.forms = {
                ...newOrderedState
            }
        } catch (e) {
            console.log('Error: ', e)
        }
    },

    deleteFormChild(state: ReduxFormsObject, action: DeleteChildrenAction): void {
        state.forms = {
            ...state.forms,
            [action.payload.formId]: {
                ...state.forms[action.payload.formId],
                children: [
                    ...state.forms[action.payload.formId].children.filter((el: ElementSettings) => (
                        el.id !== action.payload.childrenId
                    )),
                ]
            }
        }
    },

    clearForm(state: ReduxFormsObject, action: ClearFormAction): void {
        state.forms = {};
    },
};

export const slice = createSlice({
    name: 'forms',
    initialState,
    reducers
});

export const {reducer} = slice;