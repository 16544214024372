import type { FC, ReactNode } from "react";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useSelector } from "react-redux";
import { SvgIcon } from "@mui/material";
import { paths } from "src/paths";
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import User03Icon from "@untitled-ui/icons-react/build/esm/User03";

interface LayoutProps {
	children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
	const settings = useSettings();
	const sections = useSections();
	const userData = useSelector((state: any) => state.user.user);

	let roleRoutes: any;
	if (userData.workingProfiles) {
		sections.filter((section) => {
			switch (userData.workingProfiles[0].role) {
				case "Admin":
					roleRoutes = sections;
					break;
				case "Approver":
					roleRoutes = sections.map((group) => {
						group.items = group.items.filter(
							(item) =>
                                item.title === "Submitted Forms" ||
                                item.title === "Notifications" || 
								item.title === "KYC Forms" 
								// item.title === "KYC Builder" 
                        );
                        if (group.items.length > 0) { return group } else {return {items:[]}}
					});
					break;
				case "Agent":
					roleRoutes = sections.map((group) => {
						group.items = group.items.filter(
							(item) =>
								item.title === "Submitted Forms" ||
								item.title === "KYC Forms" ||
								item.title === "Notifications" 
                                
                        );
                        if (group.items.length > 0) { return group } else {return {items:[]}}
					});
					break;

                default:
                    roleRoutes = sections;
					break;
			}
		});
	} else {
		roleRoutes = sections;
    }
	
	let isHasCompany = userData && userData.companies && (userData.companies.length > 0);

	if(userData && userData.companies && userData.companies.length === 0) {
		roleRoutes = roleRoutes.map((group: any) => {
			group.items = group.items.filter(
				(item: any) => item.title !== 'KYC Overview' && item.title !== 'KYC Builder'
				
			);
			if (group.items.length > 0) { return group } else {return {items:[]}}
		})
	}
    
    if (userData.isAdmin) {
        roleRoutes = [ {
            items: [
                {
              title: 'Admin panel',
              path: paths.dashboard.admin,
              icon: (
                <SvgIcon fontSize="small">
                  <HomeSmileIcon />
                </SvgIcon>
              )
            },
                {
              title: 'Prospects',
              path: paths.dashboard.prospects,
              icon: (
                <SvgIcon fontSize="small">
                  <User03Icon />
                </SvgIcon>
              )
            },
  
            ]
          },]
    }
	if (settings.layout === "horizontal") {
		return (
			<HorizontalLayout
				sections={roleRoutes}
				navColor={settings.navColor}
				{...props}
			/>
		);
	}

	return (
		<VerticalLayout
			sections={roleRoutes}
			navColor={settings.navColor}
			{...props}
		/>
	);
});

Layout.propTypes = {
	// children: PropTypes.node,
};
