import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Button, Link, SvgIcon, Typography} from "@mui/material";
import {useDropzone} from "react-dropzone";

interface SignaturePadProps {
    element: any;
    onSave: (file: any) => void;
    isRequiredError: boolean;
    isValidateError?: boolean;
    disabled?: boolean;
}

const FileField: React.FC<SignaturePadProps> = ({ element, onSave, isRequiredError, isValidateError = false, disabled = false }) => {
    const [files, setFiles] = useState<any[]>([]);
    // const [hasValue, setHasValue] = useState<string>('');
    useEffect(() => {
        if(element.value) {
            const value = element.value.indexOf('http://yesyou.dev-3.com/api/submitted-form/file/') >= 0 ?
                '' : element.value;
            setFiles([{name: value}])
        }
    }, [])
    const onDrop = useCallback((acceptedFiles: any) => {
        if(isMobile()) return;
        if (disabled) return;
        setFiles([
            ...files,
            ...acceptedFiles
        ]);
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader()
            reader.onabort = () => {
                alert('file reading was aborted')
            }
            reader.onerror = () => {
                alert('file reading has failed')
            }
            reader.onload = () => {
                // const binaryStr = reader.result
                onSave(acceptedFiles[0])
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop, maxFiles: 1})
    function isMobile() {
        var check = false;
        (function(a){
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
                check = true;
        })(navigator.userAgent||navigator.vendor);
        return check;
    };
    return (
        <Box>
            <Box
                key={element.id}
                sx={{
                    width: '100%',
                    paddingTop: '9px',
                    paddingBottom: '8px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    borderRadius: '8px',
                    border: '1px solid rgba(108, 115, 127, 0.50)',
                }}
            >
                {
                    disabled ? (
                        <Box
                            sx={{
                                position: 'relative',
                                paddingTop: '14px'
                            }}
                        >
                            <Typography variant='caption'
                                sx={{
                                    position: 'absolute',
                                    color: '#6C737F',
                                    fontSize: '11px',
                                    fontFamily: 'Inter, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '12px',
                                    letterSpacing: '0.15px',
                                    left: '0',
                                    top: '0',
                                    transform: 'translate(-4px, 0) scale(0.85)',
                                }}
                            >
                                {element.label}
                            </Typography>
                            <Link href={element.value} target="_blank">File Link</Link>
                        </Box>
                    ) : (
                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '5px',
                            background: '#FFF',
                            paddingTop: '20px',
                            paddingBottom: '11px',
                            marginTop: '3px'
                        }}
                        {...getRootProps()}
                    >
                        <svg
                            width="38"
                            height="52"
                            viewBox="0 0 38 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="file-arrow-up-light">
                                <path
                                    id="Shape"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M25.6104 1.88367L36.1495 12.5545C37.337 13.7568 38 15.38 38 17.0833V44.8875C38 48.4294 35.1648 51.3 31.6667 51.3H6.33398C2.8358 51.3 0.000643229 48.4294 0.000643229 44.8875L0 6.4125C0 2.8706 2.83516 0 6.33333 0H21.1276C22.8099 0 24.413 0.675517 25.6104 1.88367ZM23.3661 4.14585C23.0243 3.79988 22.6097 3.57143 22.1667 3.41453V14.428C22.1667 15.3097 22.8792 16.0311 23.75 16.0311H34.6256C34.4771 15.5802 34.2495 15.1594 33.9051 14.8167L23.3661 4.14585ZM34.8334 44.8875C34.8334 46.6549 33.4123 48.0938 31.6667 48.0938H6.33337C4.58775 48.0938 3.16671 46.6549 3.16671 44.8875V6.4125C3.16671 4.64505 4.58775 3.20625 6.33337 3.20625H19V14.4281C19 17.0833 21.1276 19.2375 23.75 19.2375H34.8334V44.8875ZM9.96514 30.9301L17.8818 22.9145C18.4954 22.2833 19.5047 22.2833 20.1193 22.9137L28.0359 30.9293C28.6544 31.5545 28.6544 32.5695 28.0359 33.1957C27.727 33.5089 27.3224 33.6654 26.9167 33.6654C26.511 33.6654 26.1052 33.5051 25.7975 33.1935L20.5834 27.9142V40.0779C20.5834 40.9641 19.8752 41.6811 19 41.6811C18.1249 41.6811 17.4167 40.9641 17.4167 40.0779V27.9142L12.2036 33.1965C11.5861 33.8228 10.5836 33.8218 9.96514 33.1965C9.34665 32.5713 9.34665 31.5563 9.96514 30.9301Z"
                                    fill="#111927"
                                    fillOpacity="0.55"
                                />
                            </g>
                        </svg>

                        <Typography
                            sx={{
                                textAlign: 'center',
                                marginTop: '12px',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '11px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '12px',
                                color: '#6C737F',
                                letterSpacing: '0.15px'
                            }}
                        >
                            Upload {`${element.label.toLowerCase() !== 'idcard input' ? element.label : 'ID Document'}${element.required ? '*' : ''}`}
                        </Typography>
                        {
                            isMobile() && <input
                                style={{
                                    margin: '10px auto',
                                    width: '115px',
                                    color: 'transparent'
                                }}
                                type="file"
                                accept="image/png, image/jpeg"
                                required={element.required}
                                title={element.label}
                                onChange={(e) => {
                                    setFiles([
                                        ...[{name: e.target.value.replace('C:\\fakepath\\', '')}]
                                    ]);
                                    for (const file of e.target.files ? e.target.files : []) {
                                        onSave(file)
                                    }

                                }}
                                disabled={disabled}
                            />
                        }
                        <Typography
                            sx={{
                                textAlign: 'center',
                                marginTop: '12px',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '11px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '12px',
                                color: '#6C737F',
                                letterSpacing: '0.15px'
                            }}
                        >
                            {files.length > 0 ? files[0].name : ''}
                        </Typography>
                            {/*{hasValue.length > 0 &&*/}
                            {/*    <Typography*/}
                            {/*        sx={{*/}
                            {/*            textAlign: 'center',*/}
                            {/*            marginTop: '12px',*/}
                            {/*            fontFamily: 'Inter, sans-serif',*/}
                            {/*            fontSize: '11px',*/}
                            {/*            fontStyle: 'normal',*/}
                            {/*            fontWeight: '500',*/}
                            {/*            lineHeight: '12px',*/}
                            {/*            color: '#6C737F',*/}
                            {/*            letterSpacing: '0.15px'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {*/}
                            {/*            hasValue.indexOf('http://yesyou.dev-3.com/api/submitted-form/file/') >= 0 ?*/}
                            {/*                'File is Uploaded' :*/}
                            {/*                hasValue*/}
                            {/*        }*/}
                            {/*    </Typography>*/}
                            {/*}*/}
                        <input
                            {...getInputProps()}
                            style={{display: 'none'}}
                            type="file"
                            required={element.required}
                            title={element.label}
                            onChange={element.onChange}
                            disabled={disabled || isMobile()}
                        />
                    </Box>
                    )
                }
            </Box>
            {
                isRequiredError || isValidateError ? (<Typography
                    sx={{
                        color: '#F04438',
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        lineHeight: '1.66',
                        fontFamily: "Inter, sans-serif",
                        marginTop: '3px'
                    }}
                    variant="body2"
                >
                    {isValidateError ? "This field is not valid" : "This field is required"}
                </Typography>) : null
            }
        </Box>
    );
};

export { FileField };
