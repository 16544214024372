import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

export function useSocket() {
  const userData = useSelector((state: any) => state.user.user);
  const id = userData.id;


  const URL = `${process.env.REACT_APP_SOCKET_URL}`;

  const socket = io((URL || ''), {
      query: {
          token: id
      }, 
      autoConnect: false,
  });

  return socket;
}