import React, { useEffect, useState } from 'react';
import {Autocomplete, Box, InputAdornment, SvgIcon, TextField, Typography} from "@mui/material";
import {countries} from "src/utils/countries";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface PhoneInputWithCountryProps {
    element: any;
    handleChangeValue: (element: any, value: any, pattern: string) => void;
    isRequiredError: boolean;
    isValidateError: boolean;
    onBlurInput: any;
    disabled?: boolean;
    builderDisabled?: boolean;
    color?: string;
}

const PhoneInputWithCountry: React.FC<PhoneInputWithCountryProps> = ({element, handleChangeValue, isRequiredError, isValidateError, onBlurInput, disabled = false, builderDisabled = false, color = '#42B68A' }) => {
    const [selectedValue, setSelectedValue] = useState({...countries[0]});
    const [phone, setPhone] = useState('');
    const [phoneFocus, setPhoneFocus] = useState(false);
    const handleChange = (event: any, newValue: any) => {
        setSelectedValue(newValue);
    };

    useEffect(() => {
        const event: any = {}
        const regex = /\(([^)]+)\)(\d+)/;
        const match = element.value.replaceAll(' ', '').match(regex);
        if (match) {
            const countryCode = match[1].replace('+', '');
            const phoneNumber = match[2];
            setPhone(phoneNumber);
            handleChange(event, countries.filter((country) => (country.phone === countryCode))[0])
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!selectedValue) return;
        const phoneAssign = `(+${selectedValue.phone})${phone}`;
        handleChangeValue(element.id, phoneAssign, '')
        // eslint-disable-next-line
    }, [selectedValue])

    const handleChangePhone = (e: any) => {
        if(e.target.value.length > 11) return;
        let val = e.target.value.split('').filter((el: string) => !isNaN(+el)).join('');
        val = val.replace(/ /gm, '');
        let num = `${val.substring(0, 2)} ${val.substring(2, 5)} ${val.substring(5, val.length)}`;
        num = num.trim();

        const phoneAssign = `(+${selectedValue ? selectedValue.phone : ''})${num}`;
        setPhone(num);
        handleChangeValue(element.id, phoneAssign, '')
    }

    const onBlurAction = (e: any) => {
        setPhoneFocus(false);
        if (!selectedValue) return;
        const phoneAssign = `(+${selectedValue.phone})${phone}`;
        onBlurInput({
            target: {
                dataset: {
                    inputId: e.target.dataset.inputId
                },
                value: phoneAssign
            }
        })
    }

    const [searchValue, setSearchValue] = useState<string>('');

    const focusHandler = (event: any) => {
        setPhoneFocus(true);
        setSearchValue('');
    };

    const handleSearch = (e: any) => {
        setSearchValue(e.target.value);
    }

    if(disabled && !builderDisabled) {
        return (
            <Box
                sx={{
                    width: '100%',
                    padding: '20px 8px 8px',
                    borderRadius: '8px',
                    border: '1px solid rgba(108, 115, 127, 0.50)',
                    position: 'relative'
                }}
            >
                <Typography variant='caption'
                    sx={{
                        position: 'absolute',
                        color: '#6C737F',
                        fontSize: '11px',
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.15px',
                        left: '0',
                        top: '0',
                        transform: 'translate(4px, 9px) scale(0.85)',
                    }}
                >
                    {element.label}
                </Typography>
                <Typography variant='body1'>{element.value}</Typography>
            </Box>
        )
    }

    return (
        <Box
            key={element.id}
        >
            <Box
                sx={{
                    display: 'flex',
                    marginTop: disabled ? '0' : '8px',
                    width: '100%',
                    padding: '0',
                    borderRadius: '8px',
                    border: '1px solid rgba(108, 115, 127, 0.50)',
                    position: 'relative',
                    boxShadow: isRequiredError ? '#F04438 0 0 0 2px' : phoneFocus ? `${color} 0 0 0 2px` : 'none'
                }}
            >
                <Autocomplete
                    key={element.id+"tess"}
                    sx={{
                        opacity: 0,
                        width:  !selectedValue || selectedValue.phone === '' ? '50px' :
                            `${selectedValue.hasOwnProperty('phone') ? (selectedValue.phone.length * 6) + 52 : 50}px`,
                        border: 'none',
                        "& .MuiAutocomplete-inputRoot": {
                            border: 'none'
                        },
                        '& .Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        '& .MuiAutocomplete-endAdornment': {
                            display: 'none'
                        },
                        "& .MuiInputBase-root": {
                            border: 'none',
                            paddingRight: '0',
                        },
                        '& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.MuiAutocomplete-inputRoot': {
                            paddingRight: '0',
                        },
                    }}
                    options={countries}
                    isOptionEqualToValue={(option, value) => {
                        if(value && value.hasOwnProperty('name')) {
                            return (option.name === value.name)
                        } else {
                            return true;
                        }
                    }}
                    onFocus={focusHandler}
                    onBlur={onBlurAction}
                    value={selectedValue}
                    onChange={(e, value: any) => {
                        handleChange(e, value);
                    }}
                    autoHighlight
                    filterOptions={(options: any, {inputValue}) => {
                        if (inputValue === '') {
                            return options;
                        } else {
                            let newOptions: any = [];
                            options.forEach((option: any) => {
                                if (
                                    option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                                    option.phone.includes(inputValue)
                                ) {
                                    newOptions.push(option)
                                }
                                if (option.name === '' && option.phone === '') {
                                    newOptions.push(option)
                                }
                            })
                            return newOptions;
                        }

                    }}
                    getOptionLabel={(option) => option.phone ? option.phone : countries[0].phone}
                    renderOption={(props, option) => {
                        let isSelected: boolean = false;
                        if (selectedValue && option.name === selectedValue.name) {
                            isSelected = true;
                        }
                        return (
                            <>
                                {
                                    option.name === '' ? (
                                        <Box
                                            key={`"search-field"+${option.emoji}`}
                                            sx={{
                                                padding: '0 4px'
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon style={{fontSize: '14px'}}>
                                                                <SearchMdIcon />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                placeholder="Search country"
                                                value={searchValue}
                                                onChange={handleSearch}
                                                size='small'
                                                sx={{
                                                    height: '38px',
                                                    '& .MuiInputAdornment-positionStart': {
                                                        margin: '0 8px 0 0 !important',
                                                        height: '1em',
                                                    },
                                                    '& input': {
                                                        paddingTop: '4px',
                                                        height: '30px',
                                                    }
                                                }}

                                            />
                                        </Box>
                                    ) : (
                                        <Box
                                            {...props}
                                            key={`element${option.emoji}+${option.name}+${option.phone}+${option.phone}`}
                                            component="li"
                                            sx={{
                                                width: '100px',
                                                color: isSelected ? '#6b6bf3' : '#000000',
                                                fontSize: '16px',
                                                fontFamily: 'Inter, sans-serif',
                                                padding: '10px 4px !important',
                                                background: isSelected ? 'rgba(17, 25, 39, 0.08)' : '#FFFFFF',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: '26px',
                                                    lineHeight: '20px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {option.emoji}
                                                <div
                                                    style={{
                                                        marginLeft: option.emoji.length > 0 ? '15px' : 0,
                                                        fontSize: '14px',
                                                        lineHeight: '18px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <div>{option.name}</div>
                                                    <div
                                                        style={{
                                                            marginLeft: option.name.length > 0 ? '5px' : 0,
                                                            display: 'inline-block'
                                                        }}
                                                    >
                                                        (+{option.phone})
                                                    </div>
                                                </div>
                                                {
                                                    isSelected && (
                                                        <SvgIcon
                                                            style={{
                                                                color: '#6b6bf3',
                                                                marginLeft: 'auto',
                                                                marginRight: '0',
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            <CheckCircleIcon/>
                                                        </SvgIcon>
                                                    )
                                                }
                                            </div>
                                        </Box>
                                    )
                                }

                            </>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'none'
                            }}
                            value={searchValue}
                            onChange={handleSearch}
                        />
                    )}
                />
                <TextField
                    type='text'
                    value={`(+${selectedValue && selectedValue.phone ? selectedValue.phone : ' '})`}
                    disabled={disabled}
                    sx={{
                        width: '80px',
                        pointerEvents: 'none',
                        position: 'absolute',
                        border: 'none',
                        "& .MuiInputBase-root": {
                            border: 'none',
                            paddingRight: '0',
                            padding: '25px 11px 8px',
                        },
                        "&.MuiFormControl-root.MuiTextField-root": {
                            width:  !selectedValue || selectedValue.phone === '' ? '57px' :
                                `${selectedValue.hasOwnProperty('phone') ? (selectedValue.phone.length * 6) + 59 : 57}px`,
                        },
                        "& .MuiInputBase-input.MuiFilledInput-input": {
                            fontSize: '12px',
                            padding: '0 6px',
                            borderRadius: '5px',
                            border: '0.5px solid rgba(0, 0, 0, 0.26)',
                            background: 'rgba(217, 217, 217, 0.00)',
                        },
                        '& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.MuiAutocomplete-inputRoot': {
                            paddingRight: '0',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: '#6C737F'
                        },
                        '& input.Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                    }}
                />
                <TextField
                    disabled={disabled}
                    onFocus={focusHandler}
                    label={element.label}
                    variant="filled"
                    type="phone"
                    value={disabled ? '000 0000' : phone}
                    onBlur={onBlurAction}
                    onChange={handleChangePhone}
                    inputProps={{
                        'data-input-id': element.id
                    }}
                    required={element.required}
                    sx={{
                        width: '100%',
                        position: 'static',
                        '& .MuiInputBase-root': {
                            border: 'none',
                            boxShadow: 'none'
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: '#6C737F'
                        },
                        '& input.Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        '& .MuiFormLabel-root': {
                            color: phoneFocus ? color : '#6C737F',
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                            transform: 'translate(66px, 24px) scale(1)'
                        },
                        '& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-filled': {
                            transform: 'translate(12px, 9px) scale(0.85)'
                        },
                        '& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink.MuiInputLabel-filled': {
                            transform: 'translate(12px, 9px) scale(0.85)'
                        },
                        '& .Mui-focused': {
                            color: `${color} !important`,
                        },

                    }}
                />
            </Box>
            {
                isRequiredError && (<Typography
                    sx={{
                        color: '#F04438',
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        lineHeight: '1.66',
                        fontFamily: "Inter, sans-serif",
                        marginTop: '3px'
                    }}
                    variant="body2"
                >
                    This field is required
                </Typography>)
            }
        </Box>
    )
};

export { PhoneInputWithCountry };
