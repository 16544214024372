import type { FC } from 'react';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import { format } from 'date-fns';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import Mail04Icon from '@untitled-ui/icons-react/build/esm/Mail04';
import MessageChatSquareIcon from '@untitled-ui/icons-react/build/esm/MessageChatSquare';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Tooltip,
  Button,
  Typography,
  Divider
} from '@mui/material';
import { Notification } from 'src/types/notification';
import { useMounted } from 'src/hooks/use-mounted';
import { notificationsApi } from 'src/api/notifications';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/notifications';

interface NotificationsPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  unread: number | null;
  notifications: Notification[];
  open?: boolean;
}

function timeAgo(timestamp: string) {
  const now: Date = new Date();
  const date: Date = new Date(timestamp);
  const elapsed: number = now.getTime() - date.getTime();

  const minutes: number = Math.floor(elapsed / (1000 * 60));
  const hours: number = Math.floor(elapsed / (1000 * 60 * 60));
  const days: number = Math.floor(elapsed / (1000 * 60 * 60 * 24));

  if (minutes < 60) {
    return `${minutes}m ago`;
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else {
    return `${days}d ago`;
  }
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
  const {
    anchorEl,
    onClose,
    notifications,
    unread,
    open,
    ...other
  } = props;
  
  const isEmpty = notifications.length === 0;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 600, borderRadius: "20px" } }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          pt: "29px",
          pb: "13px",
        }}
      >
        <Typography
          color="inherit"
          variant="h6"
          sx={{
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "22px",
          }}
        >
          Notifications ({unread})
        </Typography>
      </Stack>
      <Divider/>
      { 
        isEmpty
          ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle2">
                There are no notifications
              </Typography>
            </Box>
          )
          : (
              <List disablePadding>
                {notifications.map((notification) => (
                  <ListItem
                    key={notification.id}
                    sx={{
                      alignItems: 'flex-start',
                      paddingRight: '12px',
                      transition: 'ease  background-color 1s',
                      backgroundColor: notification.isRead ? "transparent" : "rgba(17, 25, 39, 0.04)",
                      '& .MuiListItemSecondaryAction-root': {
                        top: '24%'
                      }
                    }}
                  >
                    <Box 
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1, 
                        maxWidth: "100%",
                      }}
                    >
                      <ListItemAvatar 
                        sx={{
                          marginRight: "-8px",
                        }}
                      >
                        <Avatar src={notification.photo || undefined}>
                          <SvgIcon>
                            <User01Icon />
                          </SvgIcon>
                        </Avatar>
                      </ListItemAvatar>
                      <Box sx={{width: "auto"}}>
                        <Typography
                          color="text.primary"
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            maxWidth: "459px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: ["-webkit-box", "box"],
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            lineClamp: 2,
                            boxOrient: "vertical",
                          }}
                          variant="body2"
                        >
                          <span style={{fontWeight: "600",}}>
                            {`${notification.name}: `}
                          </span>
                          {notification.title} 
                        </Typography>
                      </Box>
                      <Box 
                        sx={{
                          minWidth: "57px", 
                          display: "flex", 
                          justifyContent: "end", 
                          flexGrow: 1,
                          alignSelf: 'start' 
                        }}
                      >
                        <Typography
                          color="text.secondary"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "20px",
                            
                          }}
                        >
                          {timeAgo(notification.date)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
          )
      }
      <Divider/>
      <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            px: 3,
            pt: "20px",
            pb: 2,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "24px",
            py: "6px",
            px: "16px",
            color: "#111927",
           }}
           onClick={onClose}
          >Close</Button>
          <Button
            component={RouterLink}
            href={paths.dashboard.notifications}
            onClick={onClose}
            variant='contained'
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "24px",
              py: "6px",
              px: "16px",
              marginLeft: "8px !important",
                backgroundColor: '#42B68A',
                "&:hover": {
                    backgroundColor: "#266A4B",
                },
            }}
          >View all</Button>
        </Stack>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  unread: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
