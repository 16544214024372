import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { GuestGuard } from 'src/guards/guest-guard';
// import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import { Layout as AuthLayoutModern } from 'src/layouts/auth/modern-layout';
import { Issuer } from 'src/utils/auth';

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtRegisterSubmit = lazy(() => import('src/pages/auth/jwt/submitEmail'));
const JwtForgotPassword = lazy(() => import('src/pages/auth/jwt/forgotPassword'));
const JwtResetPassword = lazy(() => import('src/pages/auth/jwt/resetPassword'));
const JwtRegisterCompleted = lazy(() => import('src/pages/auth/jwt/thankYou'));

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
        <IssuerGuard issuer={Issuer.JWT}>
          <GuestGuard>
            <AuthLayoutModern>
              <Outlet />
            </AuthLayoutModern>
          </GuestGuard>
        </IssuerGuard>
    ),
    children: [
      {
        path: 'login',
        element: <JwtLoginPage />
      },
      {
        path: 'register',
        element: <JwtRegisterPage />
      },
      {
        path: 'confirmation',
        element: <JwtRegisterSubmit />
      },
      {
        path: 'forgot-password',
        element: <JwtForgotPassword />
      },
      {
        path: 'reset-password',
        element: <JwtResetPassword />
      },
      {
        path: 'register-completed',
        element: <JwtRegisterCompleted />
      }
    ]
  }
];
