import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {
    Button,
    CardContent,
    CardHeader,
    FormHelperText,
    Link,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {RouterLink} from 'src/components/router-link';
import {Seo} from 'src/components/seo';
import type {AuthContextType} from 'src/contexts/auth/jwt-context';
import {useAuth} from 'src/hooks/use-auth';
import {useMounted} from 'src/hooks/use-mounted';
import {usePageView} from 'src/hooks/use-page-view';
import {paths} from 'src/paths';
import './style.css';
// import { AuthIssuer } from 'src/sections/auth/auth-issuer';
import type {Page as PageType} from 'src/types/page';
import {Logo} from "src/components/logo";

interface Values {
    email: string;
    password: string;
    submit: null;
}
const initialValues: Values = {
    email: '',
    password: '',
    submit: null
};

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    password: Yup
        .string()
        .max(255)
        .required('Password is required')
});

const Page: PageType = () => {
    const isMounted = useMounted();
    const {signIn} = useAuth<AuthContextType>();
    let navigate = useNavigate();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                await signIn(values.email, values.password);

                if (isMounted()) {
                    navigate("/dashboard");
                }
            } catch (err) {
                console.error(err);

                if (isMounted()) {
                    helpers.setStatus({success: false});
                    helpers.setErrors({submit: err.message});
                    helpers.setSubmitting(false);
                }
            }
        }
    });

    usePageView();

    return (
        <>
            <Seo title="Sign in"/>
            <div>
                <div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '21px'
                    }}>
                        <img src='/assets/img/YesYouLogo.png' width='100' alt='Yes You' />
                        {/*<Logo/>*/}
                        {/*<span style={{*/}
                        {/*    fontFamily: 'Inter-Bold',*/}
                        {/*    fontSize: '18px',*/}
                        {/*    fontWeight: 700,*/}
                        {/*    lineHeight:  '21.6px',*/}
                        {/*    marginLeft: '4px'*/}
                        {/*}}>YesYou.</span>*/}
                    </div>
                    <CardHeader
                        subheader={(
                            <Typography
                                color="text.secondary"
                                variant="body2"
                                sx={{mt: '8px'}}
                            >
                                Don&apos;t have an account?
                                &nbsp;
                                <Link
                                    component={RouterLink}
                                    href={paths.auth.register}
                                    underline="hover"
                                    variant="subtitle2"
                                    sx={{
                                        fontWeight: 700,
                                        fontFamily: 'Inter-Bold',
                                        color: '#42B68A'
                                    }}
                                >
                                    Sign Up
                                </Link>
                            </Typography>
                        )}
                        sx={{
                            fontWeight: 700,
                            color: '#111927',
                            p: '0',
                            fontFamily: 'Inter-Bold',
                        }}
                        title={<Typography  sx={{
                            fontFamily: 'Inter-Bold',
                        }} variant="h4">Sign in</Typography>}
                    />
                    <CardContent sx={{
                        pt: '21px',
                        pb: 0,
                        pl: 0,
                        pr: 0,
                        fontFamily: 'Inter',

                    }}>
                        <form
                            noValidate
                            onSubmit={formik.handleSubmit}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'

                            }}
                        >
                            <Stack
                                sx={{
                                    width: '100%',
                                }}
                                spacing={'24px'}
                            >
                                <TextField
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email && formik.errors.email}
                                    label="Email address"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="email"
                                    value={formik.values.email}
                                    size='small'
                                    sx={{
                                        '& .MuiFilledInput-root.Mui-focused': {
                                            borderColor: '#42B68A',
                                            boxShadow: '#42B68A 0 0 0 2px'
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            borderRadius: '8px',
                                            padding:'28px 12px 8px',
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            mt: "1px",
                                        }
                                    }}
                                />
                                <TextField
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    fullWidth
                                    helperText={formik.touched.password && formik.errors.password}
                                    label="Password"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="password"
                                    value={formik.values.password}
                                    size='small'
                                    sx={{
                                        '& .MuiFilledInput-root.Mui-focused': {
                                            borderColor: '#42B68A',
                                            boxShadow: '#42B68A 0 0 0 2px'
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            borderRadius: '8px',
                                            padding:'28px 12px 8px'
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            mt: "1px"
                                        }
                                    }}
                                />
                            </Stack>

                            <Button
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                sx={{
                                    maxWidth: '472px',
                                    height: '55px',
                                    borderRadius: '15px',
                                    mt: '24px',
                                    backgroundColor: '#42B68A',
                                    "&:hover": {
                                        backgroundColor: "#266A4B",
                                    },
                                }}
                                type="submit"
                                variant="contained"
                            >
                                Sign in
                            </Button>
                            <NavLink
                                to={paths.auth.forgotPassword}
                                // target="_blank"
                                style={{
                                    textDecoration: 'none',
                                    display: "inline-block",
                                    marginTop: '24px',
                                    fontFamily: 'Inter-SemiBold',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#111927',
                                }}
                            >
                                Forgot password?
                            </NavLink>

                            {formik.errors.submit && (
                                <FormHelperText
                                    error
                                    sx={{
                                        maxWidth: '472px',
                                        height: '50px',
                                        mt: '24px',
                                        position: 'absolute',
                                        bottom: '-60px',
                                        width: '100%',
                                        backgroundColor: "#FEE4E2",
                                        padding: '8px 16px',
                                        borderRadius: '8px',
                                        color: '#7A271A !important',
                                        display: "flex",
                                        alignItems: 'center',
                                        fontSize: '14px'
                                    }}
                                >
                                    <span style={{
                                        display: "inline-flex",
                                        alignItems: 'center',
                                        marginRight: '12px'
                                    }}>
                                        <svg width="22"
                                             height="23"
                                             viewBox="0 0 22 23"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.0833 14.25H11.9167V16.0833H10.0833V14.25ZM10.0833 6.91665H11.9167V12.4166H10.0833V6.91665ZM10.9908 2.33331C5.93084 2.33331 1.83334 6.43998 1.83334 11.5C1.83334 16.56 5.93084 20.6666 10.9908 20.6666C16.06 20.6666 20.1667 16.56 20.1667 11.5C20.1667 6.43998 16.06 2.33331 10.9908 2.33331ZM11 18.8333C6.94834 18.8333 3.66668 15.5516 3.66668 11.5C3.66668 7.44831 6.94834 4.16665 11 4.16665C15.0517 4.16665 18.3333 7.44831 18.3333 11.5C18.3333 15.5516 15.0517 18.8333 11 18.8333Z"
                                                fill="#F04438"/>
                                        </svg>
                                    </span>
                                    {formik.errors.submit as string}
                                </FormHelperText>
                            )}
                        </form>
                    </CardContent>
                </div>
                {/*<Stack*/}
                {/*  spacing={3}*/}
                {/*  sx={{ mt: 3 }}*/}
                {/*>*/}

                {/*<AuthIssuer issuer={issuer} />*/}
                {/*</Stack>*/}
            </div>
        </>
    );
};

export default Page;
