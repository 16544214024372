import type { FC } from 'react';
import { Box } from '@mui/material';
import { Logo } from './logo';
interface SplashScreenProps {
    isCustomerForm?: boolean;
}
export const SplashScreen: FC<SplashScreenProps> = ({isCustomerForm = false}) => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: isCustomerForm ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.08)',
      display: isCustomerForm ? 'flex' : 'none',
      flexDirection: 'column',
      height: '100vw',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: 'inline-flex',
        height: 48,
        width: 48
      }}
    >
      <Logo />
    </Box>
  </Box>
);
