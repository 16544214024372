import type { ReactNode } from 'react';
import { useMemo } from 'react';
import {  SvgIcon } from '@mui/material';
import AlignLeft02Icon from 'src/icons/untitled-ui/duocolor/align-left-02';
import CheckDone01Icon from 'src/icons/untitled-ui/duocolor/check-done-01';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import Mail04Icon from 'src/icons/untitled-ui/duocolor/mail-04';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import LayoutAlt02Icon from 'src/icons/untitled-ui/duocolor/layout-alt-02';

import { paths } from 'src/paths';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {

  return useMemo(
    () => {
      return [

        {
          items: [
              {
            title: 'Dashboard',
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },

          ]
        },
        {
          subheader: 'insightS',
          items: [
            {
              title: 'Submitted Forms',
              path: paths.dashboard.submittedForms.index,
              icon: (
                <SvgIcon fontSize="small">
                  <Users03Icon />
                </SvgIcon>
              )
            },
            {
              title: 'Notifications',
              path: paths.dashboard.notifications,
              icon: (
                  <SvgIcon fontSize="small">
                    <Mail04Icon />
                  </SvgIcon>
              )
            },
            // {
            //   title: 'Calendar',
            //   path: paths.dashboard.calendar,
            //   icon: (
            //       <SvgIcon fontSize="small">
            //         <CalendarIcon />
            //       </SvgIcon>
            //   )
            // },
          ]
        },
        {
          subheader: 'Company KYC',
          items: [
            {
              title: 'KYC Overview',
              path: paths.dashboard.kycOverview,
              icon: (
                  <SvgIcon fontSize="small">
                    <LayoutAlt02Icon />
                  </SvgIcon>
              ),
            },
            {
              title: 'KYC Builder',
              path: paths.dashboard.kycBuilder,
              icon: (
                  <SvgIcon fontSize="small">
                    <AlignLeft02Icon />
                  </SvgIcon>
              )
            },
            {
              title: 'KYC Forms',
              path: paths.dashboard.kycForms,
              icon: (
                  <SvgIcon fontSize="small">
                    <CheckDone01Icon />
                  </SvgIcon>
              )
            },
          ]
        },
      ];
    },
    []
  );
};
