import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import "src/assets/kyc-builder.css";
import {useState, useEffect, useRef, createRef} from "react";
import { v4 } from "uuid";
import { Box, Button, SvgIcon } from "@mui/material";
import KYCTopNav from "src/pages/kyc-builder/kyc-header";
import {
	addNewFormElement,
	getImageByType,
} from "src/utils/kyc-form-bilder";
import FormBuilder from "src/pages/kyc-builder/kyc-form-builder";
import {
	FormSettings,
	MenuFields,
} from "src/types/kyc-form-builder";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import KYCInputSettings from "src/pages/kyc-builder/kyc-input-settings";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/forms";
import { useSelector } from "react-redux";
import FormTemplate from "./form-template";
import { kycFormsApi } from "src/api/kyc-forms";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { paths } from "src/paths";
import {slice } from 'src/slices/forms';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import toast from "react-hot-toast";

const rightMenu: MenuFields[] = [
	{
		title: "Information",
		children: [
			{
				title: "Headline",
				type: "head",
			},
			{
				title: "Sub Headline",
				type: "subhead",
			},
			{
				title: "Paragraph",
				type: "paragraph",
			},
		],
	},
	{
		title: "User Inputs",
		children: [
			{
				title: "Text Input",
				type: "text",
			},
			{
				title: "Number Input",
				type: "number",
			},
			{
				title: "Date Input",
				type: "date",
			},
			{
				title: "ID Number",
				type: "id",
			},
			{
				title: "ID Card",
				type: "idCard",
			},
			{
				title: "Checkbox",
				type: "checkbox",
			},
			{
				title: "Link Checkbox",
				type: "linkedCheckbox",
			},
			{
				title: "Mobile Number",
				type: "phone",
			},
			{
				title: "Email Address",
				type: "email",
			},
			{
				title: "Country",
				type: "country",
			},
			{
				title: "Signature",
				type: "sign",
			},
			{
				title: "File Upload",
				type: "file",
			},
		],
	},
];

const elementsGridBuilder = (
	pageNumber: number | string,
	formSettings: FormSettings[],
	dispatchForms: any,
    pageId: string,
    formId?: string,
) => {
	const notAllowed: any[] = [];
	for (const key in formSettings) {
		if (formSettings.hasOwnProperty(key)) {
			if (!formSettings[key].hasOwnProperty('formName')) {
				for (const formSettingKey in formSettings[key]) {
					const page: any = formSettings[key];
					if (page.hasOwnProperty(formSettingKey)) {
						let phoneCounts: number = 0;
						page.children.forEach((input: any) => {
							if(
								input.type === 'country' ||
								input.type === 'idCard' ||
								input.type === 'id'
							) {
								notAllowed.push(input.type);
							}
							if(input.type === 'phone') {
								phoneCounts++;
							}
						})
						if(phoneCounts === 2) {
							notAllowed.push('phone');
						}
					}
				}
			}
		}
	}

	return (
        <>
			{rightMenu.map((section, index) => {
				return (
					<div
						className="inputs-section"
						key={section.title}
					>
						<h2 className="elementsTitle">{section.title}</h2>
						<Droppable
							droppableId={'inputs'}
							isDropDisabled={true}
						>
							{(provided: any) => {
								return(
									<div
										key={`${section.title}-${index}`}
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{display: 'flex', gridColumnGap: '15px', flexWrap: 'wrap'}}
									>
										{section.children.map((item: any, index: number) => {
											const isAllowed: boolean = !notAllowed.includes(item.type)
											return (
												<Draggable
													key={item.title}
													draggableId={item.type}
													index={index}
												>
													{(provided, snapshot) => {
														return (
															<Box key={`${item.title}-${item.type}`}>
																<div
																	className={`item ${isAllowed ? '' : 'disabled'}`}
																	data-name={item.type}
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={{
																		...provided.draggableProps.style,
																		transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'translate(0px, 0px)',
																	}}
																	onClick={(e) => {
																		if(isAllowed) {
																			addNewFormElement(
																				e,
																				pageNumber,
																				formSettings,
																				dispatchForms,
																				pageId,
																				formId,
																			);
																		}
																	}}
																>
																	<div className="image">
																		{getImageByType(item.type)}
																	</div>
																	<p className={"text"}>{item.title}</p>
																</div>
																{snapshot.isDragging &&
																	<div
																		className={`item ${isAllowed ? '' : 'disabled'}`}
																		data-name={item.type}
																		style={{
																				transform: 'none !important'
																			}}>
																			<div className="image">
																				{getImageByType(item.type)}
																			</div>
																			<p className={"text"}>{item.title}</p>
																	</div>}
															</Box>
														)
													}}
												</Draggable>
											);
										})}

										{provided.placeholder}
									</div>
								)}}
						</Droppable>
					</div>
				);
			})}
		</>
	);
}

const Page: PageType = () => {
    usePageView();
	const [openSettingMenu, setOpenSettingMenu] = useState({
		isOpen: false,
		formId: "",
		elementId: "",
	});
	const [openInputTypesMenu, setOpenInputTypesMenu] = useState({
		isOpen: false,
		formId: "",
	});
    const [loadedSettings, setLoadedSettings] = useState({})
    const [loadedFormSettings, setLoadedFormSettings] = useState({})
    const [loadedPages, setLoadedPages] = useState({})
    const [formStatus, setFormStatus] = useState('')

	const { formId } = useParams();
	const dispatchForms: any = useDispatch();
	const companyData = useSelector((state: any) => state.company.company);
	const myData = useSelector((state: any) => state.form.forms);
	const userData = useSelector((state: any) => state.user.user);
    const navigate = useNavigate();
    useEffect(() => {
		if(userData.workingProfiles && (userData.workingProfiles[0].role !== 'Owner') && userData.workingProfiles[0].company && formId){
			return;
		}
		if(userData.companies === null) {
			navigate(paths.dashboard.kycForms)
			return;
		}
        if (
        	userData.workingProfiles &&
			(userData.workingProfiles[0].role === 'Agent' ||  userData.workingProfiles[0].role === 'Approver') &&
			!formId
		) {
			navigate(paths.dashboard.kycForms)
        } 
        
        if (!(companyData && companyData.id)) {
            navigate(paths.dashboard.kycForms)
        }
		// eslint-disable-next-line
	}, [])
	useEffect(() => {
		// eslint-disable-next-line
	}, [openSettingMenu]);

	useEffect(() => {
		async function fetchFormDetail() {
            if (formId) {
				dispatchForms(slice.actions.clearForm({}));
				const formDetails = await kycFormsApi.getFormDetails({
					id: formId,
				});
				const settings = {
                    ...formDetails.form.content,
					pages: [],
                };
				const formSettings = {
                    ...formDetails.form,
					content: {},
                };
                setLoadedFormSettings(formSettings)
                setFormStatus(formDetails.form.status)
				const pages = formDetails.form.content.pages;
                setLoadedPages(pages)
                setLoadedSettings(settings)
                dispatchForms(thunks.addForm(settings));
				pages.forEach((page) => {
					dispatchForms(thunks.addForm(page));
				});
            } else {
                dispatchForms(slice.actions.clearForm({}));

                async function onCreateNewFormClick() {
                    const template = FormTemplate();
                    if ((companyData && companyData.id && companyData.status === 'Active')) {
                        const response = await kycFormsApi.createForm({ id: companyData.id, content: template.content })
                        navigate(paths.dashboard.kycBuilder+`/${response.response.id}`)
                    } else if (companyData && companyData.id && companyData.status !== 'Active') { 
                        toast.error('Your company is not active, please contact the Administrator of the site')
                        return navigate(paths.dashboard.kycForms)
                    }
                    else {
                        toast.error('Set up a company first, please.')
                       return navigate(paths.dashboard.kycForms)
                    }
                }
                await onCreateNewFormClick()
			}
		}
		fetchFormDetail();

		// eslint-disable-next-line
	}, [formId]);

	const onDragEnd = (result: any) => {
		if (!result.destination) return; // Cancel drag

		const dragEndPos: any = result.destination.index;
		const dragStartFormId: string = result.source.droppableId;
		const dragEndFormId: string = result.destination.droppableId;
		const draggableElementId: string = result.draggableId;

		if(dragStartFormId === 'inputs') {
			addNewFormElement(
				{currentTarget: {dataset: {name: draggableElementId}}},
				NaN,
				myData,
				dispatchForms,
				dragEndFormId,
				formId,
				dragEndPos
			)
			return;
		}

		//TODO: Sometimes in this code we have a bug when items change order is not correctly
		// maybe this bug appear when settings bar is opened and state on page works two times
		// need to add validation to open settings modal event if element dragging modal can't open

		for (const myDataKey in myData) {
			if(myData.hasOwnProperty(myDataKey) && !myData[myDataKey].hasOwnProperty('formName')) {
				myData[myDataKey].children.forEach((el: any) => {
					if(el.id === draggableElementId) {
						if (el.hasOwnProperty('isDefault')) {
							if (dragStartFormId !== dragEndFormId) {
								alert('Default fields be only on First page');
								return;
							}
						}
						dispatchForms(thunks.changeFormAndOrderFormChild({
							formIdStart: dragStartFormId,
							formIdEnd: dragEndFormId,
							childrenId: el.id,
							oldOrder: el.order,
							newOrder: dragEndPos,
							formDbId: formId,
							formData: myData,
						}))
					}
				})
			}
		}
	};

	const heightRef: any = useRef();

	return (
		<>
			<Seo title="KYC-Builder" />
            <KYCTopNav
                formId={formId}
                loadedPages={loadedPages}
                loadedSettings={loadedSettings}
                formStatus={formStatus}
                loadedFormSettings={loadedFormSettings}
            />
			<Box className={"content"}>
				<DragDropContext
					onDragEnd={onDragEnd}
				>
					<Box
						className="forms-container"
						sx={{
							width: openInputTypesMenu.isOpen
								? "calc(100% - 408px)"
								: "100%",
						}}
					>
                        <Box
							ref={heightRef}
                            className="wrapper333"
							style={{
								width: "100%",
								overflow: "auto",
								display: "flex",
								alignItems: "flex-start",
								height: "calc(100vh - 128px)",
								gridGap: "32px",
								paddingLeft: "24px",
								paddingRight: "24px",
								paddingTop: "24px",
							}}
							onClick={() => {
								if(openSettingMenu.isOpen || openInputTypesMenu.isOpen) {
									setOpenSettingMenu({
										isOpen: false,
										formId: "",
										elementId: "",
									});
									setOpenInputTypesMenu({
										isOpen: false,
										formId: "",
									});
								}
							}}
						>
							{
								Object.keys(myData).map((el: any, index: number) => {
									if (myData[el].hasOwnProperty("formName")) {
										return (
											<Box
												key={'settings-page'}
												sx={{display: 'none'}}
											></Box>
										);
									}
									return (
										<FormBuilder
											key={index}
											myData={myData[el]}
											state={myData}
											setOpenSettingMenu={setOpenSettingMenu}
											setOpenInputTypesMenu={
												setOpenInputTypesMenu
											}
											isSettingsOpened={openSettingMenu.isOpen}
											formDbId={formId}
											formStatus={formStatus}
										/>
									);
								})
							}
							{formStatus === 'Saved' && <Button
								sx={{
									width: "509px",
									minWidth: "509px",
									cursor: "pointer",
									padding: "16px",
									display: "flex",
									alignItems: "center",
									gridGap: "8px",
									borderRadius: "20px",
									background: "#FFF",
									boxShadow:
										"0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
									color: "#6C737F",
									justifyContent: "flex-start",
									"&:hover": {
										background: "rgba(99, 102, 241, 0.04)",
									},
								}}
								onClick={() => {
									const id = v4();
									dispatchForms(
										thunks.addForm({
											id: id,
											order: Object.keys(myData).length,
											page: `Page ${
												Object.keys(myData).length
											}`,
											children: [],
											new: true,
											prevState: myData,
											formDbId: formId,

										})
										);
								}}
							>
								<SvgIcon>
									<AddSharpIcon />
								</SvgIcon>
								Add Page
							</Button>}
						</Box>
						{openSettingMenu.isOpen || openInputTypesMenu.isOpen ? (
							<Box
								onClick={() => {
									setOpenSettingMenu({
										isOpen: false,
										formId: "",
										elementId: "",
									});
									setOpenInputTypesMenu({
										isOpen: false,
										formId: "",
									});
								}}
								sx={{
									// position: "fixed",
									// top: 0,
									// left: 0,
									width: "100%",
									height: heightRef.current.scrollHeight ? heightRef.current.scrollHeight + 'px' : '100%', // "100%"
								}}
							/>
						) : null}
					</Box>
					{(openInputTypesMenu.isOpen || openSettingMenu.isOpen) && (
						<Box className={"right"}>
							{openInputTypesMenu.isOpen && (
								<>
									{elementsGridBuilder(
										1,
										myData,
										dispatchForms,
										openInputTypesMenu.formId,
										formId
									)}
								</>
							)}
							{openSettingMenu.isOpen && (
								<KYCInputSettings
									openSettingMenu={openSettingMenu}
									setOpenSettingMenu={setOpenSettingMenu}
									isOpen={openSettingMenu.isOpen}
									formDbId={formId}
									formStatus={formStatus}
								/>
							)}
						</Box>
					)}
				</DragDropContext>
			</Box>
		</>
	);
};

export default Page;
