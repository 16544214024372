import type { Theme } from '@mui/material';
import { Box, Button, Container, Typography, useMediaQuery, SvgIcon } from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { paths } from 'src/paths';
import type { Page as PageType } from 'src/types/page';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Page: PageType = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  usePageView();

  return (
    <>
      <Seo title="Error: Not Found" />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container sx={{maxWidth: {md: "838px"}}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3
            }}
          >
            <Box
              alt="Not found"
              component="img"
              src="/assets/img/404.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 133
              }}
            />
          </Box>
          <Typography
            align="center"
            variant={mdUp ? 'h1' : 'h4'}
            sx={{
              fontSize: {xs: "1.5rem"},
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ 
              mt: 1, 
              fontSize: {sm: "16px", xs: "14px"},
            }}
          >
            You either tried some shady route, do not have the user role to access this page or you came here by mistake. Whichever it is, try using the navigation.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3
            }}
          >
            <Button
              component={RouterLink}
              href={paths.index}
              sx={{
                // backgroundColor: "#569DAA",
                backgroundColor: '#42B68A',
                color: "white",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                paddingLeft: "16px",
                paddingRight: "12px",
                paddingTop: "6px",
                paddingBottom: "6px",
                "&:hover": {
                    backgroundColor: "#266A4B",
                },
              }}
            >
              Take Me Home
              <SvgIcon sx={{width: "20px", height: "20px", marginLeft: "8px"}}>
								<KeyboardArrowRightIcon/>
							</SvgIcon>
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;
