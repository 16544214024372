import type {User} from 'src/types/user';
import axios from 'axios';
import toast from 'react-hot-toast';
import api from "../index";

import {
    SetPhoneRequest,
    SetPhoneResponse,
    ChangePasswordRequest,
    ChangePasswordResponse,
    SetUserDataRequest,
    SetUserDataResponse,
    PhotoUploadRequest,
    PhotoUploadResponse,
    GetNotificationsSettingsRequest,
    GetNotificationsSettingsResponse,
    SetNotificationsSettingsRequest,
    SetNotificationsSettingsResponse,
} from 'src/types/api/user'


class UserApi {

    async setPhone(request: SetPhoneRequest): SetPhoneResponse {
        const {phone} = request;
        const response = await api.call('user/phone', "post", {phone}, {});
        return {message: response.message};
    }

    async changePassword(request: ChangePasswordRequest): ChangePasswordResponse {

        const response = await api.call('user/change-password', "post", request, {});
        return {message: response.message};
    }

    async updateUserData(request: SetUserDataRequest): SetUserDataResponse {
        const {firstName, lastName, email, phone, photo, isFirstLogin} = request;
        const response = await api.call('user/profile', "post", {
            firstName,
            lastName,
            email,
            phone,
            photo,
            isFirstLogin
        }, {'Content-Type': 'multipart/form-data'});
        return {
            message: response.message,
            data: response.data
        };
    }

    async uploadPhoto(photo: PhotoUploadRequest): PhotoUploadResponse {
            const formData = new FormData();
            formData.append('photo', photo || '');
            const response = await api.call('user/photo', "post", formData, {'Content-Type': 'multipart/form-data'});
            return response
    };

    async getNotificationSettings(request: GetNotificationsSettingsRequest): GetNotificationsSettingsResponse {
        const {accessToken} = request;
        const response = await api.call(`user/notifications-settings`, "get")
        return {settings: response.settings}
    }
    
    async setNotificationSettings(request: SetNotificationsSettingsRequest): SetPhoneResponse {
        const { messages, email, productOfferings, featureUpdates} = request;
        const response = await api.call('user/notifications-settings', "post", {messages, email, productOfferings, featureUpdates}, {});
        return {message: response.message};
    }

}

export const userApi = new UserApi();
