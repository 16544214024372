import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {Announcement} from 'src/types/announcement';

interface AnnouncementState {
    announcement: Announcement;
}

type GetAnnouncementAction = PayloadAction<Announcement>;
type UpdateAnnouncementAction = PayloadAction<Announcement>;

const initialState: AnnouncementState = {
    announcement: {
        // id: '',
        // subject: '',
        // header: '',
        // description: '',
        // buttonText: '',
        // buttonLink: '',
    },
};

const reducers = {
    getAnnouncement(state: AnnouncementState, action: GetAnnouncementAction): void {
        state.announcement = action.payload;
    },

    updateAnnouncement(state: AnnouncementState, action: UpdateAnnouncementAction): void {
        state.announcement = action.payload;
    },
    clearState(state: any, action: any): void {
        state.announcement = {}
    },
};

export const slice = createSlice({
    name: 'announcement',
    initialState,
    reducers
});

export const {reducer} = slice;
