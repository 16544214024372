import api from "../index";
import {
    GetNotificationsRequest,
    GetNotificatinsResponse,
    GetNotificationRequest,
    GetNotificatinResponse,
    GetUnreadNotificationsCountResponse,
    SetAsReadNotificationRequest,
    SetAsReadNotificationResponse,
    DeleteNotificationsRequest,
    DeleteNotificationsResponse,
} from 'src/types/api/notifications'


class NotificationsApi {

    async getNotifications(request: GetNotificationsRequest): GetNotificatinsResponse {
        const { page, query } = request;
        let url = `notifications?page=${page}`;
        if(query){
            url += `&search=${query}`
        }
        const response = await api.call(url, "get")
        return {data: response}
    }

    async getUnreadNotificationsCount():GetUnreadNotificationsCountResponse{
        const response = await api.call(`notifications/unread`, "get")
        return {data: response}
    }

    async setAsReadNotification({ids}: SetAsReadNotificationRequest): SetAsReadNotificationResponse{
        const response = await api.call(`notifications`, "put", {
            ids
        }, {})
        return {data: response}
    }

    // async getNotification(request: GetNotificationRequest):GetNotificatinResponse {
    //     const { id } = request;
    //     const response = await api.call(`notifications/${id}`, "get")
    //     return {notification: response.notification}
    // }

    // async deleteNotifications(ids: DeleteNotificationsRequest): DeleteNotificationsResponse {
    //     let queryString = ids.ids.map(id => `ids[]=${id}`).join('&')
    //     const response = await api.call(`notifications?${queryString}`, "delete")
    //     return {data: response}
    // }
}

export const notificationsApi = new NotificationsApi();