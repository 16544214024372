import api from "../index";
import {
	GetKycFormListRequest,
	GetKycFormListResponse,
	GetKycFormDetailsRequest,
	GetKycFormDetailsResponse,
	PostDuplicateFormRequest,
	PostDuplicateFormResponse,
	DeleteFormRequest,
	DeleteFormResponse,
	ChangeFormStatusRequest,
	ChangeFormStatusResponse,
	UpdateFormRequest,
	UpdateFormResponse,
	CreateFormRequest,
	CreateFormFormResponse,
	ApproveFormRequest,
	GetKycFormCustomerDetailsRequest,
	GetKycFormCustomerDetailsResponse,
	GetCategoriesRequest,
	GetCategoriesResponse, SetCategoriesRequest, SetCategoriesResponse
} from "src/types/api/kyc-forms";

class KycFormsApi {
	async getCategories(
		request: GetCategoriesRequest
	): GetCategoriesResponse {
		const { companyId } = request;
		const response = await api.call(`company/${companyId}/form-categories`, "get");
		return { categories: [...response.categories] };
	}

	async setCategories(request: SetCategoriesRequest): SetCategoriesResponse {
		//! company id
		const { companyId, categories } = request;
		const response = await api.call(
			`company/${companyId}/form-categories`,
			"post",
			{ categories },
			{}
		);
		return { data: response.data };
	}

	async createForm(request: CreateFormRequest): CreateFormFormResponse {
		//! company id
		const { id, content } = request;
		const response = await api.call(
			`form/${id}`,
			"post",
			{ content },
			{}
		);
		return { response };
	}

	async getFormsList(request: GetKycFormListRequest): GetKycFormListResponse {
        let { companyId, page, rowsPerPage, sortBy, sortDir, filters } = request;
        page += 1 
		let url = `form/${companyId}/list?per_page=${rowsPerPage}&page=${page}`;

		if(filters){
			if(filters.query){
                url += `&search=${filters.query}`
			}
		}

		if(sortBy){
			url += `&sort_by=${sortBy}&order=${sortDir}`
		} else {
			url += `&sort_by=createdAt&order=desc`
		}

		const response = await api.call(url, "get");
		return { forms: response };
	}

	async getFormDetails(
		request: GetKycFormDetailsRequest
	): GetKycFormDetailsResponse {
		const { id } = request;
		const response = await api.call(`form/${id}/details`, "get");
		return { form: response.form };
	}

	async getFormDetailsForCustomer(
		request: GetKycFormDetailsRequest
	): GetKycFormDetailsResponse {
		const { id } = request;
		const response = await api.call(`form/${id}/details-for-customer`, "get");
		return { form: response.form };
	}

	async getCustomerDetails(
		request: GetKycFormCustomerDetailsRequest
	): GetKycFormCustomerDetailsResponse {
		const { id, hash } = request;
		if (id) {
			const response = await api.call(`submitted-form/${id}/full`, "get");
			return { values: response.customer.values };
		} else {
			const response = await api.call(`submitted-form/${hash}/fields`, "get");
			return { values: response.values };
		}
	}

	async updateForm(request: UpdateFormRequest): UpdateFormResponse {
		//! form id
		const { id, content } = request;
		const response = await api.call(`form/${id}`, "put", { content }, {});
		return { message: response.message };
	}

	async updateFormStatus(
		request: ChangeFormStatusRequest
	): ChangeFormStatusResponse {
		//! form id
		const { id, status } = request;
		const response = await api.call(`form/${id}/status`, "put", { status }, {});
		return { message: response };
	}
	async approveForm(
		request: ApproveFormRequest
	): ChangeFormStatusResponse {
		//! form id
		const { id, approve } = request;
		const response = await api.call(`form/${id}/approve`, "put", { approve }, {});
		return { message: response };
	}

	async deleteForm(request: DeleteFormRequest): DeleteFormResponse {
		//! form id
		const { id } = request;
		const response = await api.call(`form/${id}`, "delete");
		return { message: response };
	}

	async postDuplicateForm(
		request: PostDuplicateFormRequest
	): PostDuplicateFormResponse {
		//! form id
		const { id } = request;
		const response = await api.call(`form/${id}/duplicate`, "post", {}, {});
		return { message: response.message };
	}
}
export const kycFormsApi = new KycFormsApi();