import {slice} from 'src/slices/announcement';
import type {AppThunk} from 'src/store';
import {companyApi} from "src/api/company";

const getAnnouncement = (announcement: {}): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.getAnnouncement(announcement));
};

const updateAnnouncement = (announcement: { id: string, subject?: string, header?: string, description?: string, buttonText?: string, buttonLink?: any }): AppThunk => async (dispatch): Promise<void> => {
    const response = await companyApi.updateAnnouncement(announcement);
    if (response){
        dispatch(slice.actions.updateAnnouncement(announcement));
    }
};

export const thunks = {
    getAnnouncement,
    updateAnnouncement,
};
