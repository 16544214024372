import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {User} from 'src/types/user';

interface UserState {
    user: {};
}

type GetUserAction = PayloadAction<User>;
type UpdateUserAction = PayloadAction<User>;

const initialState: UserState = {
    user: {},
};

const reducers = {
    getUser(state: User, action: GetUserAction): void {
        state.user = action.payload;
    },

    updateUser(state: User, action: UpdateUserAction): void {
        state.user = action.payload;
    },

    // updateUserCompany(state: User, action: UpdateUserAction): void {
    //     // state.user = action.payload;
    //     state.user.user.companies = state.user.user.companies.map((item:Company) => {
    //         if (item.name === action.payload.name) {
    //             return { ...item, ...action.payload };
    //         } else {
    //             return item;
    //         }
    //     });
    // },

    updatePhone(state: User, action: UpdateUserAction): void {
        state.user = {...state.user, ...action.payload}
    },

    clearState(state: any, action: any): void {
        state.user = {}
    },

};

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers
});

export const {reducer} = slice;
