import 'src/assets/kyc-builder.css';
import React, {useState, useRef, useCallback, useEffect} from "react";
import {
    Box,
    Button,
    ButtonGroup, CardContent,
    ClickAwayListener, FormControl, FormHelperText,
    Grow, InputLabel, MenuItem, MenuList, Modal,
    Paper,
    Popper, Select,
    Stack,
    SvgIcon, TextField,
    Typography,
    Dialog,
    DialogActions, Menu,
} from "@mui/material";
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {useDispatch} from "src/store";
import {thunks} from "src/thunks/forms";
import PreviewModal from "src/pages/kyc-builder/kyc-builder-preview";
import { kycFormsApi } from 'src/api/kyc-forms';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/paths';
import toast from 'react-hot-toast';

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, {Dayjs} from "dayjs";

enum Category {
    EDIT="EDIT",
    ADD="ADD"
}

interface Values {
    formName: string;
    description: string;
    category: string;
    autoApproveApplications: string;
    renewalReminder: string;
    formExpiry: any;
    submit: null;
}

const currentDate = new Date();
const futureDate = new Date(currentDate);
futureDate.setFullYear(currentDate.getFullYear() + 1000);

const initialValues: Values = {
    formName: 'New Form (1)',
    description: '',
    category: 'Insurance',
    autoApproveApplications: 'No',
    renewalReminder: 'None',
    formExpiry: futureDate.toISOString().split('T')[0],
    submit: null
};

const validationSchema = Yup.object({
    formName: Yup
        .string()
        .max(255),
    description: Yup
        .string().max(255),
    category: Yup
        .string()
        .max(255),
    autoApproveApplications: Yup
        .string()
        .max(255),
    renewalReminder: Yup
        .string()
        .max(255),
    formExpiry: Yup
        .date(),
});


const KYCTopNav = (props: any) => {
	const navigate = useNavigate();

    const {formId, loadedSettings, formStatus, loadedFormSettings} = props
    const dispatchForms: any = useDispatch();
    const reduxFormSettings = useSelector((state:any) => state.form.forms);
    const myData = useSelector((state: any) => state.form.forms);
    const reduxLoadFormSettings: any = Object.values(myData).filter((page: any) => page.page === "Settings");
    const formName = reduxLoadFormSettings[0]?.formName
	const userData = useSelector((state: any) => state.user.user);
	const companyData = useSelector((state: any) => state.company.company);

    const [formSettings, setFormSettings] = useState({
        id: '',
        order: NaN,
        page: '',
        children: [],
        formName: loadedSettings.formName,
        category: '',
        briefDescription: '',
        autoApprove: 'No',
        renewalReminder: 'None',
        formExpiry: futureDate.toISOString().split('T')[0],
    });

    useEffect(() => {
		setFormSettings({
			...formSettings,
			formName: loadedSettings.formName,
		});

        // eslint-disable-next-line
	}, [props.loadedSettings]);

    const setDataFromRedux = () => {
        
        for (const key in reduxFormSettings) {
            if (!reduxFormSettings.hasOwnProperty(key)) return;
            if (reduxFormSettings[key].hasOwnProperty('formName')) {
                formik.setFieldValue("formName", reduxFormSettings[key].formName);
                formik.setFieldValue("description", reduxFormSettings[key].briefDescription);
                formik.setFieldValue("category", reduxFormSettings[key].category);
                formik.setFieldValue("autoApproveApplications", reduxFormSettings[key].autoApprove);
                formik.setFieldValue("renewalReminder", reduxFormSettings[key].renewalReminder);
                formik.setFieldValue("formExpiry", reduxFormSettings[key].formExpiry);
                setFormSettings(reduxFormSettings[key]);
            }
        }
    }

    const [isOpenPreview, setIsOpenPreview] = useState(false);

    // if options changed need to fix actionMap
    let options: string[] = [];
	switch (formStatus) {
        case "Active":
            if (userData.workingProfiles && userData.workingProfiles[0]?.role === "Agent") {
                options = ["Copy Share Link"];
            } else {
                options = ["Copy Share Link", "Deactivate"];
            }
			break;
        case "Pending Review":
                if (userData.workingProfiles &&
                    userData.workingProfiles[0]?.role === "Approver" &&
                    loadedFormSettings.createdBy !== userData.id
                ) {
                    options = ["Mark as Approved", "Decline"];
                } else { 
                    options = ["Mark as Approved", "Decline", "Delete"];
                }
			break;
		case "Saved":
			options = ["Submit for Approval", "Delete"];
			break;
		case "Deactivated":
			options = ["Activate"];
			break;

		default:
			break;
	}
    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(100);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleMenuItemClick = useCallback(
        (index: number): void => {
            setSelectedIndex(index);
            handleCloseMenu();
            setOpen(false);
        },
        // eslint-disable-next-line
        []
    );

    const handleToggle = useCallback(
        (): void => {
            setOpen((prevOpen) => !prevOpen);
        },
        // eslint-disable-next-line
        []
    );

    const handleClose = useCallback(
        (event: Event): void => {
            if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                return;
            }

            setOpen(false);
        },
        // eslint-disable-next-line
        []
    );

    const handleOpenPreviewModal = () => {
        setIsOpenPreview(true);
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                setFormSettings({
                    id: formSettings.id,
                    order: formSettings.order,
                    page: formSettings.page,
                    children: formSettings.children,

                    formName: values.formName,
                    category: values.category,
                    briefDescription: values.description,
                    autoApprove: values.autoApproveApplications,
                    renewalReminder: values.renewalReminder,
                    formExpiry: values.formExpiry,
                });
                dispatchForms(thunks.updateForm({
                    formId: formSettings.id,
                    prevState: reduxFormSettings,
                    formDbId: formId,
                    form: {
                        id: formSettings.id,
                        order: formSettings.order,
                        page: formSettings.page,
                        children: formSettings.children,

                        formName: values.formName,
                        category: values.category,
                        briefDescription: values.description,
                        autoApprove: values.autoApproveApplications,
                        renewalReminder: values.renewalReminder,
                        formExpiry: values.formExpiry,
                    }
                }));
                setCategoriesToApi();
                handleCloseModal();
            } catch (err) {
                console.error(err);
            }
        }
    });

    useEffect(() => {
        setDataFromRedux();
        setFormSettings({...formSettings, category: categoryList[0].value})
        // eslint-disable-next-line
    }, [myData])

    useEffect(() => {
        setDataFromRedux();
        // eslint-disable-next-line
    }, [openModal])

    const [categoryList, setCategoryList] = useState([
        {
            name: 'Insurance',
            value: 'Insurance'
        },
        {
            name: 'Finance',
            value: 'Finance'
        }
    ]);

    const getCategoriesFromApi = async () => {
        const categoriesList: any = await kycFormsApi.getCategories({
            companyId: companyData.id
        })
        if(categoriesList.categories && categoriesList.categories.length > 0) {
            setCategoryList([...categoriesList.categories.map((el: any) => ({name: el, value: el}))])
        }
    }
    const setCategoriesToApi = async () => {
        let categoriesArray: any = categoryList.map((category: any) => {
            return category.name;
        })
        const categoriesList: any = await kycFormsApi.setCategories({
            companyId: companyData.id,
            categories: categoriesArray
        })
        if(categoriesList.categories && categoriesList.categories.length > 0) {
            setCategoryList([...categoriesList.categories.map((el: any) => ({name: el, value: el}))])
        }
    }

    useEffect(() => {
        if(userData.workingProfiles && (userData.workingProfiles[0].role !== 'Owner') && userData.workingProfiles[0].company && formId){
            if (userData.workingProfiles[0].role === 'Agent'){
               return 
            } else {
                getCategoriesFromApi()
                return 
            }
            
		}
        if(userData.companies === null) {
            navigate(paths.dashboard.kycForms)
            return;
        }
        getCategoriesFromApi()
    }, [])
    const [categoryName, setCategoryName] = useState('');
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [dialogInfo, setDialogInfo] = useState({
        type: '',
        elValue: ''
    })
    const handleCloseAndSave = () => {
        setCategoryList([...categoryList.filter((el: any) => (el.value !== dialogInfo.elValue)), {
            name: categoryName,
            value: categoryName
        }])
        handleCloseDialog();
    }
    const handleCloseAndDelete = () => {
        if (categoryList.length > 1) {
            setCategoryList([...categoryList.filter((el: any) => (el.value !== dialogInfo.elValue))])
            handleCloseDialog();
        } else {
            alert('There must be at least 1 category');
        }
    }
    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setDialogInfo({
            type: '',
            elValue: ''
        })
    }
    const openDialog = (e: any, type: string, elValue: string) => {
        setIsOpenDialog(true);
        setCategoryName(elValue);
        setDialogInfo({
            type: type,
            elValue: elValue
        })
    }

    const actionMap: {
        [key: number]: {
            [key: string]: () => Promise<void> | void;
        };
    } = {
        0: {
            Active: async () => {
                if (formId) {
                    const currentDomain = window.location.origin;
                    const currentFormLink = `${currentDomain}/form/${formId}?agentId=${userData.id}`
                    handleCopyClick(currentFormLink);
                    try {
                        await navigator.clipboard.writeText(currentFormLink);
                    } catch (e: any) {

                    }
                    toast.success('Link copied to clipboard')
                }
            },
            "Pending Review": async () => {
                if (formId) {
                    const responseApprove = await kycFormsApi.approveForm(
                        { id: formId, approve: true }
                    );
                    if (responseApprove) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
            Saved: async () => {
                if (formId) {
                    const response = await kycFormsApi.updateFormStatus(
                        { id: formId, status: "Pending Review" }
                    );
                    if (response) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
            Deactivated: async () => {
                if (formId) {
                    const response = await kycFormsApi.updateFormStatus(
                        { id: formId, status: "Active" }
                    );
                    if (response) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
        },
        1: {
            Active: async () => {
                if (formId) {
                    const response = await kycFormsApi.updateFormStatus(
                        { id: formId, status: "Deactivated" }
                    );
                    if (response) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
            "Pending Review": async() => {
                if (formId) {
                    const response = await kycFormsApi.updateFormStatus(
                        { id: formId, status: "Saved" }
                    );
                    if (response) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
            Saved: async() => {
                if (formId) {
                    const response = await kycFormsApi.deleteForm({
                        id: formId,
                    });
                    if (response) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
        },
        2: {
            "Pending Review": async() => {
                if (formId) {
                    const response = await kycFormsApi.deleteForm({
                        id: formId,
                    });
                    if (response) {
                        navigate(paths.dashboard.kycForms);
                    }
                }
            },
        },
    };

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleCopyClick = (textToCopy: string) => {
        if (textAreaRef.current) {
            textAreaRef.current.value = textToCopy;
            textAreaRef.current.select();
        }

        try {
            document.execCommand('copy');
        } catch (err) {
            // console.error('Error: ', err);
        }
    };
    
    const onActionClick = async (index: number) => {
        const selectedActions:any = actionMap[index];
        // const selectedActions:any = actionMap[selectedIndex];
        if (selectedActions) {
            const action = selectedActions[formStatus];
            if (action) {
                await action();
            }
        }
    };

    const isDisabled = () => (formStatus !== "Saved");

    const [date, setDate] = useState(futureDate.toISOString().split('T')[0]);

    const customInputRef = useRef<HTMLDivElement | null>(null);

    const [isOpenExpireDate, setIsOpenExpireDate] = useState(false);

    const handleExpireDateChange = (date: Dayjs | null) => {
        if (date) {
            formatDateToYYYYMMDD(date)
        }
        setDate(formatDateToYYYYMMDD(date));
        formik.setFieldValue("formExpiry", formatDateToYYYYMMDD(date));
    };

    function formatDateToYYYYMMDD(date: any) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu: boolean = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Box className="top-header">
            <Box className="title">
                <Typography variant="h1">
                    {formName}
                </Typography>
                <Typography
                    className="status"
                    variant="subtitle1"
                >
                    {formStatus}
                </Typography>
            </Box>
            <Box className="buttons">

                <Box sx={{p: 0}}>
                    <Stack spacing={0}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={0}
                            sx={{gridGap: '10px'}}
                        >
                            <textarea
                                ref={textAreaRef}
                                style={{ position: 'absolute', left: '-9999px', visibility: 'hidden' }}
                            />
                            <Button
                                variant="outlined"
                                onClick={handleOpenModal}
                                sx={{
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    gridGap: '8px',
                                    color: '#42B68A',
                                    borderColor: '#42B68A',
                                    "&:hover": {
                                        borderColor: '#42B68A',
                                    },
                                }}
                            >
                                <SvgIcon>
                                    <EditSharpIcon/>
                                </SvgIcon>
                                Form Settings
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    gridGap: '8px',
                                    color: '#42B68A',
                                    borderColor: '#42B68A',
                                    "&:hover": {
                                        borderColor: '#42B68A',
                                    },
                                }}
                                onClick={handleOpenPreviewModal}
                            >
                                <SvgIcon>
                                    <RemoveRedEyeSharpIcon/>
                                </SvgIcon>
                                Preview
                            </Button>
                            <Box>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{
                                        paddingTop: '6px',
                                        paddingBottom: '6px',
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gridGap: '8px',
                                        // backgroundColor: '#569DAA',
                                        backgroundColor: '#42B68A',
                                        color: '#FFFFFF',
                                        "&:hover": {
                                            backgroundColor: '#266A4B',
                                        },
                                    }}
                                >
                                    Actions
                                    <SvgIcon>
                                        <ChevronDownIcon/>
                                    </SvgIcon>
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={() => {
                                                // handleMenuItemClick(index)
                                                onActionClick(index)
                                            }}
                                            selected={index === selectedIndex}
                                            sx={{
                                                borderRadius: '12px',
                                                color: option === 'Delete' ? '#F04438' : 'inherit',
                                            }}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                {/*<ButtonGroup        */}
                                {/*    ref={anchorRef}*/}
                                {/*    variant="contained"*/}
                                {/*>*/}
                                {/*    <Button*/}
                                {/*        sx={{*/}
                                {/*            paddingTop: '6px',*/}
                                {/*            paddingBottom: '6px',*/}
                                {/*            paddingLeft: '16px',*/}
                                {/*            paddingRight: '4px',*/}
                                {/*            backgroundColor: '#569DAA',*/}
                                {/*            borderRight: 'none !important',*/}
                                {/*            "&:hover": {*/}
                                {/*                backgroundColor: options[selectedIndex] === 'Delete' ? '#F04438' : '#3d737c',*/}
                                {/*            },*/}
                                {/*        }}*/}
                                {/*        onClick={onActionClick}*/}
                                {/*    >*/}
                                {/*        {selectedIndex > options.length ? 'Actions' : options[selectedIndex]}*/}
                                {/*    </Button>*/}
                                {/*    <Button*/}
                                {/*        onClick={handleToggle}*/}
                                {/*        size="small"*/}
                                {/*        sx={{*/}
                                {/*            paddingTop: '6px',*/}
                                {/*            paddingBottom: '6px',*/}
                                {/*            paddingLeft: '4px',*/}
                                {/*            paddingRight: '16px',*/}
                                {/*            backgroundColor: '#569DAA',*/}
                                {/*            "&:hover": {*/}
                                {/*                backgroundColor: '#3d737c',*/}
                                {/*            },*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <SvgIcon>*/}
                                {/*            <ChevronDownIcon/>*/}
                                {/*        </SvgIcon>*/}
                                {/*    </Button>*/}
                                {/*</ButtonGroup>*/}
                                {/*<Popper*/}
                                {/*    anchorEl={anchorRef.current}*/}
                                {/*    open={open}*/}
                                {/*    transition*/}
                                {/*>*/}
                                {/*    {({TransitionProps, placement}) => (*/}
                                {/*        <Grow*/}
                                {/*            {...TransitionProps}*/}
                                {/*            style={{*/}
                                {/*                transformOrigin:*/}
                                {/*                    placement === 'bottom'*/}
                                {/*                        ? 'center top'*/}
                                {/*                        : 'center bottom'*/}
                                {/*            }}*/}
                                {/*        >*/}
                                {/*            <Paper sx={{width: '232px'}}>*/}
                                {/*                <ClickAwayListener onClickAway={handleClose}>*/}
                                {/*                    <MenuList*/}
                                {/*                        sx={{*/}
                                {/*                            paddingTop: '4px',*/}
                                {/*                            paddingLeft: '4px',*/}
                                {/*                            paddingRight: '4px',*/}
                                {/*                            paddingBottom: '4px',*/}
                                {/*                        }}*/}
                                {/*                    >*/}
                                {/*                        {options.map((option, index) => (*/}
                                {/*                            <MenuItem*/}
                                {/*                                key={option}*/}
                                {/*                                onClick={() => handleMenuItemClick(index)}*/}
                                {/*                                selected={index === selectedIndex}*/}
                                {/*                                sx={{*/}
                                {/*                                    borderRadius: '12px',*/}
                                {/*                                    color: option === 'Delete' ? '#F04438' : 'inherit',*/}
                                {/*                                }}*/}
                                {/*                            >*/}
                                {/*                                {option}*/}
                                {/*                            </MenuItem>*/}
                                {/*                        ))}*/}
                                {/*                    </MenuList>*/}
                                {/*                </ClickAwayListener>*/}
                                {/*            </Paper>*/}
                                {/*        </Grow>*/}
                                {/*    )}*/}
                                {/*</Popper>*/}
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        maxWidth: '600px',
                        width: '100%',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '20px',
                        background: '#FFF',
                        boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.08)'
                    }}
                >
                    <Typography
                        style={{
                            paddingTop: '32px',
                            paddingBottom: '19px',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                        }}
                        variant={'h6'}
                    >
                        Form Settings
                    </Typography>
                    <CardContent sx={{
                        paddingTop: '6px',
                        paddingBottom: '0 !important',
                        paddingRight: '22px',
                        paddingLeft: '22px',
                        fontFamily: 'Inter, sans-serif',
                    }}>
                        <form
                            style={{
                                position: 'relative',
                            }}
                            onSubmit={formik.handleSubmit}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gridGap: '24px',
                                    paddingTop: '20px',
                                    paddingBottom: '32px',
                                    paddingRight: '24px',
                                    paddingLeft: '24px',
                                    borderRadius: '20px',
                                    background: '#FFFFFF',
                                    boxShadow: '0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
                                }}
                            >
                                <TextField
                                    disabled={isDisabled()}
                                    error={!!(formik.touched.formName && formik.errors.formName)}
                                    fullWidth
                                    helperText={formik.touched.formName && formik.errors.formName}
                                    label="Form name"
                                    name="formName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.formName}
                                />
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                >
                                        <InputLabel id="category">Category</InputLabel>
                                    <Select
                                         disabled={isDisabled()}
                                            labelId="category"
                                            id="category"
                                            value={formik.values.category}
                                            name="category"
                                            label="Category"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={!!(formik.touched.category && formik.errors.category)}
                                            fullWidth
                                            sx={{
                                                ".editIcon": {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            {
                                                categoryList.map((el: any, index: any) => {
                                                    return (
                                                        <MenuItem
                                                            key={`${el.value}- ${index}`}
                                                            selected={index === 0}
                                                            value={el.value}
                                                            style={{ display: 'flex', justifyContent: 'space-between' }}
                                                          
                                                        >
                                                            <Typography>{el.name}</Typography>
                                                            <SvgIcon className="editIcon">
                                                                <EditSharpIcon
                                                                    onClick={(e)=>{
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        openDialog(e, Category.EDIT, el.value)
                                                                    }}
                                                                />
                                                            </SvgIcon>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                            <MenuItem
                                                value="Add"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                style={{
                                                    paddingTop: '0',
                                                    paddingBottom: '0',
                                                    paddingLeft: '0',
                                                    paddingRight: '0',
                                                    borderRadius: '12px',
                                                    // background: '#569DAA',
                                                    backgroundColor: '#42B68A',
                                                }}
                                            >
                                                <Typography
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        openDialog(e, Category.ADD, "ADD")
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        paddingTop: '6px',
                                                        paddingBottom: '6px',
                                                        paddingLeft: '16px',
                                                        paddingRight: '16px',
                                                        color: '#FFFFFF',
                                                    }}
                                                >
                                                    Add New
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                <Dialog
                                    open={isOpenDialog}
                                    onClose={handleCloseDialog}
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '480px'
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                textTransform: 'capitalize',
                                                fontFamily: 'Inter, sans-serif',
                                                width: '480px',
                                                maxWidth: '480px',
                                                paddingLeft: '24px',
                                                paddingRight: '24px',
                                                paddingTop: '32px',
                                                paddingBottom: '16px',

                                            }}
                                        >
                                            {dialogInfo.type === Category.EDIT ? 'Edit' : 'Add'} Category
                                        </Typography>
                                        <Box
                                            sx={{
                                                paddingTop: '16px',
                                                paddingBottom: '16px',
                                                paddingLeft: '24px',
                                                paddingRight: '24px',
                                            }}
                                        >
                                            <TextField
                                                autoFocus
                                                id="name"
                                                label="Name"
                                                type="text"
                                                fullWidth
                                                variant="filled"
                                                value={categoryName}
                                                onChange={(e) => {
                                                    setCategoryName(e.target.value)
                                                }}
                                            />
                                        </Box>
                                        <DialogActions
                                            sx={{
                                                paddingTop: '16px',
                                                paddingBottom: '16px',
                                                paddingLeft: '24px',
                                                paddingRight: '24px',
                                            }}
                                        >
                                            {
                                                dialogInfo.type === Category.EDIT ? (
                                                    <Button
                                                        onClick={handleCloseAndDelete}
                                                        sx={{
                                                            color: '#772917'
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={handleCloseDialog}
                                                        sx={{
                                                            color: '#111927'
                                                        }}
                                                    >
                                                        Close
                                                    </Button>
                                                )
                                            }
                                            <Button
                                                onClick={handleCloseAndSave}
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#42B68A',
                                                    "&:hover": {
                                                        backgroundColor: "#266A4B",
                                                    },
                                                }}
                                            >
                                                Save changes
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>
                                <TextField
                                     disabled={isDisabled()}
                                    error={!!(formik.touched.description && formik.errors.description)}
                                    fullWidth
                                    helperText={formik.touched.description && formik.errors.description}
                                    label="Brief Description"
                                    name="description"
                                    multiline
                                    placeholder="-"
                                    sx={{
                                        "textarea": {
                                            maxHeight: '46px',
                                            minHeight: '46px',
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                />
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                >
                                    <InputLabel id="autoApproveApplications">Auto-Approve Applications</InputLabel>
                                    <Select
                                         disabled={isDisabled()}
                                        labelId="autoApproveApplications"
                                        id="autoApproveApplications"
                                        value={formik.values.autoApproveApplications}
                                        name="autoApproveApplications"
                                        label="Auto-Approve Applications"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={!!(formik.touched.autoApproveApplications && formik.errors.autoApproveApplications)}
                                        fullWidth
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                >
                                    <InputLabel id="renewalReminder">Renewal Reminder</InputLabel>
                                    <Select
                                         disabled={isDisabled()}
                                        labelId="renewalReminder"
                                        id="renewalReminder"
                                        error={!!(formik.touched.renewalReminder && formik.errors.renewalReminder)}
                                        fullWidth
                                        label="Renewal Reminder"
                                        name="renewalReminder"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.renewalReminder}
                                    >
                                        <MenuItem value="Annually">Annually</MenuItem>
                                        <MenuItem value="2 Years">2 Years</MenuItem>
                                        <MenuItem value="8 Years">8 Years</MenuItem>
                                        <MenuItem value="10 Years">10 Years</MenuItem>
                                        <MenuItem value="None">None</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                >
                                    <DatePicker
                                        disabled={isDisabled()}
                                        onChange={handleExpireDateChange}
                                        value={date}
                                        PopperProps={{ anchorEl: customInputRef.current }}
                                        onClose={() => {
                                            setIsOpenExpireDate(false);
                                        }}
                                        open={isOpenExpireDate}
                                        disablePast
                                        renderInput={({
                                              ref,
                                              inputProps,
                                              disabled,
                                              onChange,
                                              value,
                                              ...other
                                          }) => (
                                            <div ref={ref}
                                                 style={{ width: "100%" }}>
                                                <input
                                                    style={{ display: "none" }}
                                                    value={date || ''}
                                                    onChange={onChange}
                                                    disabled={disabled}
                                                    {...inputProps}
                                                />
                                                <TextField
                                                    label="End date"
                                                    name="sortDate"
                                                    ref={customInputRef}
                                                    onClick={() => {
                                                        setIsOpenExpireDate(!isOpenExpireDate);
                                                    }}
                                                    SelectProps={{ native: true }}
                                                    value={date ? dayjs(date).format("DD/MM/YYYY") : 'None'}
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "100%",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                    {/*<DateField*/}
                                    {/*    disabled={isDisabled()}*/}
                                    {/*    id="formExpiry1"*/}
                                    {/*    error={!!(formik.touched.formExpiry && formik.errors.formExpiry)}*/}
                                    {/*    fullWidth*/}
                                    {/*    label="Form Expiry"*/}
                                    {/*    name="formExpiry"*/}
                                    {/*    onBlur={formik.handleBlur}*/}
                                    {/*    onChange={formik.handleChange}*/}
                                    {/*    value={formik.values.formExpiry}*/}
                                    {/*    sx={{*/}
                                    {/*        width: '100%',*/}
                                    {/*        border: '1px solid rgba(108, 115, 127, 0.50)',*/}
                                    {/*        borderRadius: '8px',*/}
                                    {/*        '& .MuiInputBase-root': {*/}
                                    {/*            border: 'none'*/}
                                    {/*        },*/}
                                    {/*        '& .MuiFormLabel-root': {*/}
                                    {/*            color: '#6C737F',*/}
                                    {/*            fontSize: '14px',*/}
                                    {/*            fontFamily: 'Inter',*/}
                                    {/*            fontStyle: 'normal',*/}
                                    {/*            fontWeight: '500',*/}
                                    {/*            lineHeight: '14px',*/}
                                    {/*            letterSpacing: '0.15px',*/}
                                    {/*        },*/}
                                    {/*        '& .MuiInputLabel-shrink.MuiInputLabel-filled': {*/}
                                    {/*            transform: 'translate(12px, 9px) scale(0.85)'*/}
                                    {/*        }*/}

                                    {/*    }}*/}
                                    {/*    format={"dd/MM/yyyy"}*/}
                                    {/*/>*/}
                                    {/*<Select*/}
                                    {/*     disabled={isDisabled()}*/}
                                    {/*    labelId="formExpiry"*/}
                                    {/*    id="formExpiry"*/}
                                    {/*    error={!!(formik.touched.formExpiry && formik.errors.formExpiry)}*/}
                                    {/*    fullWidth*/}
                                    {/*    label="Form Expiry"*/}
                                    {/*    name="formExpiry"*/}
                                    {/*    onBlur={formik.handleBlur}*/}
                                    {/*    onChange={formik.handleChange}*/}
                                    {/*    value={formik.values.formExpiry}*/}
                                    {/*>*/}
                                    {/*    <MenuItem value="Annually">Annually</MenuItem>*/}
                                    {/*    <MenuItem value="2 Years">2 Years</MenuItem>*/}
                                    {/*    <MenuItem value="8 Years">8 Years</MenuItem>*/}
                                    {/*    <MenuItem value="10 Years">10 Years</MenuItem>*/}
                                    {/*    <MenuItem value="None">None</MenuItem>*/}
                                    {/*</Select>*/}
                                </FormControl>

                                {formik.errors.submit && (
                                    <FormHelperText
                                        error
                                        sx={{
                                            mt: '24px',
                                            position: 'absolute',
                                            bottom: '-60px',
                                            width: '100%',
                                            backgroundColor: "#FEE4E2",
                                            padding: '8px 16px',
                                            borderRadius: '8px',
                                            color: '#7A271A !important',
                                            display: "flex",
                                            alignItems: 'center'
                                        }}
                                    >
                                    <span
                                        style={{
                                            display: "inline-flex",
                                            alignItems: 'center',
                                            marginRight: '12px'
                                        }}
                                    >
                                      <svg width="22"
                                           height="23"
                                           viewBox="0 0 22 23"
                                           fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.0833 14.25H11.9167V16.0833H10.0833V14.25ZM10.0833 6.91665H11.9167V12.4166H10.0833V6.91665ZM10.9908 2.33331C5.93084 2.33331 1.83334 6.43998 1.83334 11.5C1.83334 16.56 5.93084 20.6666 10.9908 20.6666C16.06 20.6666 20.1667 16.56 20.1667 11.5C20.1667 6.43998 16.06 2.33331 10.9908 2.33331ZM11 18.8333C6.94834 18.8333 3.66668 15.5516 3.66668 11.5C3.66668 7.44831 6.94834 4.16665 11 4.16665C15.0517 4.16665 18.3333 7.44831 18.3333 11.5C18.3333 15.5516 15.0517 18.8333 11 18.8333Z"
                                            fill="#F04438"/>
                                      </svg>
                                    </span>
                                        {formik.errors.submit as string}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gridGap: '8px',
                                    marginTop: '19px',
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    paddingBottom: '16px'
                                }}
                            >
                                <Button
                                    size="small"
                                    sx={{
                                        color: '#111927'
                                    }}
                                    onClick={handleCloseModal}
                                    variant="text"
                                >
                                    Close
                                </Button>
                               {  !isDisabled() && <Button
                                    disabled={formik.isSubmitting}
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#42B68A',
                                        "&:hover": {
                                            backgroundColor: "#266A4B",
                                        },
                                    }}
                                >
                                    Save
                                </Button>}
                            </Box>
                        </form>
                    </CardContent>
                </Box>
            </Modal>

            <PreviewModal
                isOpenPreview={isOpenPreview}
                setIsOpenPreview={setIsOpenPreview}
                isDataCollected={false}
            />
        </Box>
    )
}

export default KYCTopNav;
