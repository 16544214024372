import 'src/assets/kyc-builder.css';
import React, {useState, useEffect, FC} from "react";
import {
    Box,
    Button,
    Typography,
    Divider,
    ThemeProvider,
    TextField,
    FormControlLabel,
    Checkbox,
    Link, Modal,
} from "@mui/material";
import {useSelector} from "react-redux";
import {useDispatch} from "src/store";
import {thunks} from "src/thunks/forms"
import {ElementSettings} from "src/types/kyc-form-builder";
import {useParams, useSearchParams} from "react-router-dom";
import {kycFormsApi} from "src/api/kyc-forms";
import { customersApi } from "src/api/customers";
import {Signature} from "src/components/inputs/Signature";
import {FormattedDatePicker} from "src/components/inputs/FormattedDatePicker";
import {CountrySelect} from "src/components/inputs/CountrySelect";
import {PhoneInputWithCountry} from "src/components/inputs/PhoneInputWithCountry";
import {hexToRGB} from "src/utils/kyc-form-bilder";
import {FileField} from "src/components/inputs/FileField";
import {encode} from "base-64";
// import {SplashScreen} from "src/components/splash-screen";

export const generateInput = (element: ElementSettings | any, pageId: any, setOpenSettingMenu: any) => {
    return (
        <div
            key={`${element.type}-${element.label}`}
            onClick={(e)=>{setOpenSettingMenu({isOpen: true, formId: pageId, elementId: element.id})}}
        >
            {
                selectInputByType(element)
            }
        </div>
    )
}

export const selectInputByType = (
    element: ElementSettings,
    disabled: boolean = true,
    onBlurInput: any = () => {},
    handleChangeValue: any = () => {},
    errors: any = {},
    companyColor: any = '#42B68A'
) => {

    const isRequiredError = errors.hasOwnProperty(element.id) ? errors[element.id].required : false;
    const isValidateError = errors.hasOwnProperty(element.id) ? errors[element.id].validate : false;

    const phoneValidation = (e: any, isBlur: boolean) => {
        if(e.target.value.length > 14) return;
        let val = e.target.value.split('').filter((el: string) => !isNaN(+el)).join('');
        val = val.replace(/ /gm, '');
        let num = `${val.substring(0, 2)} ${val.substring(2, 5)} ${val.substring(5, val.length)}`;
        num = num.trim();
        if (isBlur) {
            onBlurInput({
                target: {
                    dataset: {
                        inputId: element.id
                    },
                    value: num
                }
            })
        } else {
            handleChangeValue(element.id, num, '');
        }
    }

    switch(element.type) {
        case 'head':
            return (
                <Box
                    key={element.id}
                >
                    <Typography
                        variant="h6"
                    >
                        {element.value}
                    </Typography>
                </Box>
            );
        case 'subhead':
            return (
                <Box
                    key={element.id}
                >
                    <Typography
                        variant="subtitle2"
                    >
                        {element.value}
                    </Typography>
                </Box>
            );
        case 'paragraph':
            return (
                <Box
                    key={element.id}
                >
                    <ThemeProvider
                        theme={{
                            typography: {
                                body1: {
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    letterSpacing: '0.4px'
                                }
                            }
                        }}
                    >
                        <Typography variant="body1" dangerouslySetInnerHTML={{__html: element.value}}>
                            {/*{element.value}*/}
                        </Typography>
                    </ThemeProvider>
                </Box>
            );
        case 'text':
            return (
                <TextField
                    key={element.id}
                    label={element.label ? element.label : `Text Input`}
                    variant="filled"
                    type="text"
                    value={element.value}
                    onBlur={onBlurInput}
                    onChange={(e) => {
                        handleChangeValue(element.id, e.target.value, '')
                    }}
                    inputProps={{'data-input-id': element.id}}
                    error={isRequiredError}
                    helperText={isRequiredError ? "This field is required" : ""}
                    required={element.required}
                    disabled={disabled}
                    sx={{
                        width: '100%',
                        borderRadius: '8px',
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: '#6C737F'
                        },
                        '& input.Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        '& .MuiInputBase-root': {
                            border: '1px solid rgba(108, 115, 127, 0.50)',
                        },
                        '& .MuiFormLabel-root': {
                            color: '#6C737F',
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                        },
                        '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
                            transform: 'translate(12px, 9px) scale(0.85)'
                        },
                        '& .MuiFilledInput-root.Mui-focused': {
                            borderColor: companyColor,
                            boxShadow: `${companyColor} 0 0 0 2px`
                        },
                        '& .Mui-focused': {
                            color: `${companyColor} !important`,
                        },
                    }}
                />
            );
        case 'number':
            return (
                <TextField
                    key={element.id}
                    label={element.label ? element.label : `Number Input`}
                    variant="filled"
                    type="text"
                    required={element.required}
                    value={element.value}
                    onBlur={onBlurInput}
                    onChange={(e) => {
                        handleChangeValue(element.id, e.target.value.replace(/\D/g, ''), '')
                    }}
                    InputProps={{
                        inputMode: 'numeric',
                    }}
                    inputProps={{'data-input-id': element.id}}
                    error={isRequiredError}
                    helperText={isRequiredError ? "This field is required" : ""}
                    disabled={disabled}
                    sx={{
                        width: '100%',
                        borderRadius: '8px',
                        '& .MuiInputBase-root': {
                            border: '1px solid rgba(108, 115, 127, 0.50)',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: '#6C737F'
                        },
                        '& input.Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        '& .MuiFormLabel-root': {
                            color: '#6C737F',
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                        },
                        '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
                            transform: 'translate(12px, 9px) scale(0.85)'
                        },
                        '& .MuiFilledInput-root.Mui-focused': {
                            borderColor: companyColor,
                            boxShadow: `${companyColor} 0 0 0 2px`
                        },
                        '& .Mui-focused': {
                            color: `${companyColor} !important`,
                        },
                    }}
                />
            );
        case 'date':
            return (
                <FormattedDatePicker
                    key={element.id}
                    element={element}
                    format={element.pattern}
                    onChange={
                        (newValue) => {
                            handleChangeValue(element.id, newValue, element.dateFormat)
                        }
                    }
                    isRequiredError={isRequiredError}
                    disabled={disabled}
                />
            );
        case 'id':
            return (
                <div key={element.id}>
                    <TextField
                        label={element.label ? element.label : `ID Number`}
                        variant="filled"
                        type="text"
                        inputProps={{'data-input-id': element.id}}
                        value={element.value}
                        onBlur={onBlurInput}
                        onChange={(e) => {
                            if(element.numberOfDigits && element.numberOfDigits > 0) {
                                if (e.target.value.length <= element.numberOfDigits) {
                                    handleChangeValue(element.id, e.target.value, '')
                                }
                            } else {
                                handleChangeValue(element.id, e.target.value, '')
                            }
                        }}
                        disabled={disabled}
                        error={isRequiredError || isValidateError}
                        helperText={isRequiredError ? "This field is required" : ""}
                        required={element.required}
                        sx={{
                            width: '100%',
                            borderRadius: '8px',
                            '& .MuiInputBase-root': {
                                border: '1px solid rgba(108, 115, 127, 0.50)',
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                color: '#6C737F'
                            },
                            '& input.Mui-disabled': {
                                WebkitTextFillColor: '#111927'
                            },
                            '& .MuiFormLabel-root': {
                                color: '#6C737F',
                                fontSize: '11px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '12px',
                                letterSpacing: '0.15px',
                            },
                            '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
                                transform: 'translate(12px, 9px) scale(0.85)'
                            },
                            '& .MuiFilledInput-root.Mui-focused': {
                                borderColor: companyColor,
                                boxShadow: `${companyColor} 0 0 0 2px`
                            },
                            '& .Mui-focused': {
                                color: `${companyColor} !important`,
                            },
                        }}
                    />
                    {
                        element.validate ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    marginTop: '-6px',
                                    color: isValidateError ? 'red' : '#6C737F',
                                    fontFamily: 'Inter, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '14px',
                                    fontSize: '10px'
                                }}
                            >
                                Please ensure that your ID number contains {element.numberOfDigits} characters to be valid
                            </Typography>
                        ) : null
                    }
                </div>
            );
        case 'idCard':
            return (
                <FileField
                    key={`${element.id}-main`}
                    element={element}
                    onSave={(data: any)=>{handleChangeValue(element.id, data, '')}}
                    isRequiredError={isRequiredError}
                    isValidateError={isValidateError}
                    disabled={disabled}
                />
            );
        case 'checkbox':
            return (
                <Box
                    key={element.id}
                >
                    <FormControlLabel
                        control={
                        <Checkbox
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={!!element.value}
                            disabled={disabled}
                            onChange={(e) => {
                                handleChangeValue(element.id, e.target.checked, '')
                            }}
                            sx={{
                                color: companyColor,
                                '&.Mui-checked': {
                                    color: companyColor,
                                },
                            }}
                        />}
                        label={`${element.label}${element.required ? '*' : ''}`}
                    />
                    {
                        isRequiredError && (<Typography
                            sx={{
                                color: '#F04438',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                lineHeight: '1.66',
                                fontFamily: "Inter, sans-serif",
                            }}
                            variant="body2"
                        >
                            This field is required
                        </Typography>)
                    }
                </Box>
            );
        case 'linkedCheckbox':
            return (
                <Box
                    key={element.id}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'controlled'
                                }}
                                disabled={disabled}
                                checked={!!element.value}
                                onChange={(e) => {
                                    handleChangeValue(element.id, e.target.checked, '')
                                }}
                                sx={{
                                    color: companyColor,
                                    '&.Mui-checked': {
                                        color: companyColor,
                                    },
                                }}
                            />}
                        label={
                            <Link
                                sx={{
                                    color: '#111927'
                                }}
                                href={element.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`${element.label}${element.required ? '*' : ''}`}
                            </Link>
                        }
                    />
                    {
                        isRequiredError && (<Typography
                            sx={{
                                color: '#F04438',
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                lineHeight: '1.66',
                                fontFamily: "Inter, sans-serif",
                            }}
                            variant="body2"
                        >
                            This field is required
                        </Typography>)
                    }
                </Box>
            );
        case 'phone':
            return (
                <Box
                    key={element.id}
                >
                    {element.countryCode ? (
                        <PhoneInputWithCountry
                            element={element}
                            handleChangeValue={handleChangeValue}
                            isRequiredError={isRequiredError}
                            isValidateError={isValidateError}
                            onBlurInput={onBlurInput}
                            disabled={disabled}
                            color={companyColor}
                        />
                    ) : (
                        <TextField
                            label={element.label ? element.label : `Mobile Number`}
                            variant="filled"
                            type="phone"
                            placeholder={'+380 000000000'}
                            value={element.value}
                            onBlur={(e) => {
                                phoneValidation(e, true);
                            }}
                            onChange={(e) => {
                                phoneValidation(e, false);
                            }}
                            disabled={disabled}
                            inputProps={{'data-input-id': element.id}}
                            error={isRequiredError}
                            helperText={isRequiredError ? "This field is required" : ""}
                            required={element.required}
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                '& .MuiInputBase-root': {
                                    border: '1px solid rgba(108, 115, 127, 0.50)',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    color: '#6C737F'
                                },
                                '& input.Mui-disabled': {
                                    WebkitTextFillColor: '#111927'
                                },
                                '& .MuiFormLabel-root': {
                                    color: '#6C737F',
                                    fontSize: '11px',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '12px',
                                    letterSpacing: '0.15px',
                                },
                                '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
                                    transform: 'translate(12px, 9px) scale(0.85)'
                                },
                                '& .MuiFilledInput-root.Mui-focused': {
                                    borderColor: companyColor,
                                    boxShadow: `${companyColor} 0 0 0 2px`
                                },
                                '& .Mui-focused': {
                                    color: `${companyColor} !important`,
                                },
                            }}
                        />
                    )}
                </Box>
            );
        case 'email':
            let helperText = "";
            if(isValidateError) helperText = "Value in this field not valid";
            if(isRequiredError) helperText = "This field is required";
            return (
                <TextField
                    key={element.id}
                    label={element.label ? element.label : `Email Address`}
                    variant="filled"
                    type="email"
                    value={element.value}
                    onBlur={onBlurInput}
                    onChange={(e) => {
                        handleChangeValue(element.id, e.target.value, '')
                    }}
                    inputProps={{'data-input-id': element.id}}
                    disabled={disabled}
                    error={isRequiredError || isValidateError}
                    helperText={helperText}
                    required={element.required}
                    sx={{
                        width: '100%',
                        borderRadius: '8px',
                        '& .MuiInputBase-root': {
                            border: '1px solid rgba(108, 115, 127, 0.50)',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: '#6C737F'
                        },
                        '& input.Mui-disabled': {
                            WebkitTextFillColor: '#111927'
                        },
                        '& .MuiFormLabel-root': {
                            color: '#6C737F',
                            fontSize: '11px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                        },
                        '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
                            transform: 'translate(12px, 9px) scale(0.85)'
                        },
                        '& .MuiFilledInput-root.Mui-focused': {
                            borderColor: companyColor,
                            boxShadow: `${companyColor} 0 0 0 2px`
                        },
                        '& .Mui-focused': {
                            color: `${companyColor} !important`,
                        },
                    }}
                />
            );
        case 'country':
            return (
                <CountrySelect
                    key={`${element.id}-CountrySelect`}
                    element={element}
                    handleChangeValue={handleChangeValue}
                    isRequiredError={isRequiredError}
                    isValidateError={isValidateError}
                    onBlurInput={onBlurInput}
                    disabled={disabled}
                    color={companyColor}
                />
            )
        case 'sign':
            return (
                <Signature
                    key={`${element.id}-sign`}
                    element={element}
                    width={303}
                    height={103}
                    onSave={(data: string)=>{handleChangeValue(element.id, data, '')}}
                    isRequiredError={isRequiredError}
                    disabled={disabled}
                    color={companyColor}
                />
            );
        case 'file':
            return (
                <FileField
                    key={`${element.id}-main`}
                    element={element}
                    onSave={(data: any)=>{handleChangeValue(element.id, data, '')}}
                    isRequiredError={isRequiredError}
                    disabled={disabled}
                />
            );
    }
}

function getCookie(name: string) {
    const value: string = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}
function deleteCookie(name: string) {
    document.cookie = `${name}=;`;
}

const FilledModal: FC = () => {
    const { formId } = useParams();
    const [searchParams] = useSearchParams();
    const dispatchForms: any = useDispatch();

    const reduxFormSettings: any = useSelector((state: any) => state.form.forms);
    const userInfo: any = useSelector((state: any) => state.user.user);
    const [selectedPage, setSelectedPage] = useState({
        order: 1
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
    const [pages, setPages] = useState<any>([]);
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState<any[]>([]);
    const [companyName, setCompanyName] = useState('');
    const [companyColor, setCompanyColor] = useState('');
    async function fetchFormDetail(formId: string | undefined, hash: string | null = '') {
        if (formId) {
            const formDetails: any = await kycFormsApi.getFormDetailsForCustomer({
                id: formId,
            });
            setCompanyName(formDetails.form.companyName);
            setCompanyColor(formDetails.form.companyColor);
            setIsFormLoading(false)
            const pagesFromDetails: any = formDetails.form.content.pages;
            let newPages: any = [];
            const cookieHash = getCookie('customerHash');
            if (cookieHash) {
                setCustomerId(cookieHash);
            }
            if (cookieHash || hash) {
                const userData: any = (await kycFormsApi.getCustomerDetails({
                    id: searchParams.get("customerId"),
                    hash: cookieHash
                })).values;
                for (const pageKey in pagesFromDetails) {
                    if(pagesFromDetails.hasOwnProperty(pageKey)) {
                        const newChildren: any[] = pagesFromDetails[pageKey].children.map((child: any) => {
                            if (userData[child.id]) {
                                return {
                                    ...child,
                                    value: child.type === 'file' ||
                                            child.type === 'idCard' ?
                                            `${userData[child.id]}?hash=${encode(userInfo.id)}` : userData[child.id]
                                }
                            } else {
                                return child;
                            }
                        })
                        newPages.push({
                                ...pagesFromDetails[pageKey],
                                children: newChildren
                            }
                        )
                    }
                }
            } else {
                newPages.push(...pagesFromDetails)
            }
            setPages([...newPages])
            if (hash) return;
            newPages.forEach((page: any) => {
                dispatchForms(thunks.addForm(page));
            });
        }
    }

    useEffect(() => {
        const cookieFormId = getCookie('formId');
        if(formId !== cookieFormId) {
            deleteCookie('formId');
            deleteCookie('customerHash');
        }
        if (searchParams.get("hash")) {
            document.cookie = `customerHash=${searchParams.get("hash")};`;
            document.cookie = `formId=${formId};`;
            fetchFormDetail(formId, searchParams.get("hash"));
        }
        if (searchParams.get("customerId")) {
            fetchFormDetail(formId, searchParams.get("customerId"));
        } else {
            fetchFormDetail(formId);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let pagesList: any[] = [];
        for (const key in reduxFormSettings) {
            if (reduxFormSettings.hasOwnProperty(key)) {
                if (!reduxFormSettings[key].hasOwnProperty('formName')) {
                    pagesList.push(reduxFormSettings[key]);
                }
            }
        }
        setPages([...pagesList])
        // eslint-disable-next-line
    }, [reduxFormSettings])

    const [finishModal, setFinishModal] = useState<boolean>(false);
    const [inputsValue, setInputsValue] = useState<object>({});
    //TODO: Send data to API
    const handleChangeValue = (id: string, value: any, pattern: string = '') => {
        if(searchParams.get("customerId")) {
            return;
        }
        const currentPage: any = pages.filter((el: any) => (selectedPage.order === el.order))[0];
        const childElement: any = currentPage.children.filter((child: any) => (child.id === id))[0];
        if (
            childElement.type === 'file' ||
            childElement.type === 'idCard'
        ) {
            setFiles((prevState: any) => ([
                ...prevState,
                {
                    id: childElement.id,
                    value: value,
                    type: childElement.type,
                }
            ]));
        }
        let filteredValue = null;
        if (childElement.hasOwnProperty('isDefault')) {
            if(childElement.type !== 'email') {
                filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
            }
        }
        let newChild: any = {
            ...childElement,
            value: childElement.type === 'file' ||
                    childElement.type === 'idCard' ?
                    value.name : filteredValue !== null ? filteredValue : value,
        }

        if (childElement.hasOwnProperty('isDefault')) {
            newChild.isDefault = childElement.isDefault;
        }
        dispatchForms(thunks.updateFormChild({
            formId: currentPage.id,
            children: {
                ...newChild
            },
            formDbId: formId,
            formData: reduxFormSettings,
            isCustomerForm: true
        }))
    }

    useEffect(() => {
        // eslint-disable-next-line
    }, [errors])
    useEffect(() => {
        // eslint-disable-next-line
    }, [selectedPage])
    useEffect(() => {
        // eslint-disable-next-line
    }, [isLoading])

    const validateInputs = (inputId: string, value: any, currentInput: any) => {
        let isValid = true;
        if (searchParams.get("customerId")) {
            return isValid;
        }
        const errorInputs: any = {}
        if (currentInput.hasOwnProperty('required') && currentInput.required) {
            let required: boolean = false;
            if (currentInput.type === 'phone') {
                required = value === undefined || value.replace(/\(\+\d+\)/, "").replaceAll(' ', '').length < 7;
                if (required) {
                    isValid = false;
                }
            } else {
                required = value === undefined || value.length === 0;
            }
            if (currentInput.type === 'date') {
                required = new Date(value).toString() === 'Invalid Date'
            }
            errorInputs[inputId] = {
                ...errorInputs[inputId],
                required: required
            }
            if (value === undefined || value.length === 0) {
                isValid = false;
            }
        }
        const emailRegEx = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
        if (currentInput.hasOwnProperty('validate') && currentInput.validate) {
            if (currentInput.type === 'email') {
                errorInputs[inputId] = {
                    ...errorInputs[inputId],
                    validate: !emailRegEx.test(value)
                }
                if (!emailRegEx.test(value)) {
                    isValid = false;
                }
            }
            if (currentInput.type === 'id') {
                errorInputs[inputId] = {
                    ...errorInputs[inputId],
                    validate: value.length !== parseInt(currentInput.numberOfDigits)
                }
                isValid = value.length === parseInt(currentInput.numberOfDigits);
            }
        }
        setErrors((prevState: any) => ({
            ...prevState,
            ...errorInputs
        }))
        return isValid;
    }

    const [errorModal, setModalError] = useState<boolean>(false);
    const [message, setMessage] = useState<any[]>([]);
    const goToIDCardField = (errors: any) => {
        let message: any[] = [];
        let errorInputs: any = {};
        let idNumberPosition: number = 0;
        pages.forEach((page: any) => {
            page.children.forEach((child: any) => {
                if(child.type === 'id') {
                    idNumberPosition = page.order;
                }
                if(child.type === 'idCard') {
                    setSelectedPage({order: page.order});
                    errorInputs[child.id] = {
                        ...errorInputs[child.id],
                        validate: true
                    }
                }
            })
        })
        setErrors((prevState: any) => ({
            ...prevState,
            ...errorInputs
        }))
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                if(key !== 'statusCode' && key !== 'error') {
                    if(key === 'message') {
                        message.push(`${errors[key]}`);
                    } else if(errors[key]){
                        message.push(`${key}: ${errors[key]}`);
                    }
                }
                if(key === 'First Name' || key === 'Last Name') {
                    setSelectedPage({order: 1});
                } else if(key === 'Id Number' && idNumberPosition !== 0) {
                    setSelectedPage({order: idNumberPosition});
                }
            }
        }
        if (!errors.hasOwnProperty('message')) {
            message.push('Or ID Card Photo is incorrect')
        }
        setMessage([...message]);
        setModalError(true);
    }
    let submit = false;
    const onBlurInput = async (e: any) => {
        setTimeout(async () => {
            try {
                if (submit) {
                    return;
                }
                const inputId: any = e.target.dataset.inputId;
                const value: any = e.target.value;

                const currentPage: any = pages.filter((page: any) => (selectedPage.order === page.order))[0];
                const currentInput = currentPage.children.filter((child: any) => (child.id === inputId))[0];
                let isValid: boolean = false;
                if (currentInput) {
                    if (currentInput.type === 'date') {
                        isValid = validateInputs(inputId, value, currentInput);
                    } else {
                        isValid = validateInputs(inputId, value, currentInput);
                    }
                    if (isValid) {
                        let childList: any = {};
                        childList = {
                            ...childList,
                            pageId: currentPage.id
                        }
                        if (searchParams.get("agentId")) {
                            childList = {
                                ...childList,
                                agentId: searchParams.get("agentId")
                            }
                        }
                        for (const inputsValueKey in inputsValue) {
                            if (!inputsValue.hasOwnProperty(inputsValueKey)) return;
                            childList = {
                                ...childList,
                                [inputsValueKey]: (inputsValue as { [key: string]: any })[inputsValueKey]
                            }
                        }
                        childList = {
                            ...childList,
                            [inputId]: value,
                            pageId: currentPage.id
                        }
                        let defaultCount: number = 0;
                        for (const childListKey in childList) {
                            if (childList.hasOwnProperty(childListKey)) {
                                if (childListKey !== 'pageId') {
                                    // eslint-disable-next-line
                                    pages.forEach((page: any) => {
                                        const input: any = page.children.filter((child: any) => (child.id === childListKey))[0];
                                        if (input && input.hasOwnProperty('isDefault')
                                            && childList[childListKey]
                                        ) {
                                            defaultCount++;
                                        }
                                    });
                                }
                            }
                        }
                        if (defaultCount === 3) {
                            setIsLoading(true);
                            const response = await sendDataToApi(childList);
                        }
                        setInputsValue((prevState: any) => ({
                            ...prevState,
                            [inputId]: value,
                            pageId: currentPage.id
                        }))
                    } else {

                    }
                }
            } catch (e: any) {

            } finally {
                setIsLoading(false);
                submit = false;
            }
        }, 300);
    }
    const [customerId, setCustomerId] = useState("");
    const nextOrSubmitAction = async () => {
        try {
            submit = true;
            setIsLoading(true);
            if (searchParams.get("customerId")) {
                if (selectedPage.order !== pages.length) {
                    setSelectedPage({
                        order: selectedPage.order + 1
                    })
                }
            }
            const currentPage: any = pages.filter((el: any) => (selectedPage.order === el.order))[0];
            let invalidInputsCount: number = 0;
            currentPage.children.forEach((child: any) => {
                let res: boolean = true;
                if(child.type === 'file' || child.type === 'idCard') {
                    if (files.length > 0) {
                        let isFileFind: boolean = false;
                        files.forEach((file: any) => {
                            if(child.id === file.id) {
                                res = validateInputs(child.id, file.value.name, child);
                                isFileFind = true;
                            }
                        })
                        if (!isFileFind) {
                            res = validateInputs(child.id,  undefined, child);
                        }
                    } else {
                        res = validateInputs(child.id, undefined, child);
                    }
                } else if (child.type === 'phone') {
                    res = validateInputs(child.id, child.value.replace('(+)', ''), child);
                } else {
                    res = validateInputs(child.id, child.value, child);
                }
                invalidInputsCount += res ? 0 : 1;
            })
            if (invalidInputsCount === 0) {
            let childList: any = {};
            if (searchParams.get("agentId")) {
                childList = {
                    ...childList,
                    agentId: searchParams.get("agentId")
                }
            }
            for (const inputsValueKey in inputsValue) {
                if(!inputsValue.hasOwnProperty(inputsValueKey)) return;
                const child: any = (inputsValue as { [key: string]: any })[inputsValueKey];
                childList = {
                    ...childList,
                    [inputsValueKey]: child ? child : ''
                }
            }
            childList = {
                ...childList,
                pageId: currentPage.id
            }
            currentPage.children.forEach((child: any) => {
                if(child.type === 'file' || child.type === 'idCard') {
                    files.forEach((file: any) => {
                        if(child.id === file.id) {
                            childList = {
                                ...childList,
                                [child.id]: file.value
                            }
                        }
                    })
                } else {
                    childList = {
                        ...childList,
                        [child.id]: child.value ? child.value : ''
                    }
                }

            })
            if(searchParams.get("customerId")) {
                setSelectedPage({
                    order: selectedPage.order + 1
                })
                return;
            }
            const response = await sendDataToApi(childList);
            setInputsValue((prevState: any) => ({
                ...prevState,
                ...childList
            }))
            if (response.response.status === 200) {
                if (selectedPage.order !== pages.length) {
                    setSelectedPage({
                        order: selectedPage.order + 1
                    })
                } else {
                    try {
                        const finishResponse: any = await customersApi.setCustomerFormStatus({
                            id: customerId ? customerId : response.response.data.hash,
                        });

                        if(finishResponse.response.status === 200) {
                            setFinishModal(true);
                            deleteCookie('customerHash');
                            deleteCookie('formId');
                        }
                    } catch (e: any) {
                        console.log('e.response.data.errors', e.response)
                        if (e.response.data.errors) {
                            goToIDCardField(e.response.data.errors);
                        } else if (e.response.data.message) {
                            goToIDCardField(e.response.data);
                        }
                    }
                }
            } else {
                alert(response.data.message);
            }
        }
        } catch (e: any) {

        } finally {
            setIsLoading(false);
            submit = false;
        }
    }
    const sendDataToApi = async (childList: any) => {
        const formData = new FormData();
        for (const childListKey in childList) {
            if(!childList.hasOwnProperty(childListKey)) return;
            if(childListKey !== 'undefined') {
                formData.append(`${childListKey}`, (childList as { [key: string]: any })[childListKey]);
            }
        }

        if (customerId) {
            try {
                const result: any = await customersApi.updateCustomer({
                    id: customerId,
                    content: formData
                });
                return result;
            } catch (e: any) {
                return e.response;
            }

        } else {
            const result = await customersApi.addCustomer({
                id: formId,
                content: formData
            });
            setCustomerId(result.response.data.hash)
            document.cookie = `customerHash=${result.response.data.hash};`;
            document.cookie = `formId=${formId};`;
            return result;
        }
    }

    return (
        <>{!isFormLoading && <Box>
            <Box
                sx={{
                    marginTop: "50px",
                    maxWidth: '359px ',
                    width: '100%',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderRadius: '20px',
                    background: '#FFFFFF',
                    boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.08)',
                }}
            >
                <Box
                    sx={{
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                    }}
                >
                    <Box
                        sx={{
                            height: '8px',
                            borderRadius: '8px',
                            background: companyColor ?
                                hexToRGB(companyColor, selectedPage.order === pages.length ? 1 : 0.50) :
                                hexToRGB('#0AB730', selectedPage.order === pages.length ? 1 : 0.50),
                            width: '100%',
                            marginBottom: '23px',
                            position: 'relative',
                            display: 'flex'
                        }}
                    >
                        <Box
                            key={'status'}
                            sx={{
                                pointerEvents: 'none',
                                height: '8px',
                                borderRadius: '8px',
                                background: companyColor ? companyColor : '#0AB730',
                                opacity: (selectedPage.order - 1) === pages.length ? 0.8: 1,
                                marginLeft: `${(100 / pages.length) * (selectedPage.order - 1)}%`,
                                width: `${100 / pages.length}%`,
                                transition: 'margin-left 0.3s ease-in-out',
                            }}
                        />
                        {
                            pages.map((el: any) => (
                                <Box
                                    key={`${el.order}-status`}
                                    sx={{
                                        position: 'absolute',
                                        top: '0',
                                        left: `${(100 / pages.length) * (el.order - 1)}%`,
                                        borderRadius: '8px',
                                        height: '8px',
                                        width: `${100 / pages.length}%`,
                                    }}
                                    onClick={() => {
                                        if(selectedPage.order > el.order || searchParams.get("customerId")) {
                                            setSelectedPage({order: el.order})
                                        }
                                    }}
                                >

                                </Box>
                            ))
                        }
                    </Box>
                    {
                        pages.filter((el: any) => (selectedPage.order === el.order)).map((el: any) => {
                            let childrenList: any = [];
                            if(el.children) {
                                childrenList = [...el.children]
                            }
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gridGap: '9px',
                                    }}
                                    key={el.order + 'progress'}
                                >
                                    {
                                        el.children ?
                                            childrenList
                                                .sort((a: any, b: any) => (a.order - b.order))
                                                .map((child: any) => {
                                                    return selectInputByType(
                                                        child,
                                                        !!searchParams.get("customerId"),
                                                        onBlurInput,
                                                        handleChangeValue,
                                                        errors,
                                                        companyColor
                                                    );
                                                }) : null
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>

                <Divider
                    sx={{
                        marginTop: '23px',
                        strokeWidth: '1px',
                        stroke: '#D7D7D7',
                        filter: 'drop-shadow(0px 2px 4px rgba(184, 184, 184, 0.50))',
                    }}
                />
                <Box
                    sx={{
                        marginTop: '26px',
                        paddingBottom: '15px',
                        paddingLeft: '15px',
                        paddingRight: '15px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gridGap: '14px',
                        }}
                    >
                    {
                        selectedPage.order !== 1 ? (
                        <Button
                            variant="contained"
                            sx={{
                                width: '100%',
                                background: companyColor ? companyColor : '#00C650',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '157%',
                                marginBottom: '17px',
                                padding: '0',
                                ".hoverEffect": {
                                    width: '100%',
                                    padding: '8px 20px',
                                    borderRadius: '12px',
                                    transition: 'background 0.3s ease-in-out',
                                },
                                "&:hover": {
                                    background: companyColor ? companyColor : '#00C650',
                                    ".hoverEffect": {
                                        background: 'rgba(0, 0, 0, 0.5)',
                                        transition: 'background 0.3s ease-in-out',
                                    },
                                }
                            }}
                            onClick={()=>{
                                setSelectedPage({
                                    order: selectedPage.order - 1
                                })
                            }}
                        >
                            <div className='hoverEffect' >
                                Back
                            </div>
                        </Button>) : null
                    }
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!customerId && !searchParams.get("customerId") && isLoading}
                        sx={{
                            width: '100%',
                            background: companyColor ? companyColor : '#00C650',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '157%',
                            marginBottom: '17px',
                            padding: '0',
                            display: selectedPage.order === pages.length && searchParams.get("customerId") ? 'none' : 'block',
                            ".hoverEffect": {
                                width: '100%',
                                padding: '8px 20px',
                                borderRadius: '12px',
                                transition: 'background 0.3s ease-in-out',
                            },
                            "&:hover": {
                                background: companyColor ? companyColor : '#00C650',
                                ".hoverEffect": {
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    transition: 'background 0.3s ease-in-out',
                                },
                            }
                        }}
                        onClick={() => isLoading ? null : nextOrSubmitAction()}
                    >
                        <div
                            className='hoverEffect'
                        >
                            {isLoading ?
                                (<span> Waiting...</span>) :
                                (<span>{selectedPage.order === pages.length ? 'Submit' : 'Next'}</span>)
                            }
                        </div>
                    </Button>
                    </Box>
                    {
                        pages.length !== 1 && pages.length > 2 && selectedPage.order === pages.length &&
                        searchParams.get("customerId") ? (
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    background: companyColor ? companyColor : '#00C650',
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '157%',
                                    marginBottom: '17px',
                                    padding: '0',
                                    ".hoverEffect": {
                                        width: '100%',
                                        padding: '8px 20px',
                                        borderRadius: '12px',
                                        transition: 'background 0.3s ease-in-out',
                                    },
                                    "&:hover": {
                                        background: companyColor ? companyColor : '#00C650',
                                        ".hoverEffect": {
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            transition: 'background 0.3s ease-in-out',
                                        },
                                    }
                                }}
                                onClick={() => {setSelectedPage({ order: 1 })}}
                            >
                                <div
                                    className='hoverEffect'
                                >
                                    Back to start   
                                </div>
                            </Button>
                        ) : null
                    }
                    <Typography
                        variant="body2"
                        sx={{textAlign: 'center', paddingLeft: '24px', paddingRight: '24px'}}
                    >
                        Brought to you by {companyName ? companyName : '*Company name*'} and YesYou Limited
                    </Typography>
                </Box>
            </Box>
            {/*{isLoading && <SplashScreen isCustomerForm={true}/>}*/}
            <Modal
                open={finishModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        maxWidth: '359px ',
                        width: '100%',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '20px',
                        background: '#FFFFFF',
                        boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.08)',
                        padding: '40px'
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{textAlign: 'center', marginBottom: '12px'}}
                    >
                        Thank you for completing our form!
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{textAlign: 'center'}}
                    >
                        Your cooperation helps us better serve you.
                        If you have any questions, feel free to reach out.<br /><br />
                        You may now close this window.
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={errorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        maxWidth: '359px ',
                        width: '100%',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '20px',
                        background: '#FFFFFF',
                        boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.08)',
                        padding: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{textAlign: 'center', marginBottom: '12px', color: 'red'}}
                    >
                        Error!
                    </Typography>
                    {message.map((el:any, index: number) => (
                        <Typography
                            key={index}
                            variant="body2"
                            sx={{textAlign: 'center'}}
                        >
                            {el}
                        </Typography>
                    ))}
                    <Button
                        sx={{
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            color: companyColor ? companyColor : '#00C650',
                        }}
                        onClick={()=>{setModalError(false)}}
                    >
                        Go Back
                    </Button>
                </Box>
            </Modal>
        </Box>}</>
    )
}

export default FilledModal;