export interface CountriesProps {
    name: string;
    native: string;
    phone: string;
    continent: string;
    capital: string;
    currency: string;
    languages: string[];
    emoji: string;
    emojiU: string;
}
export const countries = [
    {
        "name": "",
        "native": "",
        "phone": "",
        "continent": "",
        "capital": "",
        "currency": "",
        "languages": [],
        "emoji": "",
        "emojiU": ""
    },
{
    "name": "Andorra",
    "native": "Andorra",
    "phone": "376",
    "continent": "EU",
    "capital": "Andorra la Vella",
    "currency": "EUR",
    "languages": [
        "ca"
    ],
    "emoji": "🇦🇩",
    "emojiU": "U+1F1E6 U+1F1E9"
},
{
    "name": "United Arab Emirates",
        "native": "دولة الإمارات العربية المتحدة",
        "phone": "971",
        "continent": "AS",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "languages": [
        "ar"
    ],
        "emoji": "🇦🇪",
        "emojiU": "U+1F1E6 U+1F1EA"
},
{
    "name": "Afghanistan",
        "native": "افغانستان",
        "phone": "93",
        "continent": "AS",
        "capital": "Kabul",
        "currency": "AFN",
        "languages": [
        "ps",
        "uz",
        "tk"
    ],
        "emoji": "🇦🇫",
        "emojiU": "U+1F1E6 U+1F1EB"
},
{
    "name": "Antigua and Barbuda",
        "native": "Antigua and Barbuda",
        "phone": "1268",
        "continent": "NA",
        "capital": "Saint John's",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇦🇬",
        "emojiU": "U+1F1E6 U+1F1EC"
},
{
    "name": "Anguilla",
        "native": "Anguilla",
        "phone": "1264",
        "continent": "NA",
        "capital": "The Valley",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇦🇮",
        "emojiU": "U+1F1E6 U+1F1EE"
},
{
    "name": "Albania",
        "native": "Shqipëria",
        "phone": "355",
        "continent": "EU",
        "capital": "Tirana",
        "currency": "ALL",
        "languages": [
        "sq"
    ],
        "emoji": "🇦🇱",
        "emojiU": "U+1F1E6 U+1F1F1"
},
{
    "name": "Armenia",
        "native": "Հայաստան",
        "phone": "374",
        "continent": "AS",
        "capital": "Yerevan",
        "currency": "AMD",
        "languages": [
        "hy",
        "ru"
    ],
        "emoji": "🇦🇲",
        "emojiU": "U+1F1E6 U+1F1F2"
},
{
    "name": "Angola",
        "native": "Angola",
        "phone": "244",
        "continent": "AF",
        "capital": "Luanda",
        "currency": "AOA",
        "languages": [
        "pt"
    ],
        "emoji": "🇦🇴",
        "emojiU": "U+1F1E6 U+1F1F4"
},
{
    "name": "Antarctica",
        "native": "Antarctica",
        "phone": "672",
        "continent": "AN",
        "capital": "",
        "currency": "",
        "languages": [],
        "emoji": "🇦🇶",
        "emojiU": "U+1F1E6 U+1F1F6"
},
{
    "name": "Argentina",
        "native": "Argentina",
        "phone": "54",
        "continent": "SA",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "languages": [
        "es",
        "gn"
    ],
        "emoji": "🇦🇷",
        "emojiU": "U+1F1E6 U+1F1F7"
},
{
    "name": "American Samoa",
        "native": "American Samoa",
        "phone": "1684",
        "continent": "OC",
        "capital": "Pago Pago",
        "currency": "USD",
        "languages": [
        "en",
        "sm"
    ],
        "emoji": "🇦🇸",
        "emojiU": "U+1F1E6 U+1F1F8"
},
{
    "name": "Austria",
        "native": "Österreich",
        "phone": "43",
        "continent": "EU",
        "capital": "Vienna",
        "currency": "EUR",
        "languages": [
        "de"
    ],
        "emoji": "🇦🇹",
        "emojiU": "U+1F1E6 U+1F1F9"
},
{
    "name": "Australia",
        "native": "Australia",
        "phone": "61",
        "continent": "OC",
        "capital": "Canberra",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇦🇺",
        "emojiU": "U+1F1E6 U+1F1FA"
},
{
    "name": "Aruba",
        "native": "Aruba",
        "phone": "297",
        "continent": "NA",
        "capital": "Oranjestad",
        "currency": "AWG",
        "languages": [
        "nl",
        "pa"
    ],
        "emoji": "🇦🇼",
        "emojiU": "U+1F1E6 U+1F1FC"
},
{
    "name": "Åland",
        "native": "Åland",
        "phone": "358",
        "continent": "EU",
        "capital": "Mariehamn",
        "currency": "EUR",
        "languages": [
        "sv"
    ],
        "emoji": "🇦🇽",
        "emojiU": "U+1F1E6 U+1F1FD"
},
{
    "name": "Azerbaijan",
        "native": "Azərbaycan",
        "phone": "994",
        "continent": "AS",
        "capital": "Baku",
        "currency": "AZN",
        "languages": [
        "az"
    ],
        "emoji": "🇦🇿",
        "emojiU": "U+1F1E6 U+1F1FF"
},
{
    "name": "Bosnia and Herzegovina",
        "native": "Bosna i Hercegovina",
        "phone": "387",
        "continent": "EU",
        "capital": "Sarajevo",
        "currency": "BAM",
        "languages": [
        "bs",
        "hr",
        "sr"
    ],
        "emoji": "🇧🇦",
        "emojiU": "U+1F1E7 U+1F1E6"
},
{
    "name": "Barbados",
        "native": "Barbados",
        "phone": "1246",
        "continent": "NA",
        "capital": "Bridgetown",
        "currency": "BBD",
        "languages": [
        "en"
    ],
        "emoji": "🇧🇧",
        "emojiU": "U+1F1E7 U+1F1E7"
},
{
    "name": "Bangladesh",
        "native": "Bangladesh",
        "phone": "880",
        "continent": "AS",
        "capital": "Dhaka",
        "currency": "BDT",
        "languages": [
        "bn"
    ],
        "emoji": "🇧🇩",
        "emojiU": "U+1F1E7 U+1F1E9"
},
{
    "name": "Belgium",
        "native": "België",
        "phone": "32",
        "continent": "EU",
        "capital": "Brussels",
        "currency": "EUR",
        "languages": [
        "nl",
        "fr",
        "de"
    ],
        "emoji": "🇧🇪",
        "emojiU": "U+1F1E7 U+1F1EA"
},
{
    "name": "Burkina Faso",
        "native": "Burkina Faso",
        "phone": "226",
        "continent": "AF",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "languages": [
        "fr",
        "ff"
    ],
        "emoji": "🇧🇫",
        "emojiU": "U+1F1E7 U+1F1EB"
},
{
    "name": "Bulgaria",
        "native": "България",
        "phone": "359",
        "continent": "EU",
        "capital": "Sofia",
        "currency": "BGN",
        "languages": [
        "bg"
    ],
        "emoji": "🇧🇬",
        "emojiU": "U+1F1E7 U+1F1EC"
},
{
    "name": "Bahrain",
        "native": "‏البحرين",
        "phone": "973",
        "continent": "AS",
        "capital": "Manama",
        "currency": "BHD",
        "languages": [
        "ar"
    ],
        "emoji": "🇧🇭",
        "emojiU": "U+1F1E7 U+1F1ED"
},
{
    "name": "Burundi",
        "native": "Burundi",
        "phone": "257",
        "continent": "AF",
        "capital": "Bujumbura",
        "currency": "BIF",
        "languages": [
        "fr",
        "rn"
    ],
        "emoji": "🇧🇮",
        "emojiU": "U+1F1E7 U+1F1EE"
},
{
    "name": "Benin",
        "native": "Bénin",
        "phone": "229",
        "continent": "AF",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "languages": [
        "fr"
    ],
        "emoji": "🇧🇯",
        "emojiU": "U+1F1E7 U+1F1EF"
},
{
    "name": "Saint Barthélemy",
        "native": "Saint-Barthélemy",
        "phone": "590",
        "continent": "NA",
        "capital": "Gustavia",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇧🇱",
        "emojiU": "U+1F1E7 U+1F1F1"
},
{
    "name": "Bermuda",
        "native": "Bermuda",
        "phone": "1441",
        "continent": "NA",
        "capital": "Hamilton",
        "currency": "BMD",
        "languages": [
        "en"
    ],
        "emoji": "🇧🇲",
        "emojiU": "U+1F1E7 U+1F1F2"
},
{
    "name": "Brunei",
        "native": "Negara Brunei Darussalam",
        "phone": "673",
        "continent": "AS",
        "capital": "Bandar Seri Begawan",
        "currency": "BND",
        "languages": [
        "ms"
    ],
        "emoji": "🇧🇳",
        "emojiU": "U+1F1E7 U+1F1F3"
},
{
    "name": "Bolivia",
        "native": "Bolivia",
        "phone": "591",
        "continent": "SA",
        "capital": "Sucre",
        "currency": "BOB,BOV",
        "languages": [
        "es",
        "ay",
        "qu"
    ],
        "emoji": "🇧🇴",
        "emojiU": "U+1F1E7 U+1F1F4"
},
{
    "name": "Bonaire",
        "native": "Bonaire",
        "phone": "5997",
        "continent": "NA",
        "capital": "Kralendijk",
        "currency": "USD",
        "languages": [
        "nl"
    ],
        "emoji": "🇧🇶",
        "emojiU": "U+1F1E7 U+1F1F6"
},
{
    "name": "Brazil",
        "native": "Brasil",
        "phone": "55",
        "continent": "SA",
        "capital": "Brasília",
        "currency": "BRL",
        "languages": [
        "pt"
    ],
        "emoji": "🇧🇷",
        "emojiU": "U+1F1E7 U+1F1F7"
},
{
    "name": "Bahamas",
        "native": "Bahamas",
        "phone": "1242",
        "continent": "NA",
        "capital": "Nassau",
        "currency": "BSD",
        "languages": [
        "en"
    ],
        "emoji": "🇧🇸",
        "emojiU": "U+1F1E7 U+1F1F8"
},
{
    "name": "Bhutan",
        "native": "ʼbrug-yul",
        "phone": "975",
        "continent": "AS",
        "capital": "Thimphu",
        "currency": "BTN,INR",
        "languages": [
        "dz"
    ],
        "emoji": "🇧🇹",
        "emojiU": "U+1F1E7 U+1F1F9"
},
{
    "name": "Bouvet Island",
        "native": "Bouvetøya",
        "phone": "47",
        "continent": "AN",
        "capital": "",
        "currency": "NOK",
        "languages": [
        "no",
        "nb",
        "nn"
    ],
        "emoji": "🇧🇻",
        "emojiU": "U+1F1E7 U+1F1FB"
},
{
    "name": "Botswana",
        "native": "Botswana",
        "phone": "267",
        "continent": "AF",
        "capital": "Gaborone",
        "currency": "BWP",
        "languages": [
        "en",
        "tn"
    ],
        "emoji": "🇧🇼",
        "emojiU": "U+1F1E7 U+1F1FC"
},
 {
    "name": "Belarus",
        "native": "Белару́сь",
        "phone": "375",
        "continent": "EU",
        "capital": "Minsk",
        "currency": "BYN",
        "languages": [
        "be",
        "ru"
    ],
        "emoji": "🇧🇾",
        "emojiU": "U+1F1E7 U+1F1FE"
},
{
    "name": "Belize",
        "native": "Belize",
        "phone": "501",
        "continent": "NA",
        "capital": "Belmopan",
        "currency": "BZD",
        "languages": [
        "en",
        "es"
    ],
        "emoji": "🇧🇿",
        "emojiU": "U+1F1E7 U+1F1FF"
},
{
    "name": "Canada",
        "native": "Canada",
        "phone": "1",
        "continent": "NA",
        "capital": "Ottawa",
        "currency": "CAD",
        "languages": [
        "en",
        "fr"
    ],
        "emoji": "🇨🇦",
        "emojiU": "U+1F1E8 U+1F1E6"
},
{
    "name": "Cocos [Keeling] Islands",
        "native": "Cocos (Keeling) Islands",
        "phone": "61",
        "continent": "AS",
        "capital": "West Island",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇨🇨",
        "emojiU": "U+1F1E8 U+1F1E8"
},
{
    "name": "Democratic Republic of the Congo",
        "native": "République démocratique du Congo",
        "phone": "243",
        "continent": "AF",
        "capital": "Kinshasa",
        "currency": "CDF",
        "languages": [
        "fr",
        "ln",
        "kg",
        "sw",
        "lu"
    ],
        "emoji": "🇨🇩",
        "emojiU": "U+1F1E8 U+1F1E9"
},
{
    "name": "Central African Republic",
        "native": "Ködörösêse tî Bêafrîka",
        "phone": "236",
        "continent": "AF",
        "capital": "Bangui",
        "currency": "XAF",
        "languages": [
        "fr",
        "sg"
    ],
        "emoji": "🇨🇫",
        "emojiU": "U+1F1E8 U+1F1EB"
},
{
    "name": "Republic of the Congo",
        "native": "République du Congo",
        "phone": "242",
        "continent": "AF",
        "capital": "Brazzaville",
        "currency": "XAF",
        "languages": [
        "fr",
        "ln"
    ],
        "emoji": "🇨🇬",
        "emojiU": "U+1F1E8 U+1F1EC"
},
{
    "name": "Switzerland",
        "native": "Schweiz",
        "phone": "41",
        "continent": "EU",
        "capital": "Bern",
        "currency": "CHE,CHF,CHW",
        "languages": [
        "de",
        "fr",
        "it"
    ],
        "emoji": "🇨🇭",
        "emojiU": "U+1F1E8 U+1F1ED"
},
{
    "name": "Ivory Coast",
        "native": "Côte d'Ivoire",
        "phone": "225",
        "continent": "AF",
        "capital": "Yamoussoukro",
        "currency": "XOF",
        "languages": [
        "fr"
    ],
        "emoji": "🇨🇮",
        "emojiU": "U+1F1E8 U+1F1EE"
},
{
    "name": "Cook Islands",
        "native": "Cook Islands",
        "phone": "682",
        "continent": "OC",
        "capital": "Avarua",
        "currency": "NZD",
        "languages": [
        "en"
    ],
        "emoji": "🇨🇰",
        "emojiU": "U+1F1E8 U+1F1F0"
},
 {
    "name": "Chile",
        "native": "Chile",
        "phone": "56",
        "continent": "SA",
        "capital": "Santiago",
        "currency": "CLF,CLP",
        "languages": [
        "es"
    ],
        "emoji": "🇨🇱",
        "emojiU": "U+1F1E8 U+1F1F1"
},
    {
    "name": "Cameroon",
        "native": "Cameroon",
        "phone": "237",
        "continent": "AF",
        "capital": "Yaoundé",
        "currency": "XAF",
        "languages": [
        "en",
        "fr"
    ],
        "emoji": "🇨🇲",
        "emojiU": "U+1F1E8 U+1F1F2"
},
{
    "name": "China",
        "native": "中国",
        "phone": "86",
        "continent": "AS",
        "capital": "Beijing",
        "currency": "CNY",
        "languages": [
        "zh"
    ],
        "emoji": "🇨🇳",
        "emojiU": "U+1F1E8 U+1F1F3"
},
{
    "name": "Colombia",
        "native": "Colombia",
        "phone": "57",
        "continent": "SA",
        "capital": "Bogotá",
        "currency": "COP",
        "languages": [
        "es"
    ],
        "emoji": "🇨🇴",
        "emojiU": "U+1F1E8 U+1F1F4"
},
{
    "name": "Costa Rica",
        "native": "Costa Rica",
        "phone": "506",
        "continent": "NA",
        "capital": "San José",
        "currency": "CRC",
        "languages": [
        "es"
    ],
        "emoji": "🇨🇷",
        "emojiU": "U+1F1E8 U+1F1F7"
},
{
    "name": "Cuba",
        "native": "Cuba",
        "phone": "53",
        "continent": "NA",
        "capital": "Havana",
        "currency": "CUC,CUP",
        "languages": [
        "es"
    ],
        "emoji": "🇨🇺",
        "emojiU": "U+1F1E8 U+1F1FA"
},
{
    "name": "Cape Verde",
        "native": "Cabo Verde",
        "phone": "238",
        "continent": "AF",
        "capital": "Praia",
        "currency": "CVE",
        "languages": [
        "pt"
    ],
        "emoji": "🇨🇻",
        "emojiU": "U+1F1E8 U+1F1FB"
},
{
    "name": "Curacao",
        "native": "Curaçao",
        "phone": "5999",
        "continent": "NA",
        "capital": "Willemstad",
        "currency": "ANG",
        "languages": [
        "nl",
        "pa",
        "en"
    ],
        "emoji": "🇨🇼",
        "emojiU": "U+1F1E8 U+1F1FC"
},
{
    "name": "Christmas Island",
        "native": "Christmas Island",
        "phone": "61",
        "continent": "AS",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇨🇽",
        "emojiU": "U+1F1E8 U+1F1FD"
},
{
    "name": "Cyprus",
        "native": "Κύπρος",
        "phone": "357",
        "continent": "EU",
        "capital": "Nicosia",
        "currency": "EUR",
        "languages": [
        "el",
        "tr",
        "hy"
    ],
        "emoji": "🇨🇾",
        "emojiU": "U+1F1E8 U+1F1FE"
},
{
    "name": "Czech Republic",
        "native": "Česká republika",
        "phone": "420",
        "continent": "EU",
        "capital": "Prague",
        "currency": "CZK",
        "languages": [
        "cs",
        "sk"
    ],
        "emoji": "🇨🇿",
        "emojiU": "U+1F1E8 U+1F1FF"
},
{
    "name": "Germany",
        "native": "Deutschland",
        "phone": "49",
        "continent": "EU",
        "capital": "Berlin",
        "currency": "EUR",
        "languages": [
        "de"
    ],
        "emoji": "🇩🇪",
        "emojiU": "U+1F1E9 U+1F1EA"
},
{
    "name": "Djibouti",
        "native": "Djibouti",
        "phone": "253",
        "continent": "AF",
        "capital": "Djibouti",
        "currency": "DJF",
        "languages": [
        "fr",
        "ar"
    ],
        "emoji": "🇩🇯",
        "emojiU": "U+1F1E9 U+1F1EF"
},
{
    "name": "Denmark",
        "native": "Danmark",
        "phone": "45",
        "continent": "EU",
        "capital": "Copenhagen",
        "currency": "DKK",
        "languages": [
        "da"
    ],
        "emoji": "🇩🇰",
        "emojiU": "U+1F1E9 U+1F1F0"
},
 {
    "name": "Dominica",
        "native": "Dominica",
        "phone": "1767",
        "continent": "NA",
        "capital": "Roseau",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇩🇲",
        "emojiU": "U+1F1E9 U+1F1F2"
},
 {
    "name": "Dominican Republic",
        "native": "República Dominicana",
        "phone": "1809",
        "continent": "NA",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "languages": [
        "es"
    ],
        "emoji": "🇩🇴",
        "emojiU": "U+1F1E9 U+1F1F4"
},
 {
    "name": "Dominican Republic",
        "native": "República Dominicana",
        "phone": "1829",
        "continent": "NA",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "languages": [
        "es"
    ],
        "emoji": "🇩🇴",
        "emojiU": "U+1F1E9 U+1F1F4"
},
 {
    "name": "Dominican Republic",
        "native": "República Dominicana",
        "phone": "1849",
        "continent": "NA",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "languages": [
        "es"
    ],
        "emoji": "🇩🇴",
        "emojiU": "U+1F1E9 U+1F1F4"
},
 {
    "name": "Algeria",
        "native": "الجزائر",
        "phone": "213",
        "continent": "AF",
        "capital": "Algiers",
        "currency": "DZD",
        "languages": [
        "ar"
    ],
        "emoji": "🇩🇿",
        "emojiU": "U+1F1E9 U+1F1FF"
},
{
    "name": "Ecuador",
        "native": "Ecuador",
        "phone": "593",
        "continent": "SA",
        "capital": "Quito",
        "currency": "USD",
        "languages": [
        "es"
    ],
        "emoji": "🇪🇨",
        "emojiU": "U+1F1EA U+1F1E8"
},
{
    "name": "Estonia",
        "native": "Eesti",
        "phone": "372",
        "continent": "EU",
        "capital": "Tallinn",
        "currency": "EUR",
        "languages": [
        "et"
    ],
        "emoji": "🇪🇪",
        "emojiU": "U+1F1EA U+1F1EA"
},
{
    "name": "Egypt",
        "native": "مصر‎",
        "phone": "20",
        "continent": "AF",
        "capital": "Cairo",
        "currency": "EGP",
        "languages": [
        "ar"
    ],
        "emoji": "🇪🇬",
        "emojiU": "U+1F1EA U+1F1EC"
},
 {
    "name": "Western Sahara",
        "native": "الصحراء الغربية",
        "phone": "212",
        "continent": "AF",
        "capital": "El Aaiún",
        "currency": "MAD,DZD,MRU",
        "languages": [
        "es"
    ],
        "emoji": "🇪🇭",
        "emojiU": "U+1F1EA U+1F1ED"
},
 {
    "name": "Eritrea",
        "native": "ኤርትራ",
        "phone": "291",
        "continent": "AF",
        "capital": "Asmara",
        "currency": "ERN",
        "languages": [
        "ti",
        "ar",
        "en"
    ],
        "emoji": "🇪🇷",
        "emojiU": "U+1F1EA U+1F1F7"
},
 {
    "name": "Spain",
        "native": "España",
        "phone": "34",
        "continent": "EU",
        "capital": "Madrid",
        "currency": "EUR",
        "languages": [
        "es",
        "eu",
        "ca",
        "gl",
        "oc"
    ],
        "emoji": "🇪🇸",
        "emojiU": "U+1F1EA U+1F1F8"
},
{
    "name": "Ethiopia",
        "native": "ኢትዮጵያ",
        "phone": "251",
        "continent": "AF",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "languages": [
        "am"
    ],
        "emoji": "🇪🇹",
        "emojiU": "U+1F1EA U+1F1F9"
},
{
    "name": "Finland",
        "native": "Suomi",
        "phone": "358",
        "continent": "EU",
        "capital": "Helsinki",
        "currency": "EUR",
        "languages": [
        "fi",
        "sv"
    ],
        "emoji": "🇫🇮",
        "emojiU": "U+1F1EB U+1F1EE"
},
{
    "name": "Fiji",
        "native": "Fiji",
        "phone": "679",
        "continent": "OC",
        "capital": "Suva",
        "currency": "FJD",
        "languages": [
        "en",
        "fj",
        "hi",
        "ur"
    ],
        "emoji": "🇫🇯",
        "emojiU": "U+1F1EB U+1F1EF"
},
 {
    "name": "Falkland Islands",
        "native": "Falkland Islands",
        "phone": "500",
        "continent": "SA",
        "capital": "Stanley",
        "currency": "FKP",
        "languages": [
        "en"
    ],
        "emoji": "🇫🇰",
        "emojiU": "U+1F1EB U+1F1F0"
},
{
    "name": "Micronesia",
        "native": "Micronesia",
        "phone": "691",
        "continent": "OC",
        "capital": "Palikir",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇫🇲",
        "emojiU": "U+1F1EB U+1F1F2"
},
 {
    "name": "Faroe Islands",
        "native": "Føroyar",
        "phone": "298",
        "continent": "EU",
        "capital": "Tórshavn",
        "currency": "DKK",
        "languages": [
        "fo"
    ],
        "emoji": "🇫🇴",
        "emojiU": "U+1F1EB U+1F1F4"
},
 {
    "name": "France",
        "native": "France",
        "phone": "33",
        "continent": "EU",
        "capital": "Paris",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇫🇷",
        "emojiU": "U+1F1EB U+1F1F7"
},
{
    "name": "Gabon",
        "native": "Gabon",
        "phone": "241",
        "continent": "AF",
        "capital": "Libreville",
        "currency": "XAF",
        "languages": [
        "fr"
    ],
        "emoji": "🇬🇦",
        "emojiU": "U+1F1EC U+1F1E6"
},
{
    "name": "United Kingdom",
        "native": "United Kingdom",
        "phone": "44",
        "continent": "EU",
        "capital": "London",
        "currency": "GBP",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇧",
        "emojiU": "U+1F1EC U+1F1E7"
},
{
    "name": "Grenada",
        "native": "Grenada",
        "phone": "1473",
        "continent": "NA",
        "capital": "St. George's",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇩",
        "emojiU": "U+1F1EC U+1F1E9"
},
{
    "name": "Georgia",
        "native": "საქართველო",
        "phone": "995",
        "continent": "AS",
        "capital": "Tbilisi",
        "currency": "GEL",
        "languages": [
        "ka"
    ],
        "emoji": "🇬🇪",
        "emojiU": "U+1F1EC U+1F1EA"
},
{
    "name": "French Guiana",
        "native": "Guyane française",
        "phone": "594",
        "continent": "SA",
        "capital": "Cayenne",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇬🇫",
        "emojiU": "U+1F1EC U+1F1EB"
},
{
    "name": "Guernsey",
        "native": "Guernsey",
        "phone": "44",
        "continent": "EU",
        "capital": "St. Peter Port",
        "currency": "GBP",
        "languages": [
        "en",
        "fr"
    ],
        "emoji": "🇬🇬",
        "emojiU": "U+1F1EC U+1F1EC"
},
{
    "name": "Ghana",
        "native": "Ghana",
        "phone": "233",
        "continent": "AF",
        "capital": "Accra",
        "currency": "GHS",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇭",
        "emojiU": "U+1F1EC U+1F1ED"
},
{
    "name": "Gibraltar",
        "native": "Gibraltar",
        "phone": "350",
        "continent": "EU",
        "capital": "Gibraltar",
        "currency": "GIP",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇮",
        "emojiU": "U+1F1EC U+1F1EE"
},
{
    "name": "Greenland",
        "native": "Kalaallit Nunaat",
        "phone": "299",
        "continent": "NA",
        "capital": "Nuuk",
        "currency": "DKK",
        "languages": [
        "kl"
    ],
        "emoji": "🇬🇱",
        "emojiU": "U+1F1EC U+1F1F1"
},
 {
    "name": "Gambia",
        "native": "Gambia",
        "phone": "220",
        "continent": "AF",
        "capital": "Banjul",
        "currency": "GMD",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇲",
        "emojiU": "U+1F1EC U+1F1F2"
},
{
    "name": "Guinea",
        "native": "Guinée",
        "phone": "224",
        "continent": "AF",
        "capital": "Conakry",
        "currency": "GNF",
        "languages": [
        "fr",
        "ff"
    ],
        "emoji": "🇬🇳",
        "emojiU": "U+1F1EC U+1F1F3"
},
{
    "name": "Guadeloupe",
        "native": "Guadeloupe",
        "phone": "590",
        "continent": "NA",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇬🇵",
        "emojiU": "U+1F1EC U+1F1F5"
},
 {
    "name": "Equatorial Guinea",
        "native": "Guinea Ecuatorial",
        "phone": "240",
        "continent": "AF",
        "capital": "Malabo",
        "currency": "XAF",
        "languages": [
        "es",
        "fr"
    ],
        "emoji": "🇬🇶",
        "emojiU": "U+1F1EC U+1F1F6"
},
{
    "name": "Greece",
        "native": "Ελλάδα",
        "phone": "30",
        "continent": "EU",
        "capital": "Athens",
        "currency": "EUR",
        "languages": [
        "el"
    ],
        "emoji": "🇬🇷",
        "emojiU": "U+1F1EC U+1F1F7"
},
{
    "name": "South Georgia and the South Sandwich Islands",
        "native": "South Georgia",
        "phone": "500",
        "continent": "AN",
        "capital": "King Edward Point",
        "currency": "GBP",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇸",
        "emojiU": "U+1F1EC U+1F1F8"
},
{
    "name": "Guatemala",
        "native": "Guatemala",
        "phone": "502",
        "continent": "NA",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "languages": [
        "es"
    ],
        "emoji": "🇬🇹",
        "emojiU": "U+1F1EC U+1F1F9"
},
{
    "name": "Guam",
        "native": "Guam",
        "phone": "1671",
        "continent": "OC",
        "capital": "Hagåtña",
        "currency": "USD",
        "languages": [
        "en",
        "ch",
        "es"
    ],
        "emoji": "🇬🇺",
        "emojiU": "U+1F1EC U+1F1FA"
},
{
    "name": "Guinea-Bissau",
        "native": "Guiné-Bissau",
        "phone": "245",
        "continent": "AF",
        "capital": "Bissau",
        "currency": "XOF",
        "languages": [
        "pt"
    ],
        "emoji": "🇬🇼",
        "emojiU": "U+1F1EC U+1F1FC"
},
{
    "name": "Guyana",
        "native": "Guyana",
        "phone": "592",
        "continent": "SA",
        "capital": "Georgetown",
        "currency": "GYD",
        "languages": [
        "en"
    ],
        "emoji": "🇬🇾",
        "emojiU": "U+1F1EC U+1F1FE"
},
{
    "name": "Hong Kong",
        "native": "香港",
        "phone": "852",
        "continent": "AS",
        "capital": "City of Victoria",
        "currency": "HKD",
        "languages": [
        "zh",
        "en"
    ],
        "emoji": "🇭🇰",
        "emojiU": "U+1F1ED U+1F1F0"
},
{
    "name": "Heard Island and McDonald Islands",
        "native": "Heard Island and McDonald Islands",
        "phone": "61",
        "continent": "AN",
        "capital": "",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇭🇲",
        "emojiU": "U+1F1ED U+1F1F2"
},
{
    "name": "Honduras",
        "native": "Honduras",
        "phone": "504",
        "continent": "NA",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "languages": [
        "es"
    ],
        "emoji": "🇭🇳",
        "emojiU": "U+1F1ED U+1F1F3"
},
{
    "name": "Croatia",
        "native": "Hrvatska",
        "phone": "385",
        "continent": "EU",
        "capital": "Zagreb",
        "currency": "HRK",
        "languages": [
        "hr"
    ],
        "emoji": "🇭🇷",
        "emojiU": "U+1F1ED U+1F1F7"
},
{
    "name": "Haiti",
        "native": "Haïti",
        "phone": "509",
        "continent": "NA",
        "capital": "Port-au-Prince",
        "currency": "HTG,USD",
        "languages": [
        "fr",
        "ht"
    ],
        "emoji": "🇭🇹",
        "emojiU": "U+1F1ED U+1F1F9"
},
{
    "name": "Hungary",
        "native": "Magyarország",
        "phone": "36",
        "continent": "EU",
        "capital": "Budapest",
        "currency": "HUF",
        "languages": [
        "hu"
    ],
        "emoji": "🇭🇺",
        "emojiU": "U+1F1ED U+1F1FA"
},
{
    "name": "Indonesia",
        "native": "Indonesia",
        "phone": "62",
        "continent": "AS",
        "capital": "Jakarta",
        "currency": "IDR",
        "languages": [
        "id"
    ],
        "emoji": "🇮🇩",
        "emojiU": "U+1F1EE U+1F1E9"
},
{
    "name": "Ireland",
        "native": "Éire",
        "phone": "353",
        "continent": "EU",
        "capital": "Dublin",
        "currency": "EUR",
        "languages": [
        "ga",
        "en"
    ],
        "emoji": "🇮🇪",
        "emojiU": "U+1F1EE U+1F1EA"
},
{
    "name": "Israel",
        "native": "יִשְׂרָאֵל",
        "phone": "972",
        "continent": "AS",
        "capital": "Jerusalem",
        "currency": "ILS",
        "languages": [
        "he",
        "ar"
    ],
        "emoji": "🇮🇱",
        "emojiU": "U+1F1EE U+1F1F1"
},
 {
    "name": "Isle of Man",
        "native": "Isle of Man",
        "phone": "44",
        "continent": "EU",
        "capital": "Douglas",
        "currency": "GBP",
        "languages": [
        "en",
        "gv"
    ],
        "emoji": "🇮🇲",
        "emojiU": "U+1F1EE U+1F1F2"
},
{
    "name": "India",
        "native": "भारत",
        "phone": "91",
        "continent": "AS",
        "capital": "New Delhi",
        "currency": "INR",
        "languages": [
        "hi",
        "en"
    ],
        "emoji": "🇮🇳",
        "emojiU": "U+1F1EE U+1F1F3"
},
{
    "name": "British Indian Ocean Territory",
        "native": "British Indian Ocean Territory",
        "phone": "246",
        "continent": "AS",
        "capital": "Diego Garcia",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇮🇴",
        "emojiU": "U+1F1EE U+1F1F4"
},
{
    "name": "Iraq",
        "native": "العراق",
        "phone": "964",
        "continent": "AS",
        "capital": "Baghdad",
        "currency": "IQD",
        "languages": [
        "ar",
        "ku"
    ],
        "emoji": "🇮🇶",
        "emojiU": "U+1F1EE U+1F1F6"
},
{
    "name": "Iran",
        "native": "ایران",
        "phone": "98",
        "continent": "AS",
        "capital": "Tehran",
        "currency": "IRR",
        "languages": [
        "fa"
    ],
        "emoji": "🇮🇷",
        "emojiU": "U+1F1EE U+1F1F7"
},
{
    "name": "Iceland",
        "native": "Ísland",
        "phone": "354",
        "continent": "EU",
        "capital": "Reykjavik",
        "currency": "ISK",
        "languages": [
        "is"
    ],
        "emoji": "🇮🇸",
        "emojiU": "U+1F1EE U+1F1F8"
},
{
    "name": "Italy",
        "native": "Italia",
        "phone": "39",
        "continent": "EU",
        "capital": "Rome",
        "currency": "EUR",
        "languages": [
        "it"
    ],
        "emoji": "🇮🇹",
        "emojiU": "U+1F1EE U+1F1F9"
},
{
    "name": "Jersey",
        "native": "Jersey",
        "phone": "44",
        "continent": "EU",
        "capital": "Saint Helier",
        "currency": "GBP",
        "languages": [
        "en",
        "fr"
    ],
        "emoji": "🇯🇪",
        "emojiU": "U+1F1EF U+1F1EA"
},
{
    "name": "Jamaica",
        "native": "Jamaica",
        "phone": "1876",
        "continent": "NA",
        "capital": "Kingston",
        "currency": "JMD",
        "languages": [
        "en"
    ],
        "emoji": "🇯🇲",
        "emojiU": "U+1F1EF U+1F1F2"
},
{
    "name": "Jordan",
        "native": "الأردن",
        "phone": "962",
        "continent": "AS",
        "capital": "Amman",
        "currency": "JOD",
        "languages": [
        "ar"
    ],
        "emoji": "🇯🇴",
        "emojiU": "U+1F1EF U+1F1F4"
},
{
    "name": "Japan",
        "native": "日本",
        "phone": "81",
        "continent": "AS",
        "capital": "Tokyo",
        "currency": "JPY",
        "languages": [
        "ja"
    ],
        "emoji": "🇯🇵",
        "emojiU": "U+1F1EF U+1F1F5"
},
{
    "name": "Kenya",
        "native": "Kenya",
        "phone": "254",
        "continent": "AF",
        "capital": "Nairobi",
        "currency": "KES",
        "languages": [
        "en",
        "sw"
    ],
        "emoji": "🇰🇪",
        "emojiU": "U+1F1F0 U+1F1EA"
},
{
    "name": "Kyrgyzstan",
        "native": "Кыргызстан",
        "phone": "996",
        "continent": "AS",
        "capital": "Bishkek",
        "currency": "KGS",
        "languages": [
        "ky",
        "ru"
    ],
        "emoji": "🇰🇬",
        "emojiU": "U+1F1F0 U+1F1EC"
},
{
    "name": "Cambodia",
        "native": "Kâmpŭchéa",
        "phone": "855",
        "continent": "AS",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "languages": [
        "km"
    ],
        "emoji": "🇰🇭",
        "emojiU": "U+1F1F0 U+1F1ED"
},
 {
    "name": "Kiribati",
        "native": "Kiribati",
        "phone": "686",
        "continent": "OC",
        "capital": "South Tarawa",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇰🇮",
        "emojiU": "U+1F1F0 U+1F1EE"
},
 {
    "name": "Comoros",
        "native": "Komori",
        "phone": "269",
        "continent": "AF",
        "capital": "Moroni",
        "currency": "KMF",
        "languages": [
        "ar",
        "fr"
    ],
        "emoji": "🇰🇲",
        "emojiU": "U+1F1F0 U+1F1F2"
},
{
    "name": "Saint Kitts and Nevis",
        "native": "Saint Kitts and Nevis",
        "phone": "1869",
        "continent": "NA",
        "capital": "Basseterre",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇰🇳",
        "emojiU": "U+1F1F0 U+1F1F3"
},
{
    "name": "North Korea",
        "native": "북한",
        "phone": "850",
        "continent": "AS",
        "capital": "Pyongyang",
        "currency": "KPW",
        "languages": [
        "ko"
    ],
        "emoji": "🇰🇵",
        "emojiU": "U+1F1F0 U+1F1F5"
},
{
    "name": "South Korea",
        "native": "대한민국",
        "phone": "82",
        "continent": "AS",
        "capital": "Seoul",
        "currency": "KRW",
        "languages": [
        "ko"
    ],
        "emoji": "🇰🇷",
        "emojiU": "U+1F1F0 U+1F1F7"
},
{
    "name": "Kuwait",
        "native": "الكويت",
        "phone": "965",
        "continent": "AS",
        "capital": "Kuwait City",
        "currency": "KWD",
        "languages": [
        "ar"
    ],
        "emoji": "🇰🇼",
        "emojiU": "U+1F1F0 U+1F1FC"
},
{
    "name": "Cayman Islands",
        "native": "Cayman Islands",
        "phone": "1345",
        "continent": "NA",
        "capital": "George Town",
        "currency": "KYD",
        "languages": [
        "en"
    ],
        "emoji": "🇰🇾",
        "emojiU": "U+1F1F0 U+1F1FE"
},
{
    "name": "Kazakhstan",
        "native": "Қазақстан",
        "phone": "76",
        "continent": "AS",
        "capital": "Astana",
        "currency": "KZT",
        "languages": [
        "kk",
        "ru"
    ],
        "emoji": "🇰🇿",
        "emojiU": "U+1F1F0 U+1F1FF"
},
{
    "name": "Kazakhstan",
        "native": "Қазақстан",
        "phone": "77",
        "continent": "AS",
        "capital": "Astana",
        "currency": "KZT",
        "languages": [
        "kk",
        "ru"
    ],
        "emoji": "🇰🇿",
        "emojiU": "U+1F1F0 U+1F1FF"
},
{
    "name": "Laos",
        "native": "ສປປລາວ",
        "phone": "856",
        "continent": "AS",
        "capital": "Vientiane",
        "currency": "LAK",
        "languages": [
        "lo"
    ],
        "emoji": "🇱🇦",
        "emojiU": "U+1F1F1 U+1F1E6"
},
 {
    "name": "Lebanon",
        "native": "لبنان",
        "phone": "961",
        "continent": "AS",
        "capital": "Beirut",
        "currency": "LBP",
        "languages": [
        "ar",
        "fr"
    ],
        "emoji": "🇱🇧",
        "emojiU": "U+1F1F1 U+1F1E7"
},
{
    "name": "Saint Lucia",
        "native": "Saint Lucia",
        "phone": "1758",
        "continent": "NA",
        "capital": "Castries",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇱🇨",
        "emojiU": "U+1F1F1 U+1F1E8"
},
{
    "name": "Liechtenstein",
        "native": "Liechtenstein",
        "phone": "423",
        "continent": "EU",
        "capital": "Vaduz",
        "currency": "CHF",
        "languages": [
        "de"
    ],
        "emoji": "🇱🇮",
        "emojiU": "U+1F1F1 U+1F1EE"
},
{
    "name": "Sri Lanka",
        "native": "śrī laṃkāva",
        "phone": "94",
        "continent": "AS",
        "capital": "Colombo",
        "currency": "LKR",
        "languages": [
        "si",
        "ta"
    ],
        "emoji": "🇱🇰",
        "emojiU": "U+1F1F1 U+1F1F0"
},
 {
    "name": "Liberia",
        "native": "Liberia",
        "phone": "231",
        "continent": "AF",
        "capital": "Monrovia",
        "currency": "LRD",
        "languages": [
        "en"
    ],
        "emoji": "🇱🇷",
        "emojiU": "U+1F1F1 U+1F1F7"
},
{
    "name": "Lesotho",
        "native": "Lesotho",
        "phone": "266",
        "continent": "AF",
        "capital": "Maseru",
        "currency": "LSL,ZAR",
        "languages": [
        "en",
        "st"
    ],
        "emoji": "🇱🇸",
        "emojiU": "U+1F1F1 U+1F1F8"
},
{
    "name": "Lithuania",
        "native": "Lietuva",
        "phone": "370",
        "continent": "EU",
        "capital": "Vilnius",
        "currency": "EUR",
        "languages": [
        "lt"
    ],
        "emoji": "🇱🇹",
        "emojiU": "U+1F1F1 U+1F1F9"
},
{
    "name": "Luxembourg",
        "native": "Luxembourg",
        "phone": "352",
        "continent": "EU",
        "capital": "Luxembourg",
        "currency": "EUR",
        "languages": [
        "fr",
        "de",
        "lb"
    ],
        "emoji": "🇱🇺",
        "emojiU": "U+1F1F1 U+1F1FA"
},
{
    "name": "Latvia",
        "native": "Latvija",
        "phone": "371",
        "continent": "EU",
        "capital": "Riga",
        "currency": "EUR",
        "languages": [
        "lv"
    ],
        "emoji": "🇱🇻",
        "emojiU": "U+1F1F1 U+1F1FB"
},
{
    "name": "Libya",
        "native": "‏ليبيا",
        "phone": "218",
        "continent": "AF",
        "capital": "Tripoli",
        "currency": "LYD",
        "languages": [
        "ar"
    ],
        "emoji": "🇱🇾",
        "emojiU": "U+1F1F1 U+1F1FE"
},
 {
    "name": "Morocco",
        "native": "المغرب",
        "phone": "212",
        "continent": "AF",
        "capital": "Rabat",
        "currency": "MAD",
        "languages": [
        "ar"
    ],
        "emoji": "🇲🇦",
        "emojiU": "U+1F1F2 U+1F1E6"
},
{
    "name": "Monaco",
        "native": "Monaco",
        "phone": "377",
        "continent": "EU",
        "capital": "Monaco",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇲🇨",
        "emojiU": "U+1F1F2 U+1F1E8"
},
{
    "name": "Moldova",
        "native": "Moldova",
        "phone": "373",
        "continent": "EU",
        "capital": "Chișinău",
        "currency": "MDL",
        "languages": [
        "ro"
    ],
        "emoji": "🇲🇩",
        "emojiU": "U+1F1F2 U+1F1E9"
},
{
    "name": "Montenegro",
        "native": "Црна Гора",
        "phone": "382",
        "continent": "EU",
        "capital": "Podgorica",
        "currency": "EUR",
        "languages": [
        "sr",
        "bs",
        "sq",
        "hr"
    ],
        "emoji": "🇲🇪",
        "emojiU": "U+1F1F2 U+1F1EA"
},
 {
    "name": "Saint Martin",
        "native": "Saint-Martin",
        "phone": "590",
        "continent": "NA",
        "capital": "Marigot",
        "currency": "EUR",
        "languages": [
        "en",
        "fr",
        "nl"
    ],
        "emoji": "🇲🇫",
        "emojiU": "U+1F1F2 U+1F1EB"
},
 {
    "name": "Madagascar",
        "native": "Madagasikara",
        "phone": "261",
        "continent": "AF",
        "capital": "Antananarivo",
        "currency": "MGA",
        "languages": [
        "fr",
        "mg"
    ],
        "emoji": "🇲🇬",
        "emojiU": "U+1F1F2 U+1F1EC"
},
{
    "name": "Marshall Islands",
        "native": "M̧ajeļ",
        "phone": "692",
        "continent": "OC",
        "capital": "Majuro",
        "currency": "USD",
        "languages": [
        "en",
        "mh"
    ],
        "emoji": "🇲🇭",
        "emojiU": "U+1F1F2 U+1F1ED"
},
{
    "name": "North Macedonia",
        "native": "Северна Македонија",
        "phone": "389",
        "continent": "EU",
        "capital": "Skopje",
        "currency": "MKD",
        "languages": [
        "mk"
    ],
        "emoji": "🇲🇰",
        "emojiU": "U+1F1F2 U+1F1F0"
},
{
    "name": "Mali",
        "native": "Mali",
        "phone": "223",
        "continent": "AF",
        "capital": "Bamako",
        "currency": "XOF",
        "languages": [
        "fr"
    ],
        "emoji": "🇲🇱",
        "emojiU": "U+1F1F2 U+1F1F1"
},
{
    "name": "Myanmar [Burma]",
        "native": "မြန်မာ",
        "phone": "95",
        "continent": "AS",
        "capital": "Naypyidaw",
        "currency": "MMK",
        "languages": [
        "my"
    ],
        "emoji": "🇲🇲",
        "emojiU": "U+1F1F2 U+1F1F2"
},
{
    "name": "Mongolia",
        "native": "Монгол улс",
        "phone": "976",
        "continent": "AS",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "languages": [
        "mn"
    ],
        "emoji": "🇲🇳",
        "emojiU": "U+1F1F2 U+1F1F3"
},
{
    "name": "Macao",
        "native": "澳門",
        "phone": "853",
        "continent": "AS",
        "capital": "",
        "currency": "MOP",
        "languages": [
        "zh",
        "pt"
    ],
        "emoji": "🇲🇴",
        "emojiU": "U+1F1F2 U+1F1F4"
},
{
    "name": "Northern Mariana Islands",
        "native": "Northern Mariana Islands",
        "phone": "1670",
        "continent": "OC",
        "capital": "Saipan",
        "currency": "USD",
        "languages": [
        "en",
        "ch"
    ],
        "emoji": "🇲🇵",
        "emojiU": "U+1F1F2 U+1F1F5"
},
{
    "name": "Martinique",
        "native": "Martinique",
        "phone": "596",
        "continent": "NA",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇲🇶",
        "emojiU": "U+1F1F2 U+1F1F6"
},
 {
    "name": "Mauritania",
        "native": "موريتانيا",
        "phone": "222",
        "continent": "AF",
        "capital": "Nouakchott",
        "currency": "MRU",
        "languages": [
        "ar"
    ],
        "emoji": "🇲🇷",
        "emojiU": "U+1F1F2 U+1F1F7"
},
{
    "name": "Montserrat",
        "native": "Montserrat",
        "phone": "1664",
        "continent": "NA",
        "capital": "Plymouth",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇲🇸",
        "emojiU": "U+1F1F2 U+1F1F8"
},
{
    "name": "Malta",
        "native": "Malta",
        "phone": "356",
        "continent": "EU",
        "capital": "Valletta",
        "currency": "EUR",
        "languages": [
        "mt",
        "en"
    ],
        "emoji": "🇲🇹",
        "emojiU": "U+1F1F2 U+1F1F9"
},
{
    "name": "Mauritius",
        "native": "Maurice",
        "phone": "230",
        "continent": "AF",
        "capital": "Port Louis",
        "currency": "MUR",
        "languages": [
        "en"
    ],
        "emoji": "🇲🇺",
        "emojiU": "U+1F1F2 U+1F1FA"
},
{
    "name": "Maldives",
        "native": "Maldives",
        "phone": "960",
        "continent": "AS",
        "capital": "Malé",
        "currency": "MVR",
        "languages": [
        "dv"
    ],
        "emoji": "🇲🇻",
        "emojiU": "U+1F1F2 U+1F1FB"
},
{
    "name": "Malawi",
        "native": "Malawi",
        "phone": "265",
        "continent": "AF",
        "capital": "Lilongwe",
        "currency": "MWK",
        "languages": [
        "en",
        "ny"
    ],
        "emoji": "🇲🇼",
        "emojiU": "U+1F1F2 U+1F1FC"
},
{
    "name": "Mexico",
        "native": "México",
        "phone": "52",
        "continent": "NA",
        "capital": "Mexico City",
        "currency": "MXN",
        "languages": [
        "es"
    ],
        "emoji": "🇲🇽",
        "emojiU": "U+1F1F2 U+1F1FD"
},
{
    "name": "Malaysia",
        "native": "Malaysia",
        "phone": "60",
        "continent": "AS",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "languages": [
        "ms"
    ],
        "emoji": "🇲🇾",
        "emojiU": "U+1F1F2 U+1F1FE"
},
{
    "name": "Mozambique",
        "native": "Moçambique",
        "phone": "258",
        "continent": "AF",
        "capital": "Maputo",
        "currency": "MZN",
        "languages": [
        "pt"
    ],
        "emoji": "🇲🇿",
        "emojiU": "U+1F1F2 U+1F1FF"
},
{
    "name": "Namibia",
        "native": "Namibia",
        "phone": "264",
        "continent": "AF",
        "capital": "Windhoek",
        "currency": "NAD,ZAR",
        "languages": [
        "en",
        "af"
    ],
        "emoji": "🇳🇦",
        "emojiU": "U+1F1F3 U+1F1E6"
},
{
    "name": "New Caledonia",
        "native": "Nouvelle-Calédonie",
        "phone": "687",
        "continent": "OC",
        "capital": "Nouméa",
        "currency": "XPF",
        "languages": [
        "fr"
    ],
        "emoji": "🇳🇨",
        "emojiU": "U+1F1F3 U+1F1E8"
},
{
    "name": "Niger",
        "native": "Niger",
        "phone": "227",
        "continent": "AF",
        "capital": "Niamey",
        "currency": "XOF",
        "languages": [
        "fr"
    ],
        "emoji": "🇳🇪",
        "emojiU": "U+1F1F3 U+1F1EA"
},
{
    "name": "Norfolk Island",
        "native": "Norfolk Island",
        "phone": "672",
        "continent": "OC",
        "capital": "Kingston",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇳🇫",
        "emojiU": "U+1F1F3 U+1F1EB"
},
{
    "name": "Nigeria",
        "native": "Nigeria",
        "phone": "234",
        "continent": "AF",
        "capital": "Abuja",
        "currency": "NGN",
        "languages": [
        "en"
    ],
        "emoji": "🇳🇬",
        "emojiU": "U+1F1F3 U+1F1EC"
},
{
    "name": "Nicaragua",
        "native": "Nicaragua",
        "phone": "505",
        "continent": "NA",
        "capital": "Managua",
        "currency": "NIO",
        "languages": [
        "es"
    ],
        "emoji": "🇳🇮",
        "emojiU": "U+1F1F3 U+1F1EE"
},
 {
    "name": "Netherlands",
        "native": "Nederland",
        "phone": "31",
        "continent": "EU",
        "capital": "Amsterdam",
        "currency": "EUR",
        "languages": [
        "nl"
    ],
        "emoji": "🇳🇱",
        "emojiU": "U+1F1F3 U+1F1F1"
},
{
    "name": "Norway",
        "native": "Norge",
        "phone": "47",
        "continent": "EU",
        "capital": "Oslo",
        "currency": "NOK",
        "languages": [
        "no",
        "nb",
        "nn"
    ],
        "emoji": "🇳🇴",
        "emojiU": "U+1F1F3 U+1F1F4"
},
{
    "name": "Nepal",
        "native": "नपल",
        "phone": "977",
        "continent": "AS",
        "capital": "Kathmandu",
        "currency": "NPR",
        "languages": [
        "ne"
    ],
        "emoji": "🇳🇵",
        "emojiU": "U+1F1F3 U+1F1F5"
},
{
    "name": "Nauru",
        "native": "Nauru",
        "phone": "674",
        "continent": "OC",
        "capital": "Yaren",
        "currency": "AUD",
        "languages": [
        "en",
        "na"
    ],
        "emoji": "🇳🇷",
        "emojiU": "U+1F1F3 U+1F1F7"
},
{
    "name": "Niue",
        "native": "Niuē",
        "phone": "683",
        "continent": "OC",
        "capital": "Alofi",
        "currency": "NZD",
        "languages": [
        "en"
    ],
        "emoji": "🇳🇺",
        "emojiU": "U+1F1F3 U+1F1FA"
},
 {
    "name": "New Zealand",
        "native": "New Zealand",
        "phone": "64",
        "continent": "OC",
        "capital": "Wellington",
        "currency": "NZD",
        "languages": [
        "en",
        "mi"
    ],
        "emoji": "🇳🇿",
        "emojiU": "U+1F1F3 U+1F1FF"
},
{
    "name": "Oman",
        "native": "عمان",
        "phone": "968",
        "continent": "AS",
        "capital": "Muscat",
        "currency": "OMR",
        "languages": [
        "ar"
    ],
        "emoji": "🇴🇲",
        "emojiU": "U+1F1F4 U+1F1F2"
},
{
    "name": "Panama",
        "native": "Panamá",
        "phone": "507",
        "continent": "NA",
        "capital": "Panama City",
        "currency": "PAB,USD",
        "languages": [
        "es"
    ],
        "emoji": "🇵🇦",
        "emojiU": "U+1F1F5 U+1F1E6"
},
{
    "name": "Peru",
        "native": "Perú",
        "phone": "51",
        "continent": "SA",
        "capital": "Lima",
        "currency": "PEN",
        "languages": [
        "es"
    ],
        "emoji": "🇵🇪",
        "emojiU": "U+1F1F5 U+1F1EA"
},
{
    "name": "French Polynesia",
        "native": "Polynésie française",
        "phone": "689",
        "continent": "OC",
        "capital": "Papeetē",
        "currency": "XPF",
        "languages": [
        "fr"
    ],
        "emoji": "🇵🇫",
        "emojiU": "U+1F1F5 U+1F1EB"
},
 {
    "name": "Papua New Guinea",
        "native": "Papua Niugini",
        "phone": "675",
        "continent": "OC",
        "capital": "Port Moresby",
        "currency": "PGK",
        "languages": [
        "en"
    ],
        "emoji": "🇵🇬",
        "emojiU": "U+1F1F5 U+1F1EC"
},
{
    "name": "Philippines",
        "native": "Pilipinas",
        "phone": "63",
        "continent": "AS",
        "capital": "Manila",
        "currency": "PHP",
        "languages": [
        "en"
    ],
        "emoji": "🇵🇭",
        "emojiU": "U+1F1F5 U+1F1ED"
},
{
    "name": "Pakistan",
        "native": "Pakistan",
        "phone": "92",
        "continent": "AS",
        "capital": "Islamabad",
        "currency": "PKR",
        "languages": [
        "en",
        "ur"
    ],
        "emoji": "🇵🇰",
        "emojiU": "U+1F1F5 U+1F1F0"
},
{
    "name": "Poland",
        "native": "Polska",
        "phone": "48",
        "continent": "EU",
        "capital": "Warsaw",
        "currency": "PLN",
        "languages": [
        "pl"
    ],
        "emoji": "🇵🇱",
        "emojiU": "U+1F1F5 U+1F1F1"
},
{
    "name": "Saint Pierre and Miquelon",
        "native": "Saint-Pierre-et-Miquelon",
        "phone": "508",
        "continent": "NA",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇵🇲",
        "emojiU": "U+1F1F5 U+1F1F2"
},
{
    "name": "Pitcairn Islands",
        "native": "Pitcairn Islands",
        "phone": "64",
        "continent": "OC",
        "capital": "Adamstown",
        "currency": "NZD",
        "languages": [
        "en"
    ],
        "emoji": "🇵🇳",
        "emojiU": "U+1F1F5 U+1F1F3"
},
{
    "name": "Puerto Rico",
        "native": "Puerto Rico",
        "phone": "1787",
        "continent": "NA",
        "capital": "San Juan",
        "currency": "USD",
        "languages": [
        "es",
        "en"
    ],
        "emoji": "🇵🇷",
        "emojiU": "U+1F1F5 U+1F1F7"
},
{
    "name": "Puerto Rico",
        "native": "Puerto Rico",
        "phone": "1939",
        "continent": "NA",
        "capital": "San Juan",
        "currency": "USD",
        "languages": [
        "es",
        "en"
    ],
        "emoji": "🇵🇷",
        "emojiU": "U+1F1F5 U+1F1F7"
},
{
    "name": "Palestine",
        "native": "فلسطين",
        "phone": "970",
        "continent": "AS",
        "capital": "Ramallah",
        "currency": "ILS",
        "languages": [
        "ar"
    ],
        "emoji": "🇵🇸",
        "emojiU": "U+1F1F5 U+1F1F8"
},
 {
    "name": "Portugal",
        "native": "Portugal",
        "phone": "351",
        "continent": "EU",
        "capital": "Lisbon",
        "currency": "EUR",
        "languages": [
        "pt"
    ],
        "emoji": "🇵🇹",
        "emojiU": "U+1F1F5 U+1F1F9"
},
 {
    "name": "Palau",
        "native": "Palau",
        "phone": "680",
        "continent": "OC",
        "capital": "Ngerulmud",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇵🇼",
        "emojiU": "U+1F1F5 U+1F1FC"
},
 {
    "name": "Paraguay",
        "native": "Paraguay",
        "phone": "595",
        "continent": "SA",
        "capital": "Asunción",
        "currency": "PYG",
        "languages": [
        "es",
        "gn"
    ],
        "emoji": "🇵🇾",
        "emojiU": "U+1F1F5 U+1F1FE"
},
{
    "name": "Qatar",
        "native": "قطر",
        "phone": "974",
        "continent": "AS",
        "capital": "Doha",
        "currency": "QAR",
        "languages": [
        "ar"
    ],
        "emoji": "🇶🇦",
        "emojiU": "U+1F1F6 U+1F1E6"
},
{
    "name": "Réunion",
        "native": "La Réunion",
        "phone": "262",
        "continent": "AF",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇷🇪",
        "emojiU": "U+1F1F7 U+1F1EA"
},
 {
    "name": "Romania",
        "native": "România",
        "phone": "40",
        "continent": "EU",
        "capital": "Bucharest",
        "currency": "RON",
        "languages": [
        "ro"
    ],
        "emoji": "🇷🇴",
        "emojiU": "U+1F1F7 U+1F1F4"
},
{
    "name": "Serbia",
        "native": "Србија",
        "phone": "381",
        "continent": "EU",
        "capital": "Belgrade",
        "currency": "RSD",
        "languages": [
        "sr"
    ],
        "emoji": "🇷🇸",
        "emojiU": "U+1F1F7 U+1F1F8"
},
{
    "name": "Russia",
        "native": "Россия",
        "phone": "7",
        "continent": "EU",
        "capital": "Moscow",
        "currency": "RUB",
        "languages": [
        "ru"
    ],
        "emoji": "🇷🇺",
        "emojiU": "U+1F1F7 U+1F1FA"
},
{
    "name": "Rwanda",
        "native": "Rwanda",
        "phone": "250",
        "continent": "AF",
        "capital": "Kigali",
        "currency": "RWF",
        "languages": [
        "rw",
        "en",
        "fr"
    ],
        "emoji": "🇷🇼",
        "emojiU": "U+1F1F7 U+1F1FC"
},
 {
    "name": "Saudi Arabia",
        "native": "العربية السعودية",
        "phone": "966",
        "continent": "AS",
        "capital": "Riyadh",
        "currency": "SAR",
        "languages": [
        "ar"
    ],
        "emoji": "🇸🇦",
        "emojiU": "U+1F1F8 U+1F1E6"
},
{
    "name": "Solomon Islands",
        "native": "Solomon Islands",
        "phone": "677",
        "continent": "OC",
        "capital": "Honiara",
        "currency": "SBD",
        "languages": [
        "en"
    ],
        "emoji": "🇸🇧",
        "emojiU": "U+1F1F8 U+1F1E7"
},
 {
    "name": "Seychelles",
        "native": "Seychelles",
        "phone": "248",
        "continent": "AF",
        "capital": "Victoria",
        "currency": "SCR",
        "languages": [
        "fr",
        "en"
    ],
        "emoji": "🇸🇨",
        "emojiU": "U+1F1F8 U+1F1E8"
},
{
    "name": "Sudan",
        "native": "السودان",
        "phone": "249",
        "continent": "AF",
        "capital": "Khartoum",
        "currency": "SDG",
        "languages": [
        "ar",
        "en"
    ],
        "emoji": "🇸🇩",
        "emojiU": "U+1F1F8 U+1F1E9"
},
 {
    "name": "Sweden",
        "native": "Sverige",
        "phone": "46",
        "continent": "EU",
        "capital": "Stockholm",
        "currency": "SEK",
        "languages": [
        "sv"
    ],
        "emoji": "🇸🇪",
        "emojiU": "U+1F1F8 U+1F1EA"
},
{
    "name": "Singapore",
        "native": "Singapore",
        "phone": "65",
        "continent": "AS",
        "capital": "Singapore",
        "currency": "SGD",
        "languages": [
        "en",
        "ms",
        "ta",
        "zh"
    ],
        "emoji": "🇸🇬",
        "emojiU": "U+1F1F8 U+1F1EC"
},
{
    "name": "Saint Helena",
        "native": "Saint Helena",
        "phone": "290",
        "continent": "AF",
        "capital": "Jamestown",
        "currency": "SHP",
        "languages": [
        "en"
    ],
        "emoji": "🇸🇭",
        "emojiU": "U+1F1F8 U+1F1ED"
},
{
    "name": "Slovenia",
        "native": "Slovenija",
        "phone": "386",
        "continent": "EU",
        "capital": "Ljubljana",
        "currency": "EUR",
        "languages": [
        "sl"
    ],
        "emoji": "🇸🇮",
        "emojiU": "U+1F1F8 U+1F1EE"
},
{
    "name": "Svalbard and Jan Mayen",
        "native": "Svalbard og Jan Mayen",
        "phone": "4779",
        "continent": "EU",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "languages": [
        "no"
    ],
        "emoji": "🇸🇯",
        "emojiU": "U+1F1F8 U+1F1EF"
},
{
    "name": "Slovakia",
        "native": "Slovensko",
        "phone": "421",
        "continent": "EU",
        "capital": "Bratislava",
        "currency": "EUR",
        "languages": [
        "sk"
    ],
        "emoji": "🇸🇰",
        "emojiU": "U+1F1F8 U+1F1F0"
},
{
    "name": "Sierra Leone",
        "native": "Sierra Leone",
        "phone": "232",
        "continent": "AF",
        "capital": "Freetown",
        "currency": "SLL",
        "languages": [
        "en"
    ],
        "emoji": "🇸🇱",
        "emojiU": "U+1F1F8 U+1F1F1"
},
{
    "name": "San Marino",
        "native": "San Marino",
        "phone": "378",
        "continent": "EU",
        "capital": "City of San Marino",
        "currency": "EUR",
        "languages": [
        "it"
    ],
        "emoji": "🇸🇲",
        "emojiU": "U+1F1F8 U+1F1F2"
},
{
    "name": "Senegal",
        "native": "Sénégal",
        "phone": "221",
        "continent": "AF",
        "capital": "Dakar",
        "currency": "XOF",
        "languages": [
        "fr"
    ],
        "emoji": "🇸🇳",
        "emojiU": "U+1F1F8 U+1F1F3"
},
{
    "name": "Somalia",
        "native": "Soomaaliya",
        "phone": "252",
        "continent": "AF",
        "capital": "Mogadishu",
        "currency": "SOS",
        "languages": [
        "so",
        "ar"
    ],
        "emoji": "🇸🇴",
        "emojiU": "U+1F1F8 U+1F1F4"
},
{
    "name": "Suriname",
        "native": "Suriname",
        "phone": "597",
        "continent": "SA",
        "capital": "Paramaribo",
        "currency": "SRD",
        "languages": [
        "nl"
    ],
        "emoji": "🇸🇷",
        "emojiU": "U+1F1F8 U+1F1F7"
},
{
    "name": "South Sudan",
        "native": "South Sudan",
        "phone": "211",
        "continent": "AF",
        "capital": "Juba",
        "currency": "SSP",
        "languages": [
        "en"
    ],
        "emoji": "🇸🇸",
        "emojiU": "U+1F1F8 U+1F1F8"
},
{
    "name": "São Tomé and Príncipe",
        "native": "São Tomé e Príncipe",
        "phone": "239",
        "continent": "AF",
        "capital": "São Tomé",
        "currency": "STN",
        "languages": [
        "pt"
    ],
        "emoji": "🇸🇹",
        "emojiU": "U+1F1F8 U+1F1F9"
},
 {
    "name": "El Salvador",
        "native": "El Salvador",
        "phone": "503",
        "continent": "NA",
        "capital": "San Salvador",
        "currency": "SVC,USD",
        "languages": [
        "es"
    ],
        "emoji": "🇸🇻",
        "emojiU": "U+1F1F8 U+1F1FB"
},
{
    "name": "Sint Maarten",
        "native": "Sint Maarten",
        "phone": "1721",
        "continent": "NA",
        "capital": "Philipsburg",
        "currency": "ANG",
        "languages": [
        "nl",
        "en"
    ],
        "emoji": "🇸🇽",
        "emojiU": "U+1F1F8 U+1F1FD"
},
{
    "name": "Syria",
        "native": "سوريا",
        "phone": "963",
        "continent": "AS",
        "capital": "Damascus",
        "currency": "SYP",
        "languages": [
        "ar"
    ],
        "emoji": "🇸🇾",
        "emojiU": "U+1F1F8 U+1F1FE"
},
{
    "name": "Swaziland",
        "native": "Swaziland",
        "phone": "268",
        "continent": "AF",
        "capital": "Lobamba",
        "currency": "SZL",
        "languages": [
        "en",
        "ss"
    ],
        "emoji": "🇸🇿",
        "emojiU": "U+1F1F8 U+1F1FF"
},
{
    "name": "Turks and Caicos Islands",
        "native": "Turks and Caicos Islands",
        "phone": "1649",
        "continent": "NA",
        "capital": "Cockburn Town",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇹🇨",
        "emojiU": "U+1F1F9 U+1F1E8"
},
{
    "name": "Chad",
        "native": "Tchad",
        "phone": "235",
        "continent": "AF",
        "capital": "N'Djamena",
        "currency": "XAF",
        "languages": [
        "fr",
        "ar"
    ],
        "emoji": "🇹🇩",
        "emojiU": "U+1F1F9 U+1F1E9"
},
{
    "name": "French Southern Territories",
        "native": "Territoire des Terres australes et antarctiques fr",
        "phone": "262",
        "continent": "AN",
        "capital": "Port-aux-Français",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇹🇫",
        "emojiU": "U+1F1F9 U+1F1EB"
},
{
    "name": "Togo",
        "native": "Togo",
        "phone": "228",
        "continent": "AF",
        "capital": "Lomé",
        "currency": "XOF",
        "languages": [
        "fr"
    ],
        "emoji": "🇹🇬",
        "emojiU": "U+1F1F9 U+1F1EC"
},
{
    "name": "Thailand",
        "native": "ประเทศไทย",
        "phone": "66",
        "continent": "AS",
        "capital": "Bangkok",
        "currency": "THB",
        "languages": [
        "th"
    ],
        "emoji": "🇹🇭",
        "emojiU": "U+1F1F9 U+1F1ED"
},
{
    "name": "Tajikistan",
        "native": "Тоҷикистон",
        "phone": "992",
        "continent": "AS",
        "capital": "Dushanbe",
        "currency": "TJS",
        "languages": [
        "tg",
        "ru"
    ],
        "emoji": "🇹🇯",
        "emojiU": "U+1F1F9 U+1F1EF"
},
{
    "name": "Tokelau",
        "native": "Tokelau",
        "phone": "690",
        "continent": "OC",
        "capital": "Fakaofo",
        "currency": "NZD",
        "languages": [
        "en"
    ],
        "emoji": "🇹🇰",
        "emojiU": "U+1F1F9 U+1F1F0"
},
{
    "name": "East Timor",
        "native": "Timor-Leste",
        "phone": "670",
        "continent": "OC",
        "capital": "Dili",
        "currency": "USD",
        "languages": [
        "pt"
    ],
        "emoji": "🇹🇱",
        "emojiU": "U+1F1F9 U+1F1F1"
},
{
    "name": "Turkmenistan",
        "native": "Türkmenistan",
        "phone": "993",
        "continent": "AS",
        "capital": "Ashgabat",
        "currency": "TMT",
        "languages": [
        "tk",
        "ru"
    ],
        "emoji": "🇹🇲",
        "emojiU": "U+1F1F9 U+1F1F2"
},
{
    "name": "Tunisia",
        "native": "تونس",
        "phone": "216",
        "continent": "AF",
        "capital": "Tunis",
        "currency": "TND",
        "languages": [
        "ar"
    ],
        "emoji": "🇹🇳",
        "emojiU": "U+1F1F9 U+1F1F3"
},
{
    "name": "Tonga",
        "native": "Tonga",
        "phone": "676",
        "continent": "OC",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "languages": [
        "en",
        "to"
    ],
        "emoji": "🇹🇴",
        "emojiU": "U+1F1F9 U+1F1F4"
},
{
    "name": "Turkey",
        "native": "Türkiye",
        "phone": "90",
        "continent": "AS",
        "capital": "Ankara",
        "currency": "TRY",
        "languages": [
        "tr"
    ],
        "emoji": "🇹🇷",
        "emojiU": "U+1F1F9 U+1F1F7"
},
{
    "name": "Trinidad and Tobago",
        "native": "Trinidad and Tobago",
        "phone": "1868",
        "continent": "NA",
        "capital": "Port of Spain",
        "currency": "TTD",
        "languages": [
        "en"
    ],
        "emoji": "🇹🇹",
        "emojiU": "U+1F1F9 U+1F1F9"
},
{
    "name": "Tuvalu",
        "native": "Tuvalu",
        "phone": "688",
        "continent": "OC",
        "capital": "Funafuti",
        "currency": "AUD",
        "languages": [
        "en"
    ],
        "emoji": "🇹🇻",
        "emojiU": "U+1F1F9 U+1F1FB"
},
{
    "name": "Taiwan",
        "native": "臺灣",
        "phone": "886",
        "continent": "AS",
        "capital": "Taipei",
        "currency": "TWD",
        "languages": [
        "zh"
    ],
        "emoji": "🇹🇼",
        "emojiU": "U+1F1F9 U+1F1FC"
},
{
    "name": "Tanzania",
        "native": "Tanzania",
        "phone": "255",
        "continent": "AF",
        "capital": "Dodoma",
        "currency": "TZS",
        "languages": [
        "sw",
        "en"
    ],
        "emoji": "🇹🇿",
        "emojiU": "U+1F1F9 U+1F1FF"
},
{
    "name": "Ukraine",
        "native": "Україна",
        "phone": "380",
        "continent": "EU",
        "capital": "Kyiv",
        "currency": "UAH",
        "languages": [
        "uk"
    ],
        "emoji": "🇺🇦",
        "emojiU": "U+1F1FA U+1F1E6"
},
{
    "name": "Uganda",
        "native": "Uganda",
        "phone": "256",
        "continent": "AF",
        "capital": "Kampala",
        "currency": "UGX",
        "languages": [
        "en",
        "sw"
    ],
        "emoji": "🇺🇬",
        "emojiU": "U+1F1FA U+1F1EC"
},
{
    "name": "U.S. Minor Outlying Islands",
        "native": "United States Minor Outlying Islands",
        "phone": "1",
        "continent": "OC",
        "capital": "",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇺🇲",
        "emojiU": "U+1F1FA U+1F1F2"
},
  {
    "name": "United States",
        "native": "United States",
        "phone": "1",
        "continent": "NA",
        "capital": "Washington D.C.",
        "currency": "USD,USN,USS",
        "languages": [
        "en"
    ],
        "emoji": "🇺🇸",
        "emojiU": "U+1F1FA U+1F1F8"
},
{
    "name": "Uruguay",
        "native": "Uruguay",
        "phone": "598",
        "continent": "SA",
        "capital": "Montevideo",
        "currency": "UYI,UYU",
        "languages": [
        "es"
    ],
        "emoji": "🇺🇾",
        "emojiU": "U+1F1FA U+1F1FE"
},
{
    "name": "Uzbekistan",
        "native": "O‘zbekiston",
        "phone": "998",
        "continent": "AS",
        "capital": "Tashkent",
        "currency": "UZS",
        "languages": [
        "uz",
        "ru"
    ],
        "emoji": "🇺🇿",
        "emojiU": "U+1F1FA U+1F1FF"
},
{
    "name": "Vatican City",
        "native": "Vaticano",
        "phone": "379",
        "continent": "EU",
        "capital": "Vatican City",
        "currency": "EUR",
        "languages": [
        "it",
        "la"
    ],
        "emoji": "🇻🇦",
        "emojiU": "U+1F1FB U+1F1E6"
},
{
    "name": "Saint Vincent and the Grenadines",
        "native": "Saint Vincent and the Grenadines",
        "phone": "1784",
        "continent": "NA",
        "capital": "Kingstown",
        "currency": "XCD",
        "languages": [
        "en"
    ],
        "emoji": "🇻🇨",
        "emojiU": "U+1F1FB U+1F1E8"
},
{
    "name": "Venezuela",
        "native": "Venezuela",
        "phone": "58",
        "continent": "SA",
        "capital": "Caracas",
        "currency": "VES",
        "languages": [
        "es"
    ],
        "emoji": "🇻🇪",
        "emojiU": "U+1F1FB U+1F1EA"
},
{
    "name": "British Virgin Islands",
        "native": "British Virgin Islands",
        "phone": "1284",
        "continent": "NA",
        "capital": "Road Town",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇻🇬",
        "emojiU": "U+1F1FB U+1F1EC"
},
{
    "name": "U.S. Virgin Islands",
        "native": "United States Virgin Islands",
        "phone": "1340",
        "continent": "NA",
        "capital": "Charlotte Amalie",
        "currency": "USD",
        "languages": [
        "en"
    ],
        "emoji": "🇻🇮",
        "emojiU": "U+1F1FB U+1F1EE"
},
{
    "name": "Vietnam",
        "native": "Việt Nam",
        "phone": "84",
        "continent": "AS",
        "capital": "Hanoi",
        "currency": "VND",
        "languages": [
        "vi"
    ],
        "emoji": "🇻🇳",
        "emojiU": "U+1F1FB U+1F1F3"
},
 {
    "name": "Vanuatu",
        "native": "Vanuatu",
        "phone": "678",
        "continent": "OC",
        "capital": "Port Vila",
        "currency": "VUV",
        "languages": [
        "bi",
        "en",
        "fr"
    ],
        "emoji": "🇻🇺",
        "emojiU": "U+1F1FB U+1F1FA"
},
{
    "name": "Wallis and Futuna",
        "native": "Wallis et Futuna",
        "phone": "681",
        "continent": "OC",
        "capital": "Mata-Utu",
        "currency": "XPF",
        "languages": [
        "fr"
    ],
        "emoji": "🇼🇫",
        "emojiU": "U+1F1FC U+1F1EB"
},
 {
    "name": "Samoa",
        "native": "Samoa",
        "phone": "685",
        "continent": "OC",
        "capital": "Apia",
        "currency": "WST",
        "languages": [
        "sm",
        "en"
    ],
        "emoji": "🇼🇸",
        "emojiU": "U+1F1FC U+1F1F8"
},
{
    "name": "Kosovo",
        "native": "Republika e Kosovës",
        "phone": "377",
        "continent": "EU",
        "capital": "Pristina",
        "currency": "EUR",
        "languages": [
        "sq",
        "sr"
    ],
        "emoji": "🇽🇰",
        "emojiU": "U+1F1FD U+1F1F0"
},
{
    "name": "Kosovo",
        "native": "Republika e Kosovës",
        "phone": "381",
        "continent": "EU",
        "capital": "Pristina",
        "currency": "EUR",
        "languages": [
        "sq",
        "sr"
    ],
        "emoji": "🇽🇰",
        "emojiU": "U+1F1FD U+1F1F0"
},
{
    "name": "Kosovo",
        "native": "Republika e Kosovës",
        "phone": "383",
        "continent": "EU",
        "capital": "Pristina",
        "currency": "EUR",
        "languages": [
        "sq",
        "sr"
    ],
        "emoji": "🇽🇰",
        "emojiU": "U+1F1FD U+1F1F0"
},
{
    "name": "Kosovo",
        "native": "Republika e Kosovës",
        "phone": "386",
        "continent": "EU",
        "capital": "Pristina",
        "currency": "EUR",
        "languages": [
        "sq",
        "sr"
    ],
        "emoji": "🇽🇰",
        "emojiU": "U+1F1FD U+1F1F0"
},
{
    "name": "Yemen",
        "native": "اليَمَن",
        "phone": "967",
        "continent": "AS",
        "capital": "Sana'a",
        "currency": "YER",
        "languages": [
        "ar"
    ],
        "emoji": "🇾🇪",
        "emojiU": "U+1F1FE U+1F1EA"
},
{
    "name": "Mayotte",
        "native": "Mayotte",
        "phone": "262",
        "continent": "AF",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "languages": [
        "fr"
    ],
        "emoji": "🇾🇹",
        "emojiU": "U+1F1FE U+1F1F9"
},
{
    "name": "South Africa",
        "native": "South Africa",
        "phone": "27",
        "continent": "AF",
        "capital": "Pretoria",
        "currency": "ZAR",
        "languages": [
        "af",
        "en",
        "nr",
        "st",
        "ss",
        "tn",
        "ts",
        "ve",
        "xh",
        "zu"
    ],
        "emoji": "🇿🇦",
        "emojiU": "U+1F1FF U+1F1E6"
},
{
    "name": "Zambia",
        "native": "Zambia",
        "phone": "260",
        "continent": "AF",
        "capital": "Lusaka",
        "currency": "ZMW",
        "languages": [
        "en"
    ],
        "emoji": "🇿🇲",
        "emojiU": "U+1F1FF U+1F1F2"
},
 {
    "name": "Zimbabwe",
        "native": "Zimbabwe",
        "phone": "263",
        "continent": "AF",
        "capital": "Harare",
        "currency": "USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY",
        "languages": [
        "en",
        "sn",
        "nd"
    ],
        "emoji": "🇿🇼",
        "emojiU": "U+1F1FF U+1F1FC"
}
]
