import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'src/store';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Badge, IconButton, SvgIcon, Tooltip, Button, Box } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import { Notification } from 'src/types/notification';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/notifications';
import { useSocket } from "src/socket";
import {notificationsApi} from "src/api/notifications";
import {useLocation} from "react-router-dom";

export const NotificationsButton: FC = () => {
    const [notificationList, setNotificationList] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const popover = usePopover<HTMLButtonElement>();
    const notificationData: number = useSelector((state:any): number => state.notification.unreadNotificationsCount);
    const userData = useSelector((state:any)=> state.user.user)
    const [socketState, setSocketState] = useState<any>(null)
    const dispatch: any = useDispatch()
    const socket = useSocket();

    const location = useLocation();

    useEffect(() => {
        if (userData && userData.id && userData.isAdmin !== true) {
            setDefaultNotificationInfo();
        }
    }, [notificationData])
    useEffect(() => {
        if (location.pathname === "/dashboard/notifications") {
            setDefaultNotificationInfo();
        }
    }, [location.pathname])

    const setDefaultNotificationInfo = async () => {
        const responseNotifications = await notificationsApi.getNotifications({page: 1, query: ""});
        const responseCount = await notificationsApi.getUnreadNotificationsCount();
        setUnreadCount(responseCount.data.count);
        setNotificationList([...responseNotifications.data.notifications.filter((el: any, index: number) => {
            if(index < 5) {
                return el;
            }
        })])
        dispatch(thunks.setUnreadNotificationsCount(responseCount.data.count))
    }

    useEffect(() => {
        if (userData && userData.id && userData.isAdmin !== true) {
            setDefaultNotificationInfo();
        }
        if(!socketState){
            socket.connect()
        }

        function onFooEvent(data: any) {
            setDefaultNotificationInfo();
        }

        function onConnect() {
            setSocketState(socket)
        }

        function onDisconnect() {
            setSocketState(null);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('events', onFooEvent)

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('foo', onFooEvent);
            if (socket.connected) {
                socket.disconnect();
            }
        };
    }, []);

    const updateReadNotifications = async () => {
        const notificationsNotRead = notificationList.filter((notification: any) => !notification.isRead);
        if(notificationsNotRead.length) {
            const notificationsNotReadIds = notificationsNotRead.map(item => item.id)
            await notificationsApi.setAsReadNotification({
                ids: notificationsNotReadIds
            });
            await setDefaultNotificationInfo();
        }
    }
    useEffect(() => {
        if(popover.open) {
            updateReadNotifications();
        }
    }, [popover.open])

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton
                    ref={popover.anchorRef}
                    onClick={popover.handleOpen}
                >
                    {notificationData !== 0 ?
                        (<Badge
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: "#06AED4",
                                color: "white",
                            },
                        }}
                        badgeContent={notificationData}
                    >
                        <SvgIcon>
                            <MailOutlinedIcon sx={{color: "#6C737F"}}/>
                        </SvgIcon>
                    </Badge>) :
                    (<Badge
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: "#06AED4",
                                color: "white",
                            },
                        }}
                        badgeContent={null}
                    >
                        <SvgIcon>
                            <MailOutlinedIcon sx={{color: "#6C737F"}}/>
                        </SvgIcon>
                    </Badge>)
                    }
                </IconButton>
            </Tooltip>
            {popover.open && <NotificationsPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                notifications={notificationList}
                unread={unreadCount}
                open={popover.open}
            />}
        </>
    );
};