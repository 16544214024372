import type {Theme} from '@mui/material';
import {Box, Button, Container, Typography, useMediaQuery} from '@mui/material';
import {RouterLink} from 'src/components/router-link';
import {Seo} from 'src/components/seo';
import {paths} from 'src/paths';

import React, {useState, useEffect,} from 'react'
import {
    BrowserRouter as Router,
    Link, useSearchParams,useNavigate,
    useLocation
} from "react-router-dom";
import type {Page as PageType} from 'src/types/page';
import {companyApi} from "src/api/company";

const Page: PageType = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();

    // const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    useEffect(() => {
        confirmEmail()
    }, []);


    async function confirmEmail() {
        const token = searchParams.get("token")
        const email = searchParams.get("email")
        console.log('email', email)
        try {
            if (token) {
                sessionStorage.setItem("registerByInvite", 'true')
                if (email) {
                    sessionStorage.setItem("email", email.toString())
                }
                const confirm = await companyApi.confirmInviteWorkerByEmail({hash: token})
                confirm.isRegistered ? navigate(paths.auth.login) : navigate(paths.auth.register)
            } else {
                sessionStorage.setItem("registerByInvite", 'false')

            }

        } catch {
            navigate(paths.auth.register)
        }

    }

    return (
        <>
            {/*<Seo title="Invite Page" />*/}
            {/*<Box*/}
            {/*  component="main"*/}
            {/*  sx={{*/}
            {/*    alignItems: 'center',*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    py: '80px'*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Container maxWidth="lg">*/}
            {/*    <Box*/}
            {/*      sx={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'center',*/}
            {/*        mb: 6*/}
            {/*      }}*/}
            {/*    >*/}

            {/*    </Box>*/}
            {/*    <Typography*/}
            {/*      align="center"*/}
            {/*      variant={mdUp ? 'h1' : 'h4'}*/}
            {/*    >*/}
            {/*      invite page..... {process.env.API_URL}*/}
            {/*    </Typography>*/}

            {/*    <Box*/}
            {/*      sx={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'center',*/}
            {/*        mt: 6*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Button*/}
            {/*        component={RouterLink}*/}
            {/*        href={paths.index}*/}
            {/*      >*/}
            {/*        Back to Home*/}
            {/*      </Button>*/}
            {/*    </Box>*/}
            {/*  </Container>*/}
            {/*</Box>*/}
        </>
    );
};

export default Page;
