// import { calendarApi } from 'src/api/calendar';
import {slice} from 'src/slices/user';
import type {AppThunk} from 'src/store';
import {userApi} from "src/api/user";

const getUser = (user: {}): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.getUser(user));
};

const updateUser = (user: { firstName: any, lastName: any, email: any, phone: any, photo?: any ,isFirstLogin?:any}): AppThunk => async (dispatch): Promise<void> => {
    const response = await userApi.updateUserData(user);
    dispatch(slice.actions.updateUser(response.data));
};

const updatePhone = (phone: {  phone: any }): AppThunk => async (dispatch): Promise<void> => {
    const response: any = await userApi.setPhone(phone);
    console.log('response', response)
    dispatch(slice.actions.updatePhone(phone));
};

export const thunks = {
    getUser,
    updateUser,
    updatePhone
};
