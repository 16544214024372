import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
// import { Layout as MarketingLayout } from 'src/layouts/marketing';
// import HomePage from 'src/pages';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { GuestGuard } from 'src/guards/guest-guard';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import Invite from 'src/pages/invite';
import DashboardPage from 'src/pages/dashboard';
import KycBuilderPage from 'src/pages/kyc-builder';
import LoginPage from 'src/pages/auth/jwt/login'
import { authRoutes } from './auth';
import { dashboard } from './dashboard';
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import { Layout as AuthLayoutModern } from 'src/layouts/auth/modern-layout';
import {Issuer} from "src/utils/auth";
import FilledModal from "src/pages/kyc-forms/kyc-form-user";


export const routes: RouteObject[] = [
  {
    element: (
        <IssuerGuard issuer={Issuer.JWT}>
          <GuestGuard>
            <AuthLayoutModern>
              <Outlet />
            </AuthLayoutModern>
          </GuestGuard>
        </IssuerGuard>
    ),
    children: [
      {
        index: true,
        element: <LoginPage />
      },
    ]
  },
  ...authRoutes,
    ...dashboard,

  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },

  {
    path: 'dashboard/team/invite',
    element: <Invite />
  },
  {
    path: 'dashboard/kyc-builder',
    element: <KycBuilderPage />
  },
  {
    path: 'form/:formId',
    element: <FilledModal />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
